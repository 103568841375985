import { createAction } from 'redux-actions';
import { NAVIGATION, CATALOG } from './actionTypes';

export const setActiveCompany = company => ({
  type: NAVIGATION.SET_ACTIVE_COMPANY,
  payload: company,
});

export const updateSelectedCategory = createAction(CATALOG.UPDATE_SELECTED_TAB);

// export const setActiveCompany = company => openModal({ type: ActionsTypes.SET_ACTIVE_COMPANY, data: { company } });
