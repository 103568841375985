import { routerMiddleware } from 'connected-react-router';
import createRootReducer from 'redux/reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'redux/sagas';

// history
import history from './history';

const sagaMiddleware = createSagaMiddleware();
const middleware = [apiMiddleware, sagaMiddleware, routerMiddleware(history)];

const enhancers = [];

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(createRootReducer(history), {}, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
