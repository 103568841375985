import { COMPANIES } from 'constants/Navigation';
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"MOIKON_SCH",
  name: 'МОЙКОН щелочной',
  name_addition: 'концентрированное пенное щелочное моющее средство для поверхностей',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.SCH,
    sort_destiny:  [DESTINY_WASH.POV,DESTINY_WASH.LAP,DESTINY_WASH.POS,DESTINY_WASH.TPP],
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],

  sostav: 'комплекс ПАВ, КОН и вспомогательных компонентов.Содержание щелочи (КОН) 4±1%.',
  naznach: 'для мытья поверхностей в помещениях, небольших изделий и предметов личного пользования, лабораторной посуды, инвентаря.',
  sf_primen: '',
  image: '/images/MOIKON_SCH.jpg',
  packing: '5л,1л',
  documentLink: '/documents/InstrMOIKON_SCH.PDF',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  '-\t«Мойкон щелочной» представляет собой комплекс ПАВ (неионогенных поверхностно-активных веществ 5% или более, но менее 15%), КОН и вспомогательных компонентов. Биоразлагаем. Не содержит едкого натрия (NaOH), что обеспечивает щадящее воздействие на обрабатываемые поверхности, изготовленные из деликатных материалов. Не содержит абразивных компонентов. Не образует вредных испарений. \n' +
  '-\tСодержание щелочи (КОН) 4±1%.\n' +
  '-\tpH концентрата 12-14, pH 0,1% раствора 8-9.\n' +
  '-\tНе содержит отдушек и красителей.',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'для мытья поверхностей помещений (пол, стены, двери, поручни), жесткой и мягкой мебели, аппаратов и крупногабаритного оборудования, небольших изделий и предметов личного пользования, спортивного, уборочного инвентаря, санитарно-технического оборудования при проведении ежедневных текущих и генеральных уборок.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '- в организациях здравоохранения;\n' +
  '- в учреждениях социальной сферы;\n' +
  '- в детских дошкольных учреждениях и учебных заведениях;\n' +
  '- на коммунальных объектах;\n' +
  '- в учреждениях пенитенциарной системы;\n' +
  '- на предприятиях торговли, общественного питания, пищевой, перерабатывающей, биотехнологической, фармацевтической промышленности;\n' +
  '- на транспорте (санитарном, общественном, грузовом, предназначенном для перевозки продуктов питания), а также для использования на других объектах хозяйственной деятельности (гостиничного хозяйства, автозаправочных станций, общественных туалетов, мест массового скопления людей при проведении спортивно-зрелищных мероприятий, на вокзалах, в аэропортах, на производственных предприятиях и т.д.).',
  [PRODUCT_MENU_NAMES.SPECTRUM]:'',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'■ Эффективно удаляет загрязнения органического происхождения и смешанные (механические загрязнения, белково-жировые, крахмалистые, в т.ч. застарелые, фиксированные, присохшие); \n' +
  '■ Растворы средства «Мойкон щелочной» совместимы с дезинфицирующими препаратами на основе катионактивных (четвертичные аммониевые соединения, полигуанидины) и амфотерных (амины) поверхностно-активных веществ, не снижают их специфической активности, при взаимодействии не образуют нерастворимых солей, осадка, не оставляют налета и разводов на глянцевых поверхностях;\n' +
  '■ Категорически запрещается смешивать «МОЙКОН щелочной» с анионактивными моющими средствами! \n' +
  '■ Концентрация и температура рабочего раствора зависит от характера и интенсивности загрязнений. \n' +
  '■ Стабильность рабочих растворов 14 суток. Визуально прозрачный (без инородных включений, хлопьев, осадка) раствор может использоваться многократно.\n' +
  '■ После мытья поверхностей, не контактирующих с продуктами питания, ополаскивание не требуется. Поверхности, контактирующие с продуктами питания, после обработки средством необходимо протереть чистой влажной ветошью.\n' +
  '■ Растворы «МОЙКОНА щелочного»  не оказывают повреждающего воздействия на изделия, изготовленные из любых влагостойких материалов, устойчивых к воздействию щелочей - стекло, фаянс, резина, пластмасса и композитные материалы, керамика, линолеум, лакированная, полированная и окрашенная древесина, натуральный и искусственный камень, металлы и сплавы (латунь, медь, оцинкованные, никелированные и хромированные поверхности), а так же натуральные, искусственные и синтетические ткани. \n' +
  '■ Не горюч, не взрывоопасен.',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  'В концентрированном виде средство «МОЙКОН щелочной» согласно ГОСТ 12.1.007.76 относится к 3 классу (вещества умеренно опасные) при введении в желудок. \n' +
  'Рабочие растворы относятся к 4 классу малоопасных веществ. \n' +
  'При приготовлении рабочих растворов следует избегать попадания концентрата в глаза и  на   кожу, рекомендуется защищать глаза (защитные очки) и руки (резиновые перчатки). Все работы с растворами «МОЙКОНа щелочного» необходимо проводить в резиновых перчатках.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'- Ежедневная текущая уборка: Пол, стены, мебель, крупногабаритное оборудование и другие поверхности протирают ветошью, смоченной 0,01% – 1% рабочим раствором «МОЙКОНА щелочного».\n' +
  '- При генеральной уборке предварительную мойку поверхностей перед дезинфекцией проводят с применением 0,01% - 1% раствора «МОЙКОНА щелочного». Все поверхности протирают ветошью, смоченной рабочим раствором, ополаскивание поверхностей перед нанесением дезинфицирующего средства не требуется. Растворы средства не снижают эффективности дезинфицирующих средств на основе ЧАС, полигуанидинов, аминов. \n' +
  '- На ограниченные участки, имеющие интенсивные застарелые загрязнения, рекомендуется нанести неразбавленное (концентрированное) средство на 5-10 минут, затем механически очистить и смыть остатки средства водой.\n' +
  '- Мытье методом погружения: небольшие изделия, предметы ухода, инвентарь, погружают в 0,05%-1% рабочий раствор средства «МОЙКОН щелочной» на 5-15 минут, после чего все изделия подвергают механической очистке и промывают водопроводной водой. \n' +
  '- Уборочный материал (ветошь) замачивают в 0,05%-1% рабочем растворе средства простирывают в том же растворе, выполаскивают и высушивают.\n' +
  '- Мытье лабораторной посуды: в емкость с 5% рабочим раствором средства «МОЙКОН щелочной» помещают отмываемые изделия (предметные стекла, капилляры Паченкова, пипетки, кюветы, пробирки, наконечники дозаторов, планшеты, часовые стекла), выдерживают 5 - 30 мин., затем подвергают механической очистке и тщательно промывают под струей проточной воды или ополаскивают последовательно в 2-х емкостях: водой питьевого качества и водой дистиллированной - - Удаление чрезвычайно устойчивых загрязнений (иммерсионного масла, смол, красителей, надписей маркером, следов от воскового карандаша и т.д.): рекомендуется использовать 10% раствор средства по той же технологии.',
  /*[PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="3">
			<p style="text-align: center; text-align: center;">Концентрация рабочего раствора (по препарату),%
			</p>
		</td>
		<td colspan="4">
			<p style="text-align: center; text-align: center;">Количество концентрата и воды, необходимое для приготовления:
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">1 литра раб. раствора
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">10 литров раб. раствора
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Концентрат, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Вода, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрат, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Вода, л
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,01
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,9
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,999
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0, 025
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,75
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,975
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,05
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,995
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,990
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">997,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,975
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">995
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">50
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,95
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">990
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">100
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,9
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">50
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">950
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">500
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,5
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">100
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">900
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">1 000
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,0
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,*/
};
