import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {SOSTAV_WASH,DESTINY_WASH, SFERE_WASH} from "../constants/Catalog";

export default {
  id:"CITADEL_ACD",
  name: 'ЦИТАДЕЛЬ-ЭЙСИД',
  name_addition: 'Жидкое концентрированное пенное кислотное моющее средство',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.KIS,
    sort_destiny: [DESTINY_WASH.POV,DESTINY_WASH.TPP,DESTINY_WASH.STO],
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],

  sostav: 'комплекс ПАВ, КОН и вспомогательных компонентов.Содержание щелочи (КОН) 4±1%.',
  naznach: 'для удаления широкого спектра минерализованных загрязнений, а также сопутствующих белковых, жировых, маслянистых, биоорганических, грязе-пылевых, смешанных загрязнений.',
  sf_primen: '',
  image: '/images/CITADEL_ACD.png',
  packing: '1л',
  documentLink: '/documents/InstrCITADEL_ACD.PDF',
  [PRODUCT_MENU_NAMES.COMPOSITION]:
  'неионогенные ПАВ (менее 5 %), кислота лимонная, кислота сульфаминовая. \n' +
  'рН (массовая доля средства 1,0 %) 2,0±1\n' +
  'Не содержит отдушек и красителей.',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'для  мытья и очистки:\n- внешних и внутренних поверхностей различного технологического оборудования и инвентаря;\n' +
  '- поверхностей помещений (пола, стен, эстакад, перегородок и др.), в том числе при проведении генеральных уборок;\n' +
  '- емкостного оборудования;\n' +
  '- поверхностей транспорта (общественного, пассажирского, санитарного, предназначенного для перевозки продуктов питания, в т.ч. цистерн для транспортировки молока и других напитков и пр.);\n' +
  '- санитарно-технического оборудования и поверхностей;\n' +
  '- других поверхностей и оборудования, где использование кислотного моющего средства целесообразно, технологично и эффективно.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '- на предприятиях перерабатывающей, фармацевтической, биотехнологической, парфюмерно-косметической промышленности;\n' +
  '- в учреждениях здравоохранения;\n' +
  '- на строительных объектах;\n' +
  '- на транспорте (общественном, пассажирском, санитарном, предназначенном для перевозки продуктов питания, в т.ч. цистерн для транспортировки молока и других напитков и пр.);\n' +
  '- на предприятиях коммунальной сферы, бытового обслуживания, торговли;\n' +
  '- в местах массового скопления людей и проведения спортивных и культурно-зрелищных мероприятий, на стационарных объектах транспортной инфраструктуры (вокзалы, аэропорты, станции метрополитена, склады, сортировочные пункты);\n' +
  '- в учреждениях социальной сферы и пенитенциарной системы;\n' +
  '- других объектах хозяйственной деятельности.',
  [PRODUCT_MENU_NAMES.SPECTRUM]:
  '',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'■ для повседневной и периодической очистки и удаления минерализованных загрязнений (водного камня, солей жесткости, ржавчины, следов коррозии металлов, загрязнений после выполнения ремонта в помещениях и др.), а также сопутствующих белковых, жировых, маслянистых (в том числе от горюче-смазочных материалов и нефтепродуктов), биоорганических, грязе-пылевых, смешанных загрязнений; \n' +
  '■ рекомендовано для мытья и очистки изделий и поверхностей, изготовленных из различных материалов (кроме материалов, неустойчивых к действию кислот: алюминия, нелегированной стали, натурального мрамора и других содержащих известь поверхностей); \n' +
  '■ применяется как в виде рабочих растворов (0,01-50 %), так и концентрата. Отмытая с помощью средства поверхность является отличной базой для последующей дезинфекции;\n' +
  '■ срок годности приготовленных рабочих растворов 14 суток;\n' +
  '■ не горюче, не взрывоопасно;\n' +
  '■ хорошо смывается с обрабатываемой поверхности;\n' +
  '■ отлично солюбилизирует широкий спектр загрязнений, не давая им прилипать к обработанным поверхностям.',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  'По степени токсичности при внутрижелудочном введении средство относится 3 классу умеренно опасных веществ; \n' +
  'Не содержит летучих аллергоопасных или токсичных компонентов; \n' +
  'Не создает вредных испарений в воздухе;\n' +
  'Содержит только биоразлагаемые компоненты, при естественном разложении не выделяет вредных веществ в окружающую среду;\n',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'Температура и время мойки подбираются экспериментально, с учетом возможностей применяемого оборудования и предприятия.\n' +
  '- Мытье поверхностей: нанести 0,1-3 % рабочий раствор средства на отмываемую поверхность. При наличии интенсивных или застарелых локализованных загрязнений допускается использование концентрата средства на этих участках. Выдержать экспозицию до 30 минут. Очистить поверхность от загрязнений, затем ополоснуть водой. При необходимости – повторить процедуру.\n' +
  '- Мытье методом погружения: в емкость с заранее приготовленным 1-10 % рабочим раствором средства поместить отмываемые изделия. Выдержать экспозицию от 5 до 30 мин, очистить изделия от загрязнений. При наличии устойчивых, трудно устраняемых загрязнений  процедуру повторить, увеличив концентрацию рабочего раствора до 50 %. По окончанию процедуры все изделия тщательно промыть под струей проточной воды.',
};
