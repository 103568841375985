import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import { SOSTAV_ANTISEP } from 'constants/Catalog';
import { DESTINY_ANTISEP } from 'constants/Catalog';
import { SFERE_ANTISEP } from 'constants/Catalog';

export default {
  id:"INOL",
  name: 'Инол',
  name_addition: 'антисептическое лекарственное средство',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_ANTISEP.SPI,
    sort_destiny: [DESTINY_ANTISEP.GAR, DESTINY_ANTISEP.HAR,DESTINY_ANTISEP.INP],
    sort_sfere: [SFERE_ANTISEP.MED, SFERE_ANTISEP.FPR],
    sostav: '',
  naznach: 'Для гигиенической и хирургической антисептики рук, обработки инъекционного поля.',
  sf_primen: 'бактерицидный, вирулицидный',
  image: '/images/INOL.png',

  packing: '0.1л,0.5л,1л',
  documentLink: '/documents/InstrInol.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '\tВ 100 мл:\n\tАктивно-действующее вещество: спирт этиловый (в виде спирта этилового ректификованного из пищевого сырья 96,4 % по объему)- 63,59 г, вспомогательные вещества: спирт изопропиловый 3,23 г, полиэтиленгликоль-400  г, эфиры целлюлозы, отдушка косметическая, краситель  Кармуазин Е122, вода очищенная до 100 мл.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '- Хирургическая антисептика рук персонала организаций здравоохранения;\n- Гигиеническая антисептика рук персонала организаций здравоохранения;\n- Антисептическая обработка кожных покровов пациентов перед проведением инвазивных манипуляций (инъекции, трансфузии, забор крови т.д.).',
  [PRODUCT_MENU_NAMES.PRACTICE]: '-	в учреждениях здравоохранения, очагах инфекционных заболеваний, клинических, серологических и микробиологических лабораториях; \n- в других организациях, имеющих специальное разрешение (лицензию) на медицинскую или фармацевтическую деятельность (аптеки, центры гигиены и эпидемиологии, косметологические, массажные, стоматологические кабинеты и т.п.);\n- на предприятиях фармацевтической, микробиологической, биотехнологической промышленности (при наличии лицензии на фармацевтическую деятельность), в медицинских пунктах различных предприятий, учреждений, организаций;\n- в санаторно-оздоровительных и лечебно-профилактических организациях;\n- в комплексе противоэпидемических мероприятий при ликвидации последствий чрезвычайных ситуаций;',
  [PRODUCT_MENU_NAMES.SPECTRUM]: ' ИНОЛ обладает антимикробной активностью в отношении грамположительных и грамотрицательных бактерий, в том числе возбудителей внутрибольничных инфекций, микобактерий туберкулеза, дрожжеподобных грибов рода Кандида, вирусов (в том числе возбудителей парентеральных гепатитов, ВИЧ). Основные механизмы разрушения микробной клетки - деструктивный, ферментный, мембраноатакующий.',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Антисептическое средство ИНОЛ обладает пролонгированным антимикробным действием. Реманентное (остаточное) действие сохраняется в течение 3-х часов при работе в перчатках. В рекомендованных режимах применения Инол не обладает общетоксическим и кожнорезорбтивным действием. При многократном нанесении не вызывает раздражения кожных покровов. Для смягчения и увлажнения кожи в состав лекарственного средства введены эфиры целлюлозы.\n',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:  '■ Гигиеническая антисептика рук: На сухие, чистые руки нанести 3 мл средства ИНОЛ, массирующими движениями втирать в течение 30 сек. в кожу по методике, рекомендованной ЕN 1500. По окончании экспозиции руки досушивают на воздухе до полного высыхания средства. В случаях сильного загрязнения рук, перед проведением гигиенической обработки необходимо тщательно вымыть руки водой комнатной температуры с жидким мылом и высушить. Средство наносить только на чистую, сухую кожу. Для точного дозирования средства и в целях предупреждения контаминации продукта рекомендуется использовать настенные локтевые дозаторы.'+
  '\n■ Хирургическая антисептика рук: Перед проведением хирургической антисептики рук необходимо тщательно вымыть руки и предплечья теплой водой с жидким мылом в течение 1-2 мин. и тщательно высушить. На сухие, чистые руки дважды нанести по 5 мл средства ИНОЛ, массирующими движениями втирать в течение 5 мин. в кожу по методике, рекомендованной ЕN 1500. По окончании экспозиции руки досушивают на воздухе до полного высыхания средства. Хирургические перчатки надевают на сухие руки. Для точного дозирования средства и в целях предупреждения контаминации продукта рекомендуется использовать настенные локтевые дозаторы.'+
  '\n■ Антисептическая обработка кожных покровов пациентов перед проведением манипуляций, связанных с нарушением целостности кожных покровов (инъекции, трансфузии, катетеризация кровеносных сосудов, антисептическая обработка локтевых сгибов доноров, перед взятием анализов крови и пр.). На сухую чистую кожу при помощи стерильного ватно-марлевого тампона нанести средство ИНОЛ, время экспозиции 30 сек. -1 мин.',

};
