import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {
  SOSTAV_DEZIN,
  DESTINY_DEZIN,
  SFERE_DEZIN,
  ANTIMIKROB_DEZIN,
  ADV_DEZIN,
  SFERE_ANTISEP,
} from '../constants/Catalog';

export default {
  id:"SEPTOLIGHT",
  name: 'Септалайт',
  name_addition: 'готовый к применению спиртосодержащий раствор на основе изопропилового спирта.',
  company: COMPANIES.INKRASLAV,
    /* ----- SORT ------*/
  sort_sostav: SOSTAV_DEZIN.THREE,
  sort_destiny: DESTINY_DEZIN.EKS,
  sort_sfere: [SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_ANTISEP.PIS, SFERE_DEZIN.OBR],
  sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
  sort_adv: [ADV_DEZIN.SPIR],

    sostav: '',
  naznach: 'Экстренная/быстрая дезинфекция поверхностей, предметов ухода, изделий медназначения, гигиеническая антисептика рук персонала.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/SEPTOLIGHT.png',

  packing: '0,1л, 0,5л, 1л, 5л',
  documentLink: '/documents/InstrSEPTOLIGHT.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- спирт изопропиловый - 32,0 % - 38,0 %;\n- алкилдиметилбензиламмоний хлорид - 0,08 % - 0,15 %;\n- хлоргексидин биглюконат - 0,3 % - 0,45 %;\n- функциональные добавки.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '-\tдля быстрой/экстренной дезинфекции небольших по площади, а также труднодоступных для обработки поверхностей, крупногабаритного оборудования (в т. ч. радиаторов отопления, жалюзи, сантехники); внешних поверхностей медицинской техники, приборов и аппаратов (в т. ч. кувезов и приспособлений к ним); мебели (в т. ч. матрасов, подлокотников, подголовников); осветительных приборов (в т. ч. бактерицидных ламп); спортивных тренажеров; холодильного, вентиляционного (включая системы кондиционирования), технологического оборудования, инвентаря, других изделий и предметов обихода (в т. ч. контактирующих с продуктами питания), изготовленных из различных материалов, при уходе за которыми допускается использование спиртосодержащих средств;\n' +
  '\n' +
  '-\tэкстренной/быстрой дезинфекции предметов ухода; средств индивидуальной защиты (таких как перчатки, очки, фартуки, резиновые респираторы, противогазы и пр.); небольших аппаратов, приборов (например, термометров, тонометров, стетоскопов, венозных жгутов, датчиков УЗИ, кардиоэлектродов, мониторов, пультов, ампул, флаконов); посуды (в том числе лабораторной и одноразовой); офисной и медицинской техники, в том числе комплектующих для компьютеров, мобильных и стационарных телефонов, переговорных устройств и камер видеонаблюдения; комплектующих для ингаляторов, небулайзеров, мундштуков; спортивного и игрового инвентаря и снаряжения (обувь, шлемы, очки и пр.); электронных и оптических устройств (кроме тех, которые повреждаются под действием спиртов);\n' +
  '\n' +
  '-\tдезинфекции предварительно очищенных/без видимых загрязнений изделий медназначения (в т. ч. хирургических и стоматологических инструментов, стоматологических наконечников, оттисков, слепочных ложек и т. п.); комплектующих деталей наркознодыхательной, ингаляционной аппаратуры и анестезиологического оборудования, при уходе за которыми рекомендовано использование спиртосодержащих средств;\n' +
  '\n' +
  'дезинфекции защитных перчаток, изготовленных из различных материалов (латекса/ нитрила/ неопрена)*, надетых на руки персонала учреждений здравоохранения при выполнении работ с потенциально загрязненными объектами (уборка помещений, сортировка грязного белья, работа с опасными, потенциально инфицированными отходами, работа с биологическим материалом в лабораториях, патологоанатомических отделениях, органах судебно-медицинской экспертизы);\t\t\n' +
  '\n' +
  'ВНИМАНИЕ! В соответствии с требованиями законодательства НЕ СЛЕДУЕТ дезинфицировать и использовать одну и ту же пару медицинских перчаток при оказании медицинской помощи и/или осуществлении ухода за разными пациентами, а также при переходе от контаминированного участка тела пациента к чистому участку.\n' +
  '\n' +
  '-\tгигиенической антисептики рук персонала, антисептической обработки неповрежденных кожных покровов (кроме кожи операционного/манипуляционного полей при выполнении инвазивных лечебнодиагностических манипуляций и хирургических вмешательств);',
  [PRODUCT_MENU_NAMES.PRACTICE]: '-\tв учреждениях здравоохранения, образовательных и научных учреждениях медицинского, биологического, микробиологического профиля;\n' +
  '-\tв клинических, серологических, микробиологических, генетических, гистологических и прочих лабораториях;\n' +
  '-\tна предприятиях и объектах химикофармацевтической, микробиологической, биотехнологической промышленности (в помещениях С и D классов чистоты, при производстве нестерильных лекарственных средств и медицинских изделий), парфюмернокосметической, пищевой и перерабатывающей промышленности (на молоко , рыбо-, мясоперерабатывающих производствах, консервных, кондитерских, хлебопекарных, предприятиях по производству пива и безалкогольных напитков и пр.);\n' +
  '-\tна объектах подвижного состава наземного, воздушного, водного транспорта (общественного, пассажирского, санитарного, грузового, в т. ч. предназначенного для перевозки продуктов питания) и на стационарных объектах транЬп\'ортной инфраструктуры (вокзалы, аэропорты, станции метрополитена, склады, камеры хранения, сортировочные пункты, склады, ремонтные депо и пр.);\n' +
  '-\tв детских, дошкольных и других аналогичных учреждениях и учебных заведениях (в т. ч. в интернатах, домах малютки, приютах, детских оздоровительных лагерях);\n' +
  '-\tна предприятиях коммунальной сферы и бытового обслуживания (салоны красоты, парикмахерские, солярии, предприятия ногтевого сервиса, тэту и перманентного макияжа), объектах общественного питания (включая как стационарные - кафе, рестораны, столовые, кулинарии, фабрики-кухни, так и мобильные торговые точки для реализации продукции быстрого питания - фудтраки, сезонные павильоны, передвижные ларьки, прилавки, холодильники и т. п.), торговли (включая склады, хранилища, рынки, объекты выездной торговли промышленными товарами и продуктами питания, в т. ч. пункты самовывоза), в пунктах проката спортивного инвентаря, мебели, бытовой техники и объектах каршерин- га; в библиотеках, музейных запасниках и архивах; на объектах почтовой службы, до ставки, банковской и инкассаторской деятельности; на стационарных объектах и транспортных средствах по сбору и транспортировке мусора, сортировке, переработке вторсырья и утилизации отходов;\n' +
  '-\tв местах массового скопления людей и проведения спортивных и культурнозрелищных мероприятий, в тренажерных и спортивных залах (в т. ч. предназначенных для проведения занятий и соревнований по травмоопасным видами спорта), в бассейнах, аквапарках, развлекательных центрах, парках отдыха и пр.;\n' +
  '-\tна объектах, относящихся к сфере деятельности силовых структур, ВС, МВД (в т. ч. учреждений пенитенциарной системы, судебномедицинской экспертизы и патологоанатомических служб - секционные залы, лаборатории, моргй), МЧС, ГО, при проведении со1 ответствующих работ в зонах стихийных бедствий, чрезвычайных ситуаций, очагах инфекционных заболеваний;\n' +
  '-\tна предприятиях по оказанию ритуальных и похоронных услуг (траурные залы, колумбарии, крематории, автокатафалки, холодильное оборудование и пр.),\n' +
  'в местах проведения массовых церемоний, обрядов и отправления религиозного культа;\n' +
  '-\tв учреждениях соцобеспечения (приютах, хосписах, домах престарелых, интернатах для инвалидов), санаторнокурортной сферы, оздоровления, активного отдыха, а также на других объектах хозяйственной деятельности;\n' +
  '-\tв быту, при организации ухода за больными, инвалидами, престарелыми членами семьи, дезинфекции помещений, мебели, инвентаря, обуви, предметов домашнего обихода.\n',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-\tбактерицидный (вкл. туберкулез, тестировано на М. terrae),\n' +
  '-\tвирулицидный (включая ЕСНО-6 - группа вирусов полиомиелита),\n' +
  '-\tфунгицидный (вкл. дрожжевые грибы рода Кандида, дерматофиты).\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: '\tСредство относится, согласно ГОСТ 12.1.007, к III классу умеренно опасных веществ при введении в желудок (DL50 peros = (2772,22 ± 248,88) мг/кг), к IV классу, вещества малоопасные, при нанесении на кожу (DL 50 cut > 2500 мг/кг). .',
  [PRODUCT_MENU_NAMES.MODE]: `<div style="text-align: center;">
	<table style="margin-left: 6pt; margin-right: 6pt;" class="table table-responsive table-bordered table-hover">
<tbody>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">Объекты дезинфекции
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Спектр действия
		</p>
	</td>
	<td>
		<p>Экспозиция
		</p>
	</td>
</tr>
<tr>
	<td rowspan="3">
		<p>Поверхности в помещениях, предметы ухода, средстза индивидуальной защиты, медицинские приборы, аппараты, мониторы, электронные и оптические устройства, не контактирующие с телом человека или контактирующие с неповрежденными кожными покровами, ампулы, флаконы, кувезы для недоношенных детей, посуда, одноразовые изделия перед утилизацией, защитные перчатки, надетые на руки персонала, обувь, резиновые, пластиковые и полипропиленовые коврики и пр. изделия.
		</p>
	</td>
	<td>
		<p>Бактерицидный (вкл. грамположительные и грамотрицательные бактерии); Вирулицидный (вкл ЕСНО-6 - группа вирусов полиомиелита).
		</p>
	</td>
	<td>
		<p style="margin-left: 9pt; text-align: center; text-align: center;">10 секунд
		</p>
	</td>
</tr>
<tr>
	<td>
		<p>Фунгицидный (в отношении грибов родов Кандида и Дерматофитов).
		</p>
	</td>
	<td>
		<p style="margin-left: 9pt; text-align: center; text-align: center;">30 секунд
		</p>
	</td>
</tr>
<tr>
	<td>
		<p>Туберкулоцидный (тестировано на М. terrae)
		</p>
	</td>
	<td>
		<p style="margin-left: 9pt; text-align: center; text-align: center;">1 минута
		</p>
	</td>
</tr>
<tr>
	<td>
		<p>Предварительно очищенные или без видимых загрязнений изделия медицинского назначения в учреждениях здравоохранения различного профиля (кроме противотуберкулезных).
		</p>
	</td>
	<td>
		<p>Бактерицидный (вкл. грамполо жительные и грамотрицательные бактерии);
		</p>
		<p>Вирулицидный (вкл. ЕСНО-6 - группа вирусов полиомиелита);
		</p>
		<p>Фунгицидный (грибы рода Кандида).
		</p>
	</td>
	<td>
		<p style="margin-left: 9pt;">10 минут
		</p>
	</td>
</tr>
</tbody>
</table>
</div>`,

};
