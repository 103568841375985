import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { NAVIGATION_LINKS, COMPANIES } from 'constants/Navigation';
import * as ROUTES from 'constants/routes';
import { Hidden } from '@material-ui/core';
import CompanySelector from 'components/Header/CompanySelector';

import './Menu.styles.pcss';

class Menu extends PureComponent {
  static propTypes = {
    colorClass: PropTypes.string,
    isMenuFloating: PropTypes.bool,
    activeCompany: PropTypes.string,
    setActiveCompany: PropTypes.func,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    colorClass: '',
    isMenuFloating: '',
    activeCompany: COMPANIES.INKRASLAV,
    setActiveCompany: () => undefined,
  };

  renderMenuItems = colorClass =>
    NAVIGATION_LINKS.map(route => {
      const isActive = matchPath(window.location.pathname, {
        path: route.link,
      })?.isExact;

      return (
        <div className="nav-menu_element" key={route.link}>
          <div className="nav-menu_element-content">
            <Link to={route.link}>
              <h4>{route.name}</h4>
            </Link>
          </div>
          <div className={clsx('nav-menu_indicator', colorClass, isActive ? 'active' : '')} />
        </div>
      );
    });

  renderCompanySelector = () => {
    const { activeCompany, colorClass, setActiveCompany } = this.props;

    return (
      <CompanySelector
        className="nav-menu_element"
        activeCompany={activeCompany}
        setActiveCompany={setActiveCompany}
        colorClass={colorClass}
      />
    );
  };

  render() {
    const { colorClass, isMenuFloating, location } = this.props;
    const { pathname } = location;

    const isProductPage = ROUTES.isProductPath(pathname);

    return (
      <>
        <Hidden smDown>
          <div className="nav-menu">
            <div
              className={clsx('nav-menu_content', isMenuFloating === true ? 'float' : 'fixed')}
              ref={node => {
                this.menu = node;
              }}
            >
              {!isProductPage && this.renderCompanySelector()}
              {this.renderMenuItems(colorClass)}
            </div>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className="nav-menu-mob">{this.renderMenuItems(colorClass)}</div>
          {!isProductPage && this.renderCompanySelector()}
        </Hidden>
      </>
    );
  }
}

export default withRouter(Menu);
