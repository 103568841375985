import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import { SOSTAV_ANTISEP } from "../constants/Catalog";
import {SOSTAV_DEZIN,DESTINY_ANTISEP,SFERE_ANTISEP,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";

export default {
  id:"SEPTAL_INV_Dez",
  name: 'Септаль',
  name_addition: 'готовое к применению средство для экстренной дезинфекции небольших изделий, медицинских перчаток, надетых на руки персонала, гигиенической антисептики рук и неповрежденных кожных покровов.',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: [SOSTAV_DEZIN.TWO, SOSTAV_ANTISEP.SPI],
    sort_destiny: DESTINY_ANTISEP.GAR,
    sort_sfere: [SFERE_ANTISEP.MED, SFERE_ANTISEP.FPR, SFERE_ANTISEP.KOM, SFERE_ANTISEP.OBR, SFERE_ANTISEP.PIS, SFERE_ANTISEP.TRS],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.SPIR],

    sostav: '',
  naznach: 'быстрая дезинфекция небольших приборов,  предметов ухода и изделий (термометры, тонометры, стетоскопы, УЗ-датчики), гигиеническая антисептика рук, антисептическая обработка кожных покровов персонала и пациентов, дезинфекция перчаток, надетых на руки персонала.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Septal.jpg',

  packing: '0.1л,0.5л,1л',
  documentLink: '/documents/InstrSeptal_INV.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- спирт изопропиловый 58% - 62%,\n- полигексаметиленгуанидин гидрохлорид 0,4-0,6%, \n- косметические компоненты',
  [PRODUCT_MENU_NAMES.PURPOSE]: '- для быстрой дезинфекции небольших приборов,  предметов ухода и изделий (термометры, тонометры, стетоскопы, УЗ-датчики);\n' +
  '- гигиенической антисептики рук, антисептической обработки кожных покровов персонала и пациентов при попадании биоматериала на неповрежденные кожные покровы (кроме хирургической антисептики рук, обработки кожи операционного, инъекционного и манипуляционного полей);\n' +
  '- дезинфекции перчаток, надетых на руки персонала учреждений здравоохранения при выполнении работ с потенциально загрязненными объектами (уборка помещений, сортировка грязного белья, работа с опасными отходами класса Б, работа с биологическим материалом в лабораториях, патолого-анатомических отделениях, органах судебно-медицинской экспертизы);\n' +
  '- гигиенической антисептики рук и санитарной обработки перчаток, надетых на руки работников, занятых в открытых технологических процессах, на объектах хозяйственной деятельности, где в соответствии с санитарными нормами и правилами регламентированы требования к уровню микробиологической обсемененности рук работников, а так же обеспечивающих обслуживание, кормление, уход за пациентами, посетителями, детьми и т.д.\n',
  [PRODUCT_MENU_NAMES.PRACTICE]: '- в учреждениях здравоохранения различного профиля; \n' +
  '- в очагах инфекционных заболеваний; \n' +
  '- в клинических, серологических, вирусологических, бактериологических лабораториях, патолого-анатомических отделениях, бюро судебно-медицинской экспертизы;\n' +
  '- на транспорте (санитарном, общественном, грузовом); \n' +
  '- на предприятиях торговли и общественного питания, пищевой и перерабатывающей, парфюмерно-косметической,  фармацевтической, микробиологической, биотехнологической промышленности, на складах, базах, рынках; \n' +
  '- на предприятиях сферы услуг: в парикмахерских, соляриях, маникюрных и педикюрных кабинетах, салонах красоты, пирсинга и татуировки; \n' +
  '- в медицинских пунктах и на пищеблоках детских дошкольных учреждений, учебных заведений, общежитий, производственных предприятий, социальных объектов (приютов, хосписов, домов престарелых, инвалидов), коммунальных объектов (сауны, бани), учреждений пенитенциарной системы; \n' +
  '- в санаторно-курортных, оздоровительных учреждениях, на спортивных объектах (тренажерные залы, бассейны, залы для занятий боксом, борьбой, восточными единоборствами и др. травмоопасными видами спорта)  и пр. объектов, где высок риск контаминации рук или перчаток потенциально опасными биологическими жидкостями – кровью, слизью, мокротой.\n' +
  '- в комплексе противоэпидемических мероприятий при ликвидации последствий чрезвычайных ситуаций;\n',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '\n- бактерицидный (вкл. туберкулез, тестировано на М. terrae);\n'+
  '- вирулицидный (включая ЕСНО-6 - группа вирусов полиомиелита);\n' +
  '- фунгицидный (грибы рода Кандида).',
  [PRODUCT_MENU_NAMES.CHARACTER]:'Средство относится, согласно ГОСТ 12.1.007.76,  к IV классу малоопасных веществ при введении в желудок  и при нанесении на кожу, к III классу умеренно опасных веществ при ингаляционном воздействии. Средство не оказывает местно-раздражающего и кожно-резорбтивного действия, не обладает сенсибилизирующим действием. \n' +
  'рН – 5,5±1 \n',
/*  [PRODUCT_MENU_NAMES.MODE_TEXT]:'- Гигиеническая антисептика рук: «Септаль» наносят на руки в количестве 3 мл и тщательно втирают в ладонные, тыльные и межпальцевые поверхности кожи рук в течение 30 - 60 сек в соответствии с требованиями стандарта EN 1500 до полного высыхания;\n' +
  '- Дезинфекция перчаток, надетых на руки: При загрязнении перчаток биоматериалами (кровь, слизь, гной и т.д.), перчатки протирают стерильным ватно-марлевым тампоном или марлевой салфеткой, смоченными средством «Септаль» в течение 30 сек., при туберкулезе - 60 сек.  \n' +
  '- Дезинфекция небольших изделий: небольшие приборы, аппараты,  предметы ухода и другие изделия, не предназначенные для инвазивных манипуляций (термометры, тонометры, стетоскопы, УЗ-датчики) дезинфицируют методом протирания чистой ветошью или одноразовой салфеткой, смоченной средством «Септаль», экспозиция 30 сек. при бактериальных, грибковых, вирусных инфекциях, при туберкулезе - 60 сек. \n',
  */
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
<table class="table table-responsive table-bordered table-hover">
<tbody>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Режим
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Бактерицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">Вирулицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">100%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30 сек.
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Туберкулоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">100%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1 мин.
		</p>
	</td>
</tr>
</tbody>
</table>
</div>`,
};
