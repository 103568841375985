import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { COMPANIES } from 'constants/Navigation';
import { isMobile } from 'helpers/generic';

import './CompanySelector.styles.pcss';

const MOBILE_HEADER_HEIGHT = 130;

export default class CompanySelector extends PureComponent {
  static propTypes = {
    setActiveCompany: PropTypes.func,
    activeCompany: PropTypes.string,
    colorClass: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    setActiveCompany: () => undefined,
    activeCompany: COMPANIES.INKRASLAV,
    colorClass: '',
    className: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      isFloating: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (isMobile) {
      if (window.pageYOffset > MOBILE_HEADER_HEIGHT && this.state.isFloating === false) {
        this.setState({ isFloating: true });
      } else if (window.pageYOffset < MOBILE_HEADER_HEIGHT && this.state.isFloating === true) {
        this.setState({ isFloating: false });
      }
    }
  };

  render() {
    const { setActiveCompany, activeCompany, colorClass, className } = this.props;
    const { isFloating } = this.state;

    return (
      <div className={clsx(className, 'chooser')}>
        <div className={clsx('chooser_content', { isFloating })}>
          <ul className="chooser_tabs">
            <li className={clsx(activeCompany === COMPANIES.INKRASLAV ? 'active' : 'default')}>
              <button className={colorClass} type="button" onClick={() => setActiveCompany(COMPANIES.INKRASLAV)}>
                {COMPANIES.INKRASLAV}
              </button>
            </li>
            <li className={clsx(activeCompany === COMPANIES.INVADES ? 'active' : 'default')}>
              <button className={colorClass} type="button" onClick={() => setActiveCompany(COMPANIES.INVADES)}>
                {COMPANIES.INVADES}
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
