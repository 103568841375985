import { COMPANIES } from 'constants/Navigation';
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"UNISAN_EXS",
  name: 'Унисан-ЭКСТРА',
  name_addition: 'жидкое кислотное моющее средство',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.KIS,
    sort_destiny: [DESTINY_WASH.TPP,DESTINY_WASH.STO],
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],

  sostav: 'вода, кислота сульфаминовая, АПАВ до 5 %, НПАВ до 5 %, загуститель, отдушка, краситель',
  naznach: 'для повседневной и периодической очистки и удаления широкого спектра минерализованных и комбинированных загрязнений (карбонатных отложений, молочного, пивного или винного камня, следов коррозии металлов и др.), органических загрязнений, ржавчины, мыльных разводов, побелки, цемента, а также сопутствующих им белковых, жировых, маслянистых, биоорганических, грязепылевых, смешанных загрязнений с различных изделий и поверхностей всех видов, устойчивых к кислотам.',
  sf_primen: '',
  image: '/images/UNISAN_EXS.png',
  packing: '1л,5л',
  documentLink: '/documents/InstrUNISAN_EXS_2023.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  'вода, кислота сульфаминовая, АПАВ до 5 %, НПАВ до 5 %, загуститель, отдушка, краситель',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'Средство применяется для удаления накипи, мытья и очистки поверхностей и изделий, в т.ч. предназначенных для контакта с пищевыми продуктами, изготовленных из металлов и других материалов:\n' +
  '-	при санитарной обработке внешних и внутренних поверхностей различного технологического оборудования (в т. ч. емкостного, трубопроводов, конвейерных линий), инвентаря, газовых, электрических плит, холодильников;\n' +
  '-	поверхностей помещений (пола, стен, эстакад, перегородок и др.), в том числе при проведении генеральной и послестроительной уборки;*\n' +
  '-	поверхностей транспорта, предназначенного для перевозки жидких продуктов питания (в т.ч. цистерн для транспортировки молока и других напитков и пр.);\n' +
  '-	изделий санитарно-бытового назначения (унитазов, канализационных труб, ванн, раковин), керамических, эмалированных поверхностей;\n' +
  '-	других объектов, при обработке которых допускается использование кислотных моющих средств;**\n' +
  '* мытье и очистку внутренней отделки помещений лечебно-профилактических, санаторно- курортных, детских, дошкольных, школьных, других аналогичных учреждений проводить только рабочими растворами концентрацией 0,01 % - 0,5 %, у которых показатель pH > 3 ед. pH;\n' +
  '** уход за жилищем и предметами домашнего обихода проводить только рабочими растворами концентрацией 0,01 % - 0,5 %, у которых показатель pH > 3 ед. pH.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  'в организациях здравоохранения, на объектах торговли, общественного питания, коммунально-бытового обслуживания, в детских дошкольных и образовательных учреждениях, в оздоровительных учреждениях и на спортивных объектах, в местах проведения массовых спортивных, культурно-зрелищных мероприятий и на других объектах массового пребывания людей, на объектах железнодорожного и воздушного транспорта, в поездах, самолетах, в учреждениях социальной сферы, в гостиницах, общежитиях, пансионатах, санаториях, домах отдыха, в общественных, автономных туалетах и биотуалетах, в учреждениях пенитенциарной системы, на производственных предприятиях (в т.ч. пищевой, перерабатывающей, фармацевтической, биотехнологической, парфюмерно-косметической промышленности), а также других объектах хозяйственной деятельности и в быту.',
  [PRODUCT_MENU_NAMES.SPECTRUM]:'',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:
  '■\tобладает хорошей моющей способностью;\n' +
  '■\tне горюч, не взрывоопасен.',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  'средство «Унисан-ЭКСТРА» согласно ГОСТ 12.1.007 относится к III классу (вещества умеренно опасные) при введении в желудок (DL50: 4000-5000 мг/кг) и к IV классу опасности при ингаляционном воздействии (отсутствие клинических признаков интоксикации и гибели животных при экспозиции методом статической ингаляционной затравки). Рабочие растворы (1 %) при однократном и повторном нанесении не обладают резорбтивным действием через кожу. Кумулятивное действие отсутствует (Kcum более 5,0 уел. ед.). Сенсибилизирующее действие не выявлено (0 баллов). Неразбавленное средство обладает раздражающим действием при нанесении на кожу и слизистую оболочку глаз (показатель концентрации водородных ионов pH < 3 ед. pH).',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:
  'Рабочие растворы должны готовиться и храниться в стеклянных, пластмассовых или эмалированных (без повреждения эмали) емкостях с крышками\n' +
  'Для приготовления рабочих растворов средства «Унисан-ЭКСТРА» концентрат смешивают с определенным количеством водопроводной воды. Во избежание образования пены, концентрат вливают в воду, а не наоборот. Количество средства «Унисан-ЭКСТРА» и воды, необходимое для приготовления рабочих растворов, представлены в Таблице',
  [PRODUCT_MENU_NAMES.MODE]:
 `<div style="text-align: center;">
<table style="margin-left: 0pt;" class="table table-responsive table-bordered table-hover">
<tbody>
<tr>
	<td rowspan="3">
		<p style="text-align: center; text-align: center;">Концентрация ра- бочего раствора(по препарату), %
		</p>
	</td>
	<td colspan="2" style="text-align: center;">
		<p style="text-align: center; text-align: center;">Количество средства "Унисан-ЭКС1
		</p>
	</td>
	<td colspan="2" style="text-align: center;">
		<p style="text-align: center; text-align: center;">ГРА" и воды, необходимое для:
		</p>
	</td>
</tr>
<tr>
	<td colspan="2" style="text-align: center;">
		<p style="text-align: center; text-align: center;">1 л рабочего раствора
		</p>
	</td>
	<td colspan="2" style="text-align: center;">
		<p style="text-align: center; text-align: center;">10 л рабочего раствора
		</p>
	</td>
</tr>
<tr>
	<td style="text-align: center;">
		<p style="margin-left: 14pt; text-align: center; text-align: center;">Унисан-ЭКСТРА, мл
		</p>
	</td>
	<td style="text-align: center;">
		<p style="text-align: center; text-align: center;">Вода, мл
		</p>
	</td>
	<td style="text-align: center;">
		<p style="margin-left: 14pt; text-align: center; text-align: center;">Унисан-ЭКСТРА, мл
		</p>
	</td>
	<td style="text-align: center;">
		<p style="margin-left: 10pt; text-align: center; text-align: center;">Вода, л
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,01
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,9
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,999
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,025
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,25
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,75
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">2,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,975
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,05
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,995
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,0
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,990
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,25
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">2,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">997,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">25
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,975
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">995
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">50
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,95
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">990
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">100
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,9
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">2
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">20
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">980
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">200
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9800
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">3
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">30
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">970
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">300
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9700
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">4
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">40
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">960
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">400
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9600
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">50
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">950
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">500
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9500
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">100
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">900
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1000
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9000
		</p>
	</td>
</tr>
</tbody>
</table>
</div>`,
};
