import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import routing from './routing';
import generic from './generic';

export default history =>
  combineReducers({
    router: connectRouter(history),
    routing,
    form: formReducer,
    generic,
  });
