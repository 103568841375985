import React, { Component } from 'react';
import Router from 'router';
import Footer from 'components/Footer';
import Header from 'components/Header';

import './app.pcss';

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Router />
        <Footer />
      </div>
    );
  }
}
