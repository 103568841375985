import { connect } from 'react-redux';
import { setActiveCompany } from 'redux/actions';
import Header from './Header';
import activeCompanySelector from './selector';

const mapDispatchToProps = {
  setActiveCompany,
};

export default connect(activeCompanySelector, mapDispatchToProps)(Header);
