import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { HOME, CATALOG, PRODUCT, CERTIFICATES, FILIALS, SERVICES } from 'constants/routes';

const Home = lazy(() => import('pages/About'));
const Catalog = lazy(() => import('pages/Catalog'));
const Product = lazy(() => import('pages/Product'));
const Certificates = lazy(() => import('pages/Certificates'));
const Filials = lazy(() => import('pages/Filials'));
const Services = lazy(() => import('pages/Services'));

const Router = () => (
  // TODO fallback
  <div className="content">
    <Suspense fallback={<div />}>
      <Switch className="MainCont">
        <Route exact path={HOME} component={Home} />
        <Route exact path={CATALOG} component={Catalog} />
        <Route exact path={PRODUCT} component={Product} />
        <Route exact path={CERTIFICATES} component={Certificates} />
        <Route exact path={FILIALS} component={Filials} />
        <Route exact path={SERVICES} component={Services} />
      </Switch>
    </Suspense>
  </div>
);

export default Router;
