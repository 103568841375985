import { createSelector } from 'reselect';

export const accountState = state => state?.generic || {};
export const formState = state => state?.form;

export const activeCompanySelector = createSelector(accountState, data => data.activeCompany);

export const selectedTabSelector = createSelector(accountState, data => data.selectedTab);

export const sortingParamsSelector = createSelector(formState, data => {
  const formKey = Object.keys(data)[0];
  if (formKey && data[formKey]) return data[formKey].values;
});
