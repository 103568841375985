export const UNSELECTED = 'Не выбрано';

/*  CATEGORYS OF DEZ */

export const SOSTAV_DEZIN = {
  ONE: 'Однокомпонентные',
  TWO: 'Двухкомпонентные',
  THREE: 'Трехкомпонентные',
};

export const DESTINY_DEZIN = {
  UNI: 'Универсальное',
  EKS: 'Экстренная дезинфекция',
  DVY: 'Дезинфекция высокого уровня',
  POV: 'Для поверхностей',
  STR: 'Стерилизация',
  MAP: 'Для мелких аппаратов и приборов',
  PRC: 'Для перчаток',
  GAR: 'Для  гигиенической антисептики рук',
};

export const SFERE_DEZIN = {
  PIS: 'Для общепита и предприятий пищепрома',
  KOM: 'Для коммунальных объектов',
  TRS: 'Для транспорта',
  VET: 'Для ветеринарии',
  MED: 'Для медицины',
  OBR: 'Для учреждений образования и детских дошкольных',
  FPR: 'Для фарм производств',
};

export const ANTIMIKROB_DEZIN = {
  BAK: 'Бактерии',
  VIR: 'Вирусы',
  DRJ: 'Дрожжевые грибы',
  PLE: 'Плесневые грибы',
  DER: 'Дерматофиты',
  SPR: 'Споры',
  JAI: 'Яйца глист',
  OOI: 'Особо-опасные инфекции',
  KAI: 'Клостридиальные анаэробные инфекции',
};

export const ADV_DEZIN = {
  CHAS: 'ЧАС',
  AMI: 'Амины',
  GYAN: 'Гуанидины',
  ALD: 'Альдегиды',
  SPIR: 'Спирты',
  PEVD: 'Перекись водорода',
  CHR: 'Хлор',
  ORK: 'Органические кислоты',
};

/* ------------ANTISEP------------- */

export const SOSTAV_ANTISEP = {
  SPI: 'Спирты',
  POV: 'Повидон-ЙОД',
  GYA: 'Гуанидин',
  AMI: 'Амины',
};

export const DESTINY_ANTISEP = {
  GAR: 'Для гигиенической антисептики рук',
  HAR: 'Для хирургической антисептики рук',
  OPP: 'Для операционного поля',
  INP: 'Для инъекционного поля',
  SLI: 'Для антисептической обработки слизитых оболочек',
};

export const SFERE_ANTISEP = {
  PIS: 'Для общепита и предприятий пищепрома',
  KOM: 'Для коммунальных объектов',
  TRS: 'Для транспорта',
  VET: 'Для ветеринарии',
  MED: 'Для медицины',
  OBR: 'Для учреждений образования и детских дошкольных',
  FPR: 'Для фарм производств',
};

/* ------------WASH------------- */

export const SOSTAV_WASH = {
  KIS: 'Кислотное',
  SCH: 'Щелочное',
  NET: 'Нейтральное',
  FRM: 'Содержит ферменты',
};

export const DESTINY_WASH = {
  POV: 'Для поверхностей',
  POS: 'Для посуды',
  TPP: 'Для технологического оборудования пищепрома',
  STO: 'Для сантехоборудования',
  IPSO: 'Для ПСО инструментов',
  LAP: 'Для лабораторной и аптечной посуды',
};

export const SFERE_WASH = {
  PIS: 'Для общепита и предприятий пищепрома',
  KOM: 'Для коммунальных объектов',
  TRS: 'Для транспорта',
  VET: 'Для ветеринарии',
  MED: 'Для медицины',
  OBR: 'Для учреждений образования и детских дошкольных',
  FPR: 'Для фарм производств',
};

/* ------------MED------------- */

export const SOSTAV_MED = {
  BAK: 'Бактерицидное',
  BAS: 'Бактериостатическое',
  VIR: 'Вирулицидное',
};

export const DESTINY_MED = {
  D3L: 'Для детей с 3 лет',
  MRP: 'Для мытья рук персонала',
  NVR: 'Для новорожденных',
  GAR: 'Для гигиенической антисептики рук',
};

export const SFERE_MED = {
  PIS: 'Для общепита и предприятий пищепрома',
  KOM: 'Для коммунальных объектов',
  MED: 'Для медицины',
  OBR: 'Для учреждений образования и детских дошкольных',
  FPR: 'Для фарм производств',
};
