import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import toArray from 'lodash/toArray';
import flatten from 'lodash/flatten';
import Dezor from './Dezor';
import Dezor_INV from './Dezor_INV';
import D_Cl_Extra from './D_Cl_Extra';
import Anasept from './Anasept';
import Anasept_INV from './Anasept_INV';
import Delikat from './DELIKAT';
import GALO_Effekt from './GALO_Effekt';
import EICID_EF from './EICID_EF';
import INOL from './INOL';
import KLIN_Effekt from './Klin_Effekt';
import KVINTASEPT from './KVINTASEPT';
import KVINTASEPT_Ink_MeM from './KVINTASEPT_Ink_MeM';
import KVINTASEPT_INV_MeM from './KVINTASEPT_INV_MeM';
import KVINTASEPT_INV from './KVINTASEPT_INV';
import KVINTASEPT_INV_Ant from './KVINTASEPT_INV_Ant';
import KVINTASEPT_Ink_Dez from './KVINTASEPT_Ink_Dez';
import ROSA from './ROSA';
import ROSA_INV from './ROSA_INV';
import IODISKIN from './IODISKIN';
import INKRASEPT from './INKRASEPT';
import INKRASEPT_INV from './INKRASEPT_INV';
import META_EF from './META_EF';
import MOIKON_SCH from './MOIKON_SCH';
import MOIKON_SCH_INV from './MOIKON_SCH_INV';
import IZAPIN from './Izapin';
import IZAPIN_INV from './Izapin_INV';
import SLAVIN from './Slavin';
import DEZARAL from './Dezaral';
import DEZARAL_INV from './Dezaral_INV';
import PEROXIN from './Peroxin';
import STEN from './Sten';
import STEN_INV from './Sten_INV';
import SEPTOLIGHT from './Septolight';
import SEPTANES from './Septanes';
import DYASEPT from './Dyasept';
import SLAVINDELTA from './SlavinDelta';
import SLAVINDELTA_INV from './SlavinDelta_INV';
import PEROXINPLUS from './PeroxinPlus';
import Moi_Konz from './Moi_Konz';
import Moi_Konz_INV from './Moi_Konz_INV';
import SEPTAL_Ink_Dez from './Septal_Ink_Dez';
import SEPTAL_INV_Dez from './Septal_INV_Dez';
import SEPTAL_Ink_An from './Septal_Ink_An';
import SEPTAL_INV_An from './Septal_INV_An';
import IODEL from './IODEL';
import MOIKON_Pos from './MOIKON_Pos';
import MOIKON_Pos_INV from './MOIKON_Pos_INV';
/*import CITADEL from './CITADEL';
import CITADEL_PIRO from './CITADEL_PIRO';
import CITADEL_TERMO from './CITADEL_TERMO';*/
import GREN from './Gren';
import AKVA from './Akva';
import UNISAN from './UNISAN';
import UNISAN_INV from './UNISAN_INV';
import UNISAN_EXS from './UNISAN_EXS';
import GrosKlin from './GrosKlin';
import CITADEL_ACD from './CITADEL_ACD';


const PRODUCTS =  {
  [PRODUCT_MAIN_CATEGORIES.DEZIN]: [
    INKRASEPT,
    INKRASEPT_INV,
    Anasept,
    Anasept_INV,
    IZAPIN,
    IZAPIN_INV,
    SLAVIN,
    DEZARAL,
    DEZARAL_INV,
    D_Cl_Extra,
    PEROXIN,
    STEN,
    STEN_INV,
   // SEPTAL_Ink_Dez,
    SEPTAL_INV_Dez,
    SEPTANES,
    SEPTOLIGHT,
    DYASEPT,
    SLAVINDELTA,
    SLAVINDELTA_INV,
    PEROXINPLUS,
    ROSA,
    ROSA_INV,
    KVINTASEPT_Ink_Dez,
    KVINTASEPT_INV,
    ],
	[PRODUCT_MAIN_CATEGORIES.ANTISEP]: [
    INOL,
    SEPTAL_Ink_An,
    IODISKIN,
    IODEL,
    Dezor,
    Dezor_INV,
    KVINTASEPT,
    KVINTASEPT_INV_Ant,
    SEPTAL_INV_An,
	],
	[PRODUCT_MAIN_CATEGORIES.MED]: [
    KVINTASEPT_Ink_MeM,
    KVINTASEPT_INV_MeM,
    GREN,
    AKVA,
    Delikat,
	],
	[PRODUCT_MAIN_CATEGORIES.WASH]: [
	Moi_Konz,
    GALO_Effekt,
    Moi_Konz_INV,
    META_EF,
    EICID_EF,
    KLIN_Effekt,
    MOIKON_SCH,
    MOIKON_SCH_INV,
    MOIKON_Pos,
    MOIKON_Pos_INV,
    UNISAN,
    GrosKlin,
    CITADEL_ACD,
    UNISAN_INV,
    UNISAN_EXS,
 /*   CITADEL,
    CITADEL_PIRO,
    CITADEL_TERMO,
    AKVA,
    GREN,
    Delikat,*/
    ]
};

export const ALL_PRODUCTS = flatten(toArray(PRODUCTS));

export const CERTIFICATES_INKRASLAV = [
    {
        label: 'Cвидетельство',
        description: 'Cвидетельство о государственной регистрации коммерческой организации.',
        images: ['images/Inkras/certificates/SvedetINKR.jpg'],
    },
    {
        label: 'СТБ ИСО 9001-2015',
        description: 'Настоящий сертификат соответствия выдан ООО "ИНКРАСЛАВ"',
        images: ['images/Inkras/certificates/SertISOru.jpg','images/Inkras/certificates/SertISOen.jpg'],
    },
    {
        label: 'Сертификат GMP',
        description: 'Cертификат соответствия производства лекарственного средства требованиям Надлежащей производственной практики (GMP)',
        images: ['images/Inkras/certificates/sertGMP_1.jpg','images/Inkras/certificates/sertGMP_2.jpg','images/Inkras/certificates/sertGMP_3.jpg','images/Inkras/certificates/sertGMP_4.jpg'],
    },
    {
        label: 'Анасепт СГР',
        description: 'Cредство дезинфицирующее моющее.',
        images: ['images/Inkras/certificates/Anasept_1.jpg'],
    },
    {
        label: 'ГРОСС КЛИН',
        description: 'Готовое к применению пенное щелочное моющее средство.',
        images: ['images/Inkras/certificates/Gross-Clin.jpg'],
    },
    {
        label: 'Дезараль СГР',
        description: 'Cредство дезинфицирующее c моющими свойствами.',
        images: ['images/Inkras/certificates/Dezaral_1.jpg'],
    },
    {
        label: 'ДезОР  СГР',
        description: 'Средство дезинфицирующее для обработки рук.',
        images: ['images/Inkras/certificates/Dezor_1.jpg'],
    },
    {
        label: 'Дуасепт СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Inkras/certificates/Duasept.jpg'],
    },
    {
        label: 'Изапин  СГР',
        description: 'Средство дезинфицирующее с моющими свойствами.',
        images: ['images/Inkras/certificates/Izapin_1.jpg'],
    },
    {
        label: 'Инкрасепт-10А  СГР',
        description: 'Средство дезинфицирующее с моющим эффектом.',
        images: ['images/Inkras/certificates/Inkrasept10a_1.jpg'],
    },
    {
        label: 'ИНОЛ регистрационное yдостоверение',
        description: 'Раствор для наружного применения.',
        images: ['images/Inkras/certificates/Inol_1.jpg','images/Inkras/certificates/Inol_2.jpg','images/Inkras/certificates/Inol_3.jpg','images/Inkras/certificates/Inol_4.jpg','images/Inkras/certificates/Inol_5.jpg'],
    },
    {
        label: 'Йодель СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Inkras/certificates/Iodel.jpg'],
    },
    {
        label: 'Йодискин регистрационное yдостоверение',
        description: 'Раствор для наружного применения.',
        images: ['images/Inkras/certificates/Iodiksin_1.jpg','images/Inkras/certificates/Iodiksin_2.jpg','images/Inkras/certificates/Iodiksin_3.jpg'],
    },
    {
        label: 'Квинтасепт СГР',
        description: 'Мыло жидкое с дезинфицирующим эфектом.',
        images: ['images/Inkras/certificates/Kvintasept.jpg'],
    },
    {
        label: 'Мой концентрат для ПСО СГР',
        description: 'Средство чистящее жидкое.',
        images: ['images/Inkras/certificates/MoyKonc.jpg'],
    },
    {
        label: 'МОЙКОН. Для посуды СГР',
        description: 'Средство чистящее жидкое.',
        images: ['images/Inkras/certificates/MoyKonPosuda.jpg'],
    },
    {
        label: 'МОЙКОН. Щелочной. СГР',
        description: 'Средство моющее жидкое.',
        images: ['images/Inkras/certificates/MoyKonSch.jpg'],
    },
    {
        label: 'Пероксин плюс СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Inkras/certificates/Perocsinplus.jpg'],
    },
    {
        label: 'Пероксин СГР',
        description: 'Средство дезинфицирующее с моющими свойствами.',
        images: ['images/Inkras/certificates/Perocsin.jpg'],
    },
    {
        label: 'Роса спрей СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Inkras/certificates/RosaSpr.jpg'],
    },
    {
        label: 'Септаль СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Inkras/certificates/Septal.jpg'],
    },
    {
        label: 'Септанес СГР',
        description: 'Средство дезинфицирующее-моющее.',
        images: ['images/Inkras/certificates/Septanes.jpg'],
    },
    {
        label: 'Септалайт СГР',
        description: 'Средство жидкое дезинфицирующее.',
        images: ['images/Inkras/certificates/Septolight.jpg'],
    },
    {
        label: 'Славин Дельта СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Inkras/certificates/SlavinD.jpg'],
    },
    {
        label: 'Славин СГР',
        description: 'Средство дезинфицирующее-моющее.',
        images: ['images/Inkras/certificates/Slavin.jpg'],
    },
    {
        label: 'Стэн СГР',
        description: 'Средство для химической стерилизации и дезинфекции.',
        images: ['images/Inkras/certificates/Sten_1.jpg'],
    },
    {
        label: 'Унисан СГР',
        description: 'Средство моющее жидкое для санитарно-технического оборудования.',
        images: ['images/Inkras/certificates/Unisan.jpg'],
    },
    {
        label: 'Цитадель. Эйсид СГР',
        description: 'Средство моющее жидкое.',
        images: ['images/Inkras/certificates/CitadelEi.jpg'],
    },
    {
        label: 'Унисан-ЭКСТРА СГР',
        description: 'Жидкое кислотное моющее средство.',
        images: ['images/Inkras/certificates/UNISAN_EXS.jpg'],
    },
    {
        label: 'Эйсид-эффект СГР',
        description: 'Жидкое концентрированное пенное кислотное моющее средство.',
        images: ['images/Inkras/certificates/EICID_EF.jpg'],
    },
    {
        label: 'Мета-эффект СГР',
        description: 'Средство моющее жидкое.',
        images: ['images/Inkras/certificates/META_EF.jpg'],
    },
];

export const CERTIFICATES_INVADES = [
    {
        label: 'Cвидетельство',
        description: 'Cвидетельство о государственной регистрации коммерческой организации.',
        images: ['images/Invad/certificates/GosRegInvad.jpg'],
    },
    {
        label: 'Декларация',
        description: 'Таможенный союз декларация о соответствии.',
        images: ['images/Invad/certificates/DeklTSInvad.jpg'],
    },
    {
        label: 'Анасепт. СГР',
        description: 'Cредство дезинфицирующее моющее.',
        images: ['images/Invad/certificates/AnaseptIv.jpg'],
    },
    {
        label: 'Грин. СГР',
        description: 'Мыло жидкое бактериостатическое, серии "Софт-Лайн".',
        images: ['images/Invad/certificates/GrenIv.jpg'],
    },
    {
        label: 'Дезараль. СГР',
        description: 'Cредство дезинфицирующее c моющими свойствами.',
        images: ['images/Invad/certificates/DezaralIv.jpg'],
    },
    {
        label: 'ДезОР.  СГР',
        description: 'Средство дезинфицирующее для обработки рук.',
        images: ['images/Invad/certificates/DezorIv.jpg'],
    },
    {
        label: 'Деликат. СГР',
        description: 'Мыло жидкое бактериостатическое, серии "Софт-Лайн".',
        images: ['images/Invad/certificates/DelicatIv.jpg'],
    },
    {
        label: 'Изапин. СГР',
        description: 'Средство дезинфицирующее с моющими свойствами.',
        images: ['images/Invad/certificates/IzapinIv.jpg'],
    },
    {
        label: 'Инкрасепт-10А.  СГР',
        description: 'Средство дезинфицирующее с моющим эффектом.',
        images: ['images/Invad/certificates/Inkrasept10aIv.jpg'],
    },
    {
        label: 'Квинтасепт. СГР',
        description: 'Мыло жидкое с дезинфицирующим эфектом.',
        images: ['images/Invad/certificates/KvintaseptIv.jpg'],
    },
    {
        label: 'МОЙКОН. Для посуды. СГР',
        description: 'Средство моющее жидкое.',
        images: ['images/Invad/certificates/MoyKonPosuda.jpg'],
    },
    {
        label: 'Мой концентрат для ПСО. СГР',
        description: 'Средство чистящее жидкое.',
        images: ['images/Invad/certificates/MoyKonPSOIv.jpg'],
    },
    {
        label: ' Мойкон щелочной. СГР',
        description: 'Средство моющее жидкое.',
        images: ['images/Invad/certificates/MoyKonSchIv.jpg'],
    },
    {
        label: ' Роса-спрей. СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Invad/certificates/RosaSpryIv.jpg'],
    },
    {
        label: 'Септаль. СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Invad/certificates/SeptalIv.jpg'],
    },
    {
        label: 'Славин-Дельта. СГР',
        description: 'Средство дезинфицирующее.',
        images: ['images/Invad/certificates/SlavinDIv.jpg'],
    },
    {
        label: 'Стэн. СГР',
        description: 'Средство для химической стерилизации и дезинфекции.',
        images: ['images/Invad/certificates/StenIv.jpg'],
    },
    {
        label: 'Унисан. СГР',
        description: 'Средство моющее жидкое для санитарно-технического оборудования.',
        images: ['images/Invad/certificates/UnisanIv.jpg'],
    },
  ];

export const FILIALS = [
    /* {
    label: 'Филиал 1',
    address: 'Адрес:\n пр. Независимости 148\nТелефон:\n +343637536378',
    image: 'images/fil.jpeg',
    link: 'На сайт филиала',
  },
  {
    label: 'Филиал 2',
    address: 'Адрес:\n пр. Независимости 148\nТелефон:\n +343637536378',
    image: 'images/fil.jpeg',
    link: 'На сайт филиала',
  },
  {
    label: 'Филиал 3',
    address: 'Адрес:\n пр. Независимости 148\nТелефон:\n +343637536378',
    image: 'images/fil.jpeg',
    link: 'На сайт филиала',
  },*/
];

export default PRODUCTS;
