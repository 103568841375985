import { LOCATION_CHANGE } from 'connected-react-router';
import { call, cancel, take, takeEvery } from 'redux-saga/effects';
import initialize from 'redux/sagas/initilize';

const task = null;

function* onchange({ payload }) {
  const { pathname } = payload;
  if (pathname) return;

  if (task) {
    yield cancel(task);
  }
}

export default function* routerSaga() {
  const action = yield take(LOCATION_CHANGE);
  const pathname = yield call(initialize, action);

  yield takeEvery(LOCATION_CHANGE, onchange);

  yield* onchange({ payload: { pathname } });
}
