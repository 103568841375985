import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import { ADV_DEZIN, ANTIMIKROB_DEZIN, SOSTAV_DEZIN, DESTINY_DEZIN, SFERE_DEZIN } from 'constants/Catalog';


export default {
  id:"D_Cl_Extra",
  name: 'АСТЕРА',
  name_addition: 'Универсальное таблетированное дезинфицирующе-моющее средство..',
  company: COMPANIES.OTHERS,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.ONE,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.PLE, ANTIMIKROB_DEZIN.DER, ANTIMIKROB_DEZIN.SPR, ANTIMIKROB_DEZIN.JAI, ANTIMIKROB_DEZIN.OOI, ANTIMIKROB_DEZIN.KAI],
    sort_adv: [ADV_DEZIN.CHR],
  sostav: '',
  naznach: 'для мытья и дезинфекции поверхностей, предметов ухода, дезинфекции изделий медицинского назначения,  посуды, белья, уборочного инвентаря и текстиля, дезинфекции перед утилизацией одноразовых изделий, обеззараживания биологических жидкостей, жидких медицинских и пищевых отходов',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Astera.png',
  packing: 'Cодержит 300 таблеток (вес 1 кг).',
  documentLink: '/documents/InstrAstera.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '"АСТЕРА" - быстрорастворимые хлорные таблетки (дихлоризоцианурат натрия - 84%). Таблетки весом 3,14г – 3,50г при растворении выделяют  активного хлора – 1,35г – 1,65г.Срок годности – 5лет. Срок годности раб. р-ра 7 суток.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '- для дезинфекции изделий медназначения, \n' +
  '- для мойки и дезинфекции поверхностей при проведении заключительной, текущей и профилактической дезинфекции, предметов ухода, белья, посуды, уборочного инвентаря и других изделий.\n' +
  '- для обеззараживания биологических жидкостей (кровь, моча, мокроты, рвотные массы, фекалии и др.), смывных вод, жидкости после ополаскивания зева, посуды из - под выделений, медицинских и пищевых отходов;\n' +
  '- для дезинфекции мусоросборников, мусорокамер, мусоровозов и мусороуборочного оборудования;\n' +
  '- для дезинфекции и дезодорирования на различных объектах.',
  [PRODUCT_MENU_NAMES.PRACTICE]: '• в лечебно - профилактических учреждениях (ЛПУ), включая акушерские стационары (кроме отделений неонатологии), в клинических, микробиологических, диагностических и других лабораториях, процедурных кабинетах, пунктах и станциях переливания и забора крови, в инфекционных очагах для обеззараживания;\n' +
  '• в детских дошкольных и подростковых учреждениях (детские сады, школы, гимназии, лицеи, школы - интернаты общего типа и специальные, учреждения дополнительного образования, учреждения для детей - сирот – дома ребонка, детские дома, средние учебные заведения - профессионально - технические училища и др. , детские оздоровительные учреждения, учреждения отдыха, высшие учебные заведения);\n' +
  '• на коммунальных объектах (гостиницы, общежития, парикмахерские, СПА - салоны, салоны красоты, солярии, бани, прачечные, общественные туалеты и др. ), в учреждениях культуры, отдыха, спорта (кинотеатры, офисы, спортивные и культурно - оздоровительные комплексы, бассейны и др. ), \n' +
  '• на предприятиях продовольственной торговли и общественного питания (ресторанах, кафе, столовых, закусочных, барах, пищеблоках) в том числе для дезинфекции скорлупы яиц, продовольственных и промышленных рынках, \n' +
  '• в пенитенциарных, военных учреждениях, казармах, \n' +
  '• в учреждениях социального обеспечения (домах престарелых), \n' +
  '• в аптеках, на предприятиях фармацевтической и биотехнологической промышленности по производству нестерильных лекарственных средств в помещениях классов чистоты С и D:\n' +
  '• для дезинфекции мусоросборников, мусорокамер, мусоровозов и мусороуборочного оборудования;\n' +
  '• транспорта для перевозки пищевых продуктов, общественного транспорта;',
  [PRODUCT_MENU_NAMES.SPECTRUM]: 'обладает антимикробной активностью в отношении грамотрицательных и грамположительных бактерий (включая возбудителей туберкулеза - тестировано на M. terrae, внутрибольничных инфекций, особо опасных инфекций - чумы, холеры, туляремии, сибирской язвы), вирусов (Коксаки, ЕСНО, полиомиелита, рота- и норовирусной инфекций, энтеральных и парентеральных гепатитов, ВИЧ, гриппа, в т. ч. Н5NI, Н1NI, аденовирусов и др. возбудителей ОРВИ, герпеса, цитомегалии и др.), грибов рода Кандида, дерматофитов.',
  [PRODUCT_MENU_NAMES.CHARACTER]: '',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'',
  [PRODUCT_MENU_NAMES.ACT_MECHANICS]: '',
  [PRODUCT_MENU_NAMES.MODE]: `<div style="text-align: center;">
<table class="table table-responsive table-bordered table-hover" style="margin-left: 6pt;">
<tbody>
<tr>
<td rowspan="2">
<p style="text-align: center; margin: 0cm;">Режим</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">Концентрация</p>
<p style="text-align: center; margin: 0cm;">по активному хлору, %</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">Экспозиция,</p>
<p style="text-align: center; margin: 0cm;">мин.</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">Концентрация</p>
<p style="text-align: center; margin: 0cm;">по активному хлору, %</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">Экспозиция,</p>
<p style="text-align: center; margin: 0cm;">мин.</p>
</td>
</tr>
<tr>
<td colspan="2">
<p style="text-align: center; margin: 0cm;">для поверхностей</p>
</td>
<td colspan="2">
<p style="text-align: center; margin: 0cm;">для инструментов</p>
</td>
</tr>
<tr>
<td>
<p style="text-align: center; margin: 0cm;">Бактерицидный</p>
<p style="text-align: center; margin: 0cm;">Вирулицидный</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,015</p>
<p style="text-align: center; margin: 0cm;">0,03</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">60</p>
<p style="text-align: center; margin: 0cm;">30</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,06</p>
<p style="text-align: center; margin: 0cm;">0,1</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">90</p>
<p style="text-align: center; margin: 0cm;">30</p>
</td>
</tr>
<tr>
<td>
<p style="text-align: center; margin: 0cm;">Фунгицидный</p>
<p style="text-align: center; margin: 0cm;">(Кандиды, дерматофиты)</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,06</p>
<p style="text-align: center; margin: 0cm;">0,1</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">60</p>
<p style="text-align: center; margin: 0cm;">30</p>
</td>
<td rowspan="2">
<p style="text-align: center; margin: 0cm;">0,2</p>
<p style="text-align: center; margin: 0cm;">0,3</p>
</td>
<td rowspan="2">
<p style="text-align: center; margin: 0cm;">60</p>
<p style="text-align: center; margin: 0cm;">45</p>
</td>
</tr>
<tr>
<td>
<p style="text-align: center; margin: 0cm;">Туберкулоцидный</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,06</p>
<p style="text-align: center; margin: 0cm;">0,1</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">60</p>
<p style="text-align: center; margin: 0cm;">30</p>
</td>
</tr>
<tr>
<td>
<p style="text-align: center; margin: 0cm;">Фунгицидный</p>
<p style="text-align: center; margin: 0cm;">(плесень)</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,5</p>
<p style="text-align: center; margin: 0cm;">1,0</p>
<p style="text-align: center; margin: 0cm;">0,2</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">60</p>
<p style="text-align: center; margin: 0cm;">15</p>
<p style="text-align: center; margin: 0cm;">120</p>
</td>
<td>&nbsp;</td>
<td>&nbsp;</td>
</tr>
<tr>
<td>
<p style="text-align: center; margin: 0cm;">ООИ &ndash; чума,</p>
<p style="text-align: center; margin: 0cm;">холера, туляремия</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,1</p>
<p style="text-align: center; margin: 0cm;">0,06</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">30</p>
<p style="text-align: center; margin: 0cm;">60</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,2</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">60</p>
</td>
</tr>
<tr>
<td>
<p style="text-align: center; margin: 0cm;">Обработка скорлупы пищевых яиц</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,015</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">5</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">-</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">-</p>
</td>
</tr>
<tr>
<td colspan="3">
<p style="text-align: center; margin: 0cm;">Обеззараживание медицинских отходов (одноразовых ИМН)</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,1</p>
<p style="text-align: center; margin: 0cm;">0,2</p>
<p style="text-align: center; margin: 0cm;">0,3</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">100</p>
<p style="text-align: center; margin: 0cm;">60</p>
<p style="text-align: center; margin: 0cm;">45</p>
</td>
</tr>
<tr>
<td colspan="3">
<p style="text-align: center; margin: 0cm;">Обеззараживание медицинских отходов (перевязочный материал, белье, одежда персонала однократного применения)</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">0,2</p>
<p style="text-align: center; margin: 0cm;">0,3</p>
</td>
<td>
<p style="text-align: center; margin: 0cm;">120</p>
<p style="text-align: center; margin: 0cm;">60</p>
</td>
</tr>
</tbody>
</table>
  </div>`
};
