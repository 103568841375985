import { COMPANIES } from 'constants/Navigation';
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"META_EF",
  name: 'Мета-эффект',
  name_addition: 'средство моющее жидкое',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.SCH,
    sort_destiny: [DESTINY_WASH.POV,DESTINY_WASH.POS,DESTINY_WASH.TPP],
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],

  sostav: 'комплекс ПАВ (неионогенных поверхностно-активных веществ менее 5 %), метасиликата натрия и вспомогательных компонентов.',
  naznach: 'для удаления широкого спектра органических и сопутствующих им загрязнений.',
  sf_primen: '',
  image: '/images/META_EF.png',
  packing: '1л,5л',
  documentLink: '/documents/InstrMETA_EF.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  'Средство «Мета-эффект» представляет собой комплекс ПАВ (неионогенных поверхностно-активных веществ менее 5 %), метасиликата натрия и вспомогательных компонентов. Биоразлагаем. Не содержит едкого натра (NaOH), что обеспечивает щадящее воздействие на обрабатываемые изделия, изготовленные из деликатных материалов (алюминия, латуни, оцинковки и др.). Не содержит абразивных компонентов. Не образует вредных испарений. pH 10 % раствора составляет 11-13 ед. pH.',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'для удаления широкого спектра органических и сопутствующих им загрязнений: белковых, жировых, маслянистых (в том числе от горюче-смазочных материалов и нефтепродуктов), биоорганических, грязе-пылевых, смешанных\n' +
  '- с поверхностей помещений*, крупногабаритного оборудования, аппаратов, приборов, мебели, предметов обихода*, сантехнического оборудования и инвентаря при проведении текущих и генеральных уборок;\n' +
  '- с внешних и внутренних поверхностей различного технологического оборудования, включая емкостное, холодильного оборудования на различных объектах хозяйственной деятельности, включая предприятия пищевой промышленности и общественного питания;\n' +
  '- с посуды (лабораторной, столовой, кухонной), игрушек, спортивного инвентаря в комплексе санитарно-гигиенических и противоэпидемических мероприятий;\n' +
  '- с поверхностей в салонах и грузовых отсеках транспорта (в т. ч. цистерн для транспортировки молока и других напитков и пр.);\n' +
  '- в других случаях, когда необходимо использование щелочного моющего средства, а его применение целесообразно, технологично и эффективно.\n' +
  'Может применяться как в виде рабочих растворов (0,01 % - 50 %), так и в нативном виде.*\n' +
  'Отмытая с помощью средства поверхность является отличной базой для последующей дезинфекции.\n',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '- на предприятиях пищевой и перерабатывающей промышленности и объектах общественного питания, пищеблоках;\n' +
  '- в организациях здравоохранения;\n' +
  '- на предприятиях фармацевтической, биотехнологической, парфюмерно-косметической промышленности;\n' +
  '- на объектах подвижного состава наземного, воздушного, водного транспорта (общественного, пассажирского, санитарного, грузового, в т. ч. предназначенного для перевозки продуктов питания) и на стационарных объектах транспортной инфраструктуры (вокзалы, аэропорты, станции метрополитена, склады, сортировочные пункты);\n' +
  '- на предприятиях коммунальной сферы, бытового обслуживания, торговли;\n' +
  '- в местах массового скопления людей и проведения спортивных и культурно-зрелищных мероприятий;\n' +
  '- в учреждениях социальной сферы и пенитенциарной системы;\n' +
  '- в санаторно-курортных, детских, дошкольных, школьных и других аналогичных учреждениях* и учебных заведениях, а также на других объектах хозяйственной деятельности.',
  [PRODUCT_MENU_NAMES.SPECTRUM]:'',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:
  '■	обладает хорошей моющей способностью;\n'+'\n' +
  '■	не содержит отдушек, красителей, летучих аллергоопасных или токсичных компонентов, не создает вредных испарений в воздухе рабочей зоны;\n' +
  '■	средство не горюче, не взрывоопасно;\n' +
  '■	содержит только биоразлагаемые компоненты, при естественном разложении не выделяет вредных веществ в окружающую среду;\n' +
  '■	отлично солюбилизирует широкий спектр загрязнений, не давая им прилипать к обработанным поверхностям;\n' +
  '■	не содержит агрессивных щелочей, подходит для мытья и чистки поверхностей из деликатных металлов (алюминий, латунь, оцинкованная сталь и др.);\n' +
  '■	не содержит окислителей и коррозионных компонентов;\n' +
  '■	хорошо смывается с обрабатываемых поверхностей.',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  'в концентрированном виде средство согласно ГОСТ 12.1.007 относится к 3 классу (вещества умеренно опасные) при введении в желудок и к IV классу (вещества малоопасные) при ингаляционном воздействии (отсутствие клинических признаков интоксикации и гибели животных при экспозиции методом статической ингаляционной затравки). Рабочие растворы (1 %) при однократном и повторном нанесении не обладают резорбтивным действием через кожу. Кумулятивное действие отсутствует (Kcum более 5,0 уел. ед.). Сенсибилизирующее действие не выявлено (0 баллов). Неразбавленное средство обладает раздражающим действием при нанесении на кожу и слизистую оболочку глаз (показатель концентрации водородных ионов pH раствора с массовой долей средства 10,0 % >11,5 ед. pH).',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:
  '\tРабочие растворы должны готовиться и храниться в емкостях с крышками.\n' +
  '\tДля приготовления рабочих растворов средства концентрат смешивают с определенным количеством водопроводной воды комнатной температуры. Для повышения моющих свойств раствора температура воды может быть повышена до 50 °С - 70 °С. Во избежание образования обильной пены, концентрат вливают в воду, а не наоборот. Количество концентрата и воды, необходимое для приготовления рабочих растворов, представлены в таблице.\n',
  [PRODUCT_MENU_NAMES.MODE]:
 `<div style="text-align: center;">
<table style="margin-left: 0pt;" class="table table-responsive table-bordered table-hover">
<tbody>
<tr>
	<td rowspan="3">
		<p style="text-align: center; text-align: center;">Концентрация рабочего рас- твора,%
		</p>
	</td>
	<td colspan="4">
		<p style="text-align: center; text-align: center;">Количество концентрата и воды, необходимое для приготовления:
		</p>
	</td>
</tr>
<tr>
	<td colspan="2">
		<p style="text-align: center; text-align: center;">1 литра раб. раствора
		</p>
	</td>
	<td colspan="2">
		<p style="text-align: center; text-align: center;">10 литров раб. раствора
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="margin-left: 12pt; text-align: center; text-align: center;">Концентрат, мл
		</p>
	</td>
	<td>
		<p style="margin-left: 14pt; text-align: center; text-align: center;">Вода, мл
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Концентрат, мл
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Вода, л
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,01
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,9
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,999
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,025
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,25
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,75
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">2,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,975
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,05
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,995
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">999,0
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,990
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,25
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">2,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">997,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">25
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,975
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">0,5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">995
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">50
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,95
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">990
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">100
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,9
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">2
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">20
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">980
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">200
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,8
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">3
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">30
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">970
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">300
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,7
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">5
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">50
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">950
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">500
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,5
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">100
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">900
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1 000
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">9,0
		</p>
	</td>
</tr>
</tbody>
</table>
</div>`,
};
