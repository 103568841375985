import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {DESTINY_ANTISEP, SOSTAV_ANTISEP, SOSTAV_DEZIN,SFERE_ANTISEP,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";

export default {
	id:"Dezor",
  name: 'Дезор',
  name_addition: 'средство дезинфицирующее для антисептической обработки рук.',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: [SOSTAV_DEZIN.TWO, SOSTAV_ANTISEP.GYA],
    sort_destiny: DESTINY_ANTISEP.GAR,
    sort_sfere: [SFERE_ANTISEP.MED, SFERE_ANTISEP.FPR, SFERE_ANTISEP.KOM, SFERE_ANTISEP.OBR, SFERE_ANTISEP.PIS, SFERE_ANTISEP.TRS],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.GYAN],
  sostav: '',
  naznach: 'Предназначено для гигиенической антисептики рук',
  sf_primen: 'бактерицидный, вирулицидный',
  image: '/images/Dezor.png',
  packing: '0.5л, 1л',
  documentLink: '/documents/InstrDezOR.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- полигексаметиленбигуанидин гидрохлорид - 1%;\n- косметические и функциональные компоненты.\n'+
  'Физико-химические показатели: pH — 5,0 ± 1,0\n',
  [PRODUCT_MENU_NAMES.PURPOSE]: '- Гигиеническая антисептика рук медицинского, ухаживающего и обслуживающего персонала.\n' +
  '- Гигиеническая антисептика (санитарная обработка) рук работников, участвующих в открытых технологических процессах.',
  [PRODUCT_MENU_NAMES.PRACTICE]:'- в организациях здравоохранения различного профиля;\n' +
  '-\tклинических, серологических и микробиологических лабораториях;\n' +
  '-\tв патологоанатомических отделениях и бюро судебно-медицинской экспертизы, в моргах, крематориях,\n'+
  ' траурных залах при оказании ритуальных и похоронных услуг;\n' +
  '-\tв учреждениях социальной сферы (приюты, дома инвалидов и престарелых);\n' +
  '-\tв детских дошкольных и образовательных учреждениях;\n' +
  '-\tна транспорте (санитарном, общественном, грузовом);\n' +
  '-\tв очагах инфекционных заболеваний, при организации ухода за тяжело больными и лежачими пациентами;\n' +
  '-\tна предприятиях пищевой промышленности, общественного питания, продовольственной торговли, потребительских рынках;\n' +
  '-\tна предприятиях парфюмерно-косметической, биотехнологической, фармацевтической промышленности (при производстве нестерильных лекарственных средств, в помещениях класса чистоты С и D);\n' +
  '-\tв парикмахерских, соляриях, салонах красоты, маникюрных и педикюрных кабинетах, салонах пирсинга и татуировки;\n' +
  '-\tв общежитиях, на производственных предприятиях, объектах коммунальнобытового обслуживания (сауны, бани);\n' +
  '-\tв санаторно-курортных и оздоровительных учреждениях;\n' +
  '-\tна спортивных объектах (тренажерные залы, бассейны, залы для занятий травматичными видами спорта) и пр. объектах массового пребывания людей;\n' +
  '-\tв учреждениях пенитенциарной системы;\n' +
  '-\tв комплексе противоэпидемических мероприятий при ликвидации последствий чрезвычайных ситуаций.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерии (включая санитарно-показательную микрофлору);\n' +
  '- вирусы (включая группу вирусов полиомиелита - ЕСНО6).',
  [PRODUCT_MENU_NAMES.CHARACTER]:'- “ДезОР”, согласно ГОСТ 12.1.007.76, относится к 4 классу малоопасных веществ при введении в желудок и нанесении на кожу.\n- Содержит косметический силикон, защищающий кожу рук от неблагоприятного воздействия вредных химических факторов в процессе работы, особенно при длительной работе в перчатках.\n- Время высыхания более длительное, чем у антисептиков на основе спиртов.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'- Гигиеническая антисептика рук: На сухие, чистые руки нанести 3 мл средства ДезОР, массирующими движениями втирать в течение 60 сек. в кожу по методике, рекомендованной ЕN 1500. По окончании экспозиции руки досушивают на воздухе до полного высыхания средства. В случаях сильного загрязнения рук, перед проведением гигиенической обработки необходимо тщательно вымыть руки водой комнатной температуры с жидким мылом и высушить. Средство наносить только на чистую, сухую кожу. Для точного дозирования средства и в целях предупреждения контаминации продукта рекомендуется использовать настенные локтевые дозаторы.',
};
