import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { INKRASLAV_SMALL_DESCRIPTION, INVADES_SMALL_DESCRIPTION, COMPANIES } from 'constants/Navigation';
import SearchField from 'components/SearchField';
import Menu from 'components/Menu';

import './Header.styles.pcss';
import './AnimatedLogo.pcss';

const HEADER_HEIGHT = 75;

export default class Header extends PureComponent {
  static propTypes = {
    setActiveCompany: PropTypes.func,
    activeCompany: PropTypes.string,
  };

  static defaultProps = {
    setActiveCompany: () => undefined,
    activeCompany: COMPANIES.INKRASLAV,
  };

  constructor(props) {
    super(props);

    this.state = {
      isMenuFloating: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset > HEADER_HEIGHT && this.state.isMenuFloating === false) {
      this.setState({ isMenuFloating: true });
    } else if (window.pageYOffset < HEADER_HEIGHT && this.state.isMenuFloating === true) {
      this.setState({ isMenuFloating: false });
    }
  };

  render() {
    const { isMenuFloating } = this.state;

    const { activeCompany, setActiveCompany } = this.props;

    let colorClass;
    let smallCompanyDescriptionText;

    if (activeCompany === COMPANIES.INKRASLAV) {
      colorClass = 'inc-back-color';
      smallCompanyDescriptionText = `- ${INKRASLAV_SMALL_DESCRIPTION}`;
    } else {
      colorClass = 'inv-back-color';
      smallCompanyDescriptionText = `- ${INVADES_SMALL_DESCRIPTION}`;
    }

    return (
      <div className="header">
        <hr className={clsx('header_hr', colorClass)} />

        <div className="header_logo-line">
          <div className="header_logo-wrapper">
            <img
              style={{ display: activeCompany === COMPANIES.INKRASLAV ? 'flex' : 'none' }}
              src={'/images/smallLogoBlue.png'}
              alt="smallLogoBlue.png"
            />
            <img
              style={{ display: activeCompany === COMPANIES.INVADES ? 'flex' : 'none' }}
              src={'/images/smallLogoRed.png'}
              alt="smallLogoRed.png"
            />
          </div>

          <div className="header_logo-slider">
            <ul className="header_logo-slides">
              <li className={clsx({ active: activeCompany === COMPANIES.INKRASLAV })}>
                <h2>{COMPANIES.INKRASLAV}</h2>
              </li>
              <li className={clsx({ active: activeCompany === COMPANIES.INVADES })}>
                <h2>{COMPANIES.INVADES}</h2>
              </li>
            </ul>
          </div>

          <div className="header_small-description">
            <h5>{smallCompanyDescriptionText}</h5>
          </div>
          <SearchField className="header_search" colorClass={colorClass} activeCompany={activeCompany} />
        </div>

        <Menu
          setActiveCompany={setActiveCompany}
          activeCompany={activeCompany}
          colorClass={colorClass}
          isMenuFloating={isMenuFloating}
        />
      </div>
    );
  }
}
