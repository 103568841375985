import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {ADV_DEZIN, ANTIMIKROB_DEZIN, DESTINY_DEZIN, SOSTAV_DEZIN, SFERE_DEZIN} from "../constants/Catalog";

export default {
	id:"INKRASEPT_INV",
  name: 'Инкрасепт-10А',
  name_addition: 'универсальное дезинфицирующее средство с моющим эффектом.',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.ONE,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.VET, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: ADV_DEZIN.GYAN,

  sostav: '',
  naznach: 'Для дезинфекции, в том числе совмещенной с предстерилизационной очисткой, изделий медицинского назначения, мытья и дезинфекции поверхностей, предметов ухода, посуды (столовой, кухонной, лабораторной, аптечной), дезинфекции белья (постельного, нательного), санитарной одежды, уборочного инвентаря и текстиля, дезинфекции перед утилизацией одноразовых изделий медицинского назначения, СИЗ, перевязочного материала, одноразовых изделий из нетканых материалов, обеззараживания биологических жидкостей, жидких медицинских и пищевых отходов',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/INKRASEPT.jpg',
  packing: '1л, 5л',
  documentLink: '/documents/InstrInkrasept10A.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- полигексаметиленгуанидин гидрохлорид - 10%;\n- неионогенные ПАВ до 10%;\n- функциональные добавки (хелатный агент ЭДТА) не содержит отдушек и красителей.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'для мытья и дезинфекции поверхностей, дезинфекции и ПСО инструментов (в том числе с применением ультразвуковых устройств).',
  [PRODUCT_MENU_NAMES.PRACTICE]:'учреждения здравоохранения (препарат выбора для отделений педиатрического и аллергологического профиля, интенсивной терапии и гемодиализа), предприятия пищевой промышленности и общественного питания, торговли, детские и учебные заведения, объекты социальной и коммунальной сферы (салоны красоты пирсинга, татуировки, парикмахерские, педикюрные, маникюрные кабинеты), транспорт;',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	Бактерицидный (вкл. туберкулез, тестировано на М.terrae);\n- Фунгицидный (дрожжевые грибы рода Кандида);\n'+
  '- Вирулицидный (тестировано на ЕСНО 6 - группа вирусов полиомиелита, парентеральные гепатиты, энтеровирусы, грипп, в т.ч. вирус птичьего гриппа, штамм Н51N1, свиного гриппа, штамм Н1N1 и др.).',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'не содержит летучих аллегоопасных компонентов, рабочие растворы без  запаха, не обладает коррозионной активностью, отличается идеальной совместимостью с любыми влагостойкими материалами и коррозионно-устойчивыми металлами и сплавами (включая медь, 1Нодировэнный алюминий, изделия с хромо-никелевым покрытием).',
    [PRODUCT_MENU_NAMES.MODE]: `<div style="text-align: center;">
     <table class="table table-responsive table-bordered table-hover" style="margin-left: 6pt; margin-right: 6pt;">
<tbody>
<tr>
	<td rowspan="2">
		<p style="text-align: center; text-align: center;">Режим
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; text-align: center;">мин.
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; text-align: center;">мин.
		</p>
	</td>
</tr>
<tr>
	<td colspan="2">
		<p style="text-align: center; text-align: center;">для поверхностей
		</p>
	</td>
	<td colspan="2">
		<p style="text-align: center; text-align: center;">для инструментов
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin-right: 0cm; margin-left: 11pt; text-align: center;">Бактерицидный
		</p>
		<p style="text-align: center; margin-right: 0cm; margin-left: 11pt; text-align: center;">Вирулицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,25
		</p>
		<p style="text-align: center; text-align: center;">0,5
		</p>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
		<p style="text-align: center; text-align: center;">30
		</p>
		<p style="text-align: center; text-align: center;">15
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,75
		</p>
		<p style="text-align: center; text-align: center;">1
		</p>
		<p style="text-align: center; text-align: center;">2
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
		<p style="text-align: center; text-align: center;">30
		</p>
		<p style="text-align: center; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">Фунгицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">30
		</p>
	</td>
	<td rowspan="2">
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td rowspan="2">
		<p style="text-align: center; text-align: center;">60
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">Туберкулоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; text-align: center;">Обеззараживание медицинских и пищевых отходов, биологических жидкостей
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">2
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; text-align: center;">Дезинфекция яйца пищевого
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,75
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">10
		</p>
	</td>
</tr>
</tbody>
</table>
  </div>`
};
