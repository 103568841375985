import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import { ADV_DEZIN, ANTIMIKROB_DEZIN, SOSTAV_DEZIN, DESTINY_DEZIN, SFERE_DEZIN } from "../constants/Catalog";


export default {
  id:"Anasept",
  name: 'Анасепт',
  name_addition: 'Универсальное дезинфицирующе-моющее средство.',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.TWO,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.CHAS],

  sostav: '',
  naznach: 'для мытья и дезинфекции поверхностей, дезинфекции и ПСО многоразовых инструментов и других изделий медицинского назначения, предметов ухода, посуды (столовой, кухонной, лабораторной, аптечной), белья (постельного, нательного, одноразовых изделий из нетканых материалов), уборочного инвентаря и текстиля, дезинфекции перед утилизацией одноразовых изделий, обеззараживания биологических жидкостей, жидких медицинских и пищевых отходов',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Anasept.png',
  packing: '1л,5л',
  documentLink: '/documents/InstrAnasept.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'алкилдиметилбензиламмоний хлорид -	5,5%,\n полигексаметиленгуанидин гидрохлорид - 2,5%,\n функциональные добавки.\n',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'Одинаково хорошо подходит как для дезинфекции и предстерилизационной очистки изделий медназначения (в том числе с применением ультразвуковых устройств), так и для мойки и дезинфекции поверхностей, предметов ухода, белья, посуды, уборочного инвентаря и других изделий. Краткая характеристика: Не содержит летучих и высокотоксичных веществ (альдегидов, фенолов).',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'Невысокая стоимость рабочих растворов, универсальность, отсутствие резкого запаха и хорошие потребительские свойства делают «Анасепт» базовым препаратом для программ дезинфекции в организациях здравоохранения, на объектах торговли, общественного питания, социальной сферы, а так же в детских и учебных заведениях.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n - вирулицидный (включая ВИЧ, парентеральные гепатиты, ЕСНО6 - группа вирусов полиомиелита);\n - фунгицидный (дрожжевые грибы).',
  [PRODUCT_MENU_NAMES.CHARACTER]: '',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'',
  [PRODUCT_MENU_NAMES.ACT_MECHANICS]: '',
  [PRODUCT_MENU_NAMES.MODE]: `<div style="text-align: center;">
     <table class="table table-responsive table-bordered table-hover" style="margin-left: 6pt; margin-right: 6pt;">
<tbody>
<tr>
	<td rowspan="2">
		<p style="text-align: center; text-align: center;">Режим
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; text-align: center;">мин.
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; text-align: center;">мин.
		</p>
	</td>
</tr>
<tr>
	<td colspan="2">
		<p style="text-align: center; text-align: center;">для поверхностей
		</p>
	</td>
	<td colspan="2">
		<p style="text-align: center; text-align: center;">для инструментов
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin-right: 0cm; margin-left: 11pt; text-align: center;">Бактерицидный
		</p>
		<p style="text-align: center; margin-right: 0cm; margin-left: 11pt; text-align: center;">Вирулицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,25
		</p>
		<p style="text-align: center; text-align: center;">0,5
		</p>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
		<p style="text-align: center; text-align: center;">30
		</p>
		<p style="text-align: center; text-align: center;">15
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">0,5
		</p>
		<p style="text-align: center; text-align: center;">1
		</p>
		<p style="text-align: center; text-align: center;">2
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
		<p style="text-align: center; text-align: center;">30
		</p>
		<p style="text-align: center; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">Фунгицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">30
		</p>
	</td>
	<td rowspan="2">
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td rowspan="2">
		<p style="text-align: center; text-align: center;">60
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; text-align: center;">Туберкулоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">1
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; text-align: center;">Обеззараживание медицинских и пищевых отходов, биологических жидкостей
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">2
		</p>
	</td>
	<td>
		<p style="text-align: center; text-align: center;">60
		</p>
	</td>
</tr>
</tbody>
</table>
  </div>`
};
