import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {ADV_DEZIN, ANTIMIKROB_DEZIN, DESTINY_DEZIN, SOSTAV_DEZIN, SFERE_DEZIN} from "../constants/Catalog";


export default {
  id:"Izapin",
  name: 'Изапин',
  name_addition: 'высококонцентрированное универсальное дезинфицирующее средство с моющими свойствами', // новое поле
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.TWO,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.CHAS],
  sostav: '',
  naznach: 'Для дезинфекции, в том числе совмещенной с предстерилизационной очисткой, изделий медицинского назначения, мытья и дезинфекции поверхностей, предметов ухода, посуды (столовой, кухонной, лабораторной, аптечной), дезинфекции белья (постельного, нательного), санитарной одежды, уборочного инвентаря и текстиля, дезинфекции перед утилизацией одноразовых изделий медицинского назначения, СИЗ, перевязочного материала, одноразовых изделий из нетканых материалов, обеззараживания биологических жидкостей, жидких медицинских и пищевых отходов',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Izapin.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrIzapin.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- алкилдиметилбензиламмоний хлорид - 10%;\n- полигексаметиленгуанидин гидрохлорид - 5%;\n - функциональные добавки.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n - вирулицидный (включая парентеральные гепатиты, энтеровирусы, полиомиелит);\n - фунгицидный (дрожжевые грибы).',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'в организациях здравоохранения, на объектах торговли и общественного питания, социальной и коммунальной сферы, а так же в детских и учебных заведениях.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'для дезинфекции, в т.ч. совмещенной с ПСО в одном этапе хирургических, стоматологических инструментов, микроинструментария, лабораторной посуды, одноразовых ИМН перед утилизацией; поверхностей в помещениях и на транспорте; аппаратов, приборов, крупногабаритного оборудования, жесткой мебели; сантехоборудования; уборочного материала и инвентаря; предметов ухода за больными; белья (нательного, постельного, одежды персонала); посуды (лабораторной, кухонной, столовой, в том числе в детских учреждениях); игрушек; кувезов для недоношенных детей.\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: '',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="text-align: center; text-align: center;">для поверхностей
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">для инструментов
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Бактерицидный
			</p>
			<p style="text-align: center; text-align: center;">Вирулицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">90
			</p>
			<p style="text-align: center; text-align: center;">45
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Фунгицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">90
			</p>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">90
			</p>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="4">
			<p style="text-align: center; text-align: center;">Обеззараживание медицинских и пищевых отходов, биологических жидкостей
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
