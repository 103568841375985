import { all } from 'redux-saga/effects';

import routerSaga from './router';

export default function* rootSaga() {
  yield all([
    routerSaga(),
    // logoutSaga(),
    // uploadFilesSaga(),
    // here can be only domain sagas
  ]);
}
