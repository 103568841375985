import React, { PureComponent } from 'react';

import './Footer.styles.pcss';

export default class Footer extends PureComponent {
  render() {
    return (
      <div className="footer">
        <div className="footer_content">
          <div className="footer-block">
            <div className="footer_logo-text">
              <h1>Контакты</h1>
            </div>
            <div>
              <div className="footer_var">
                <h3>Дирекция/бухгалтерия</h3>
              </div>
              <div className="footer_text">
                <h4>{'+375 (17) 518-10-85'}</h4>
              </div>
              <div className="footer_text">
                <h4>{'+375 (17) 518-10-86'}</h4>
              </div>
            </div>
            <div>
              <div className="footer_var">
                <h3>Отдел сбыта</h3>
              </div>
              <div className="footer_text">
                <h4>{'+375 (17) 515-11-88'}</h4>
              </div>
              <div className="footer_text">
                <h4>{'A1/MTC: 333-97-81'}</h4>
              </div>
              {/*<div className="footer_text">*/}
              {/*  <h4>{'+375 (17) 373-26-03'}</h4>*/}
              {/*</div>*/}
            </div>
          </div>
          <div className="footer-block">
            <div className="footer_logo-text">
              <h1>Адрес</h1>
            </div>
            <div className="footer_text">
              <h4>{'Беларусь, Минский район, д. Боровляны,  223053'}</h4>
            </div>
            <div className="footer_text">
              <h4>ул. Интернациональная, 35/2-127</h4>
            </div>
            <div className="footer_var">
              <h3>Email</h3>
            </div>
            <div className="footer_text">
              <h4>inkraslav@gmail.com</h4>
            </div>
            {/*<div className="footer_text">*/}
            {/*  <h4>invadez@tut.by</h4>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    );
  }
}
