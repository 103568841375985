import { COMPANIES } from 'constants/Navigation';
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"UNISAN",
  name: 'УНИСАН',
  name_addition: 'гелеобразное концентрированное пенное кислотное моющее средство',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.KIS,
    sort_destiny: DESTINY_WASH.STO,
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],

  sostav: 'комплекс ПАВ, КОН и вспомогательных компонентов.Содержание щелочи (КОН) 4±1%.',
  naznach: 'для удаления минерализованных загрязнений, а также сопутствующих смешанных загрязнений с санитарных поверхностей.',
  sf_primen: '',
  image: '/images/UNISAN.jpg',
  packing: '0,5л, 1л, 5л',
  documentLink: '/documents/InstrUNISAN.PDF',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  'кислота лимонная, анионные ПАВ - до 5%, неионогенные ПАВ - до 5%, загуститель, ингибиторы коррозии, отдушки, краситель. Содержит масло чайного дерева, обладающее выраженным деодорирующим эффектом.',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'для удаления минерализованных загрязнений (мочевого камня, солей жесткости и ржавчины), органических загрязнений, мыльных разводов с санитарно-технического оборудования (в том числе смесителей, душевых насадок) и поверхностей всех видов.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '- в организациях здравоохранения;\n' +
  '- на объектах торговли, общественного питания (рестораны, столовые) и коммунально-бытового обслуживания (сауны, бани); \n' +
  '- в детских дошкольных и образовательных учреждениях;\n' +
  '- в оздоровительных учреждениях и на спортивных объектах (бассейны, аквапарки, санитарные помещения спорткомплексов); \n' +
  '- в местах проведения массовых спортивных,  культурно-зрелищных мероприятий и на других объектах массового пребывания людей (кинотеатры, концертные залы); \n' +
  '- на объектах железнодорожного, воздушного транспорта (вокзалы, аэропорты), в поездах, самолетах; \n' +
  '- в учреждениях социальной сферы (приюты, дома инвалидов и престарелых), в гостиницах, общежитиях, пансионатах, санаториях, домах отдыха; \n' +
  '- в общественных, автономных туалетах и биотуалетах; \n' +
  '- на производственных предприятиях и в учреждениях пенитенциарной системы;\n',
  [PRODUCT_MENU_NAMES.SPECTRUM]:'',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'■ Представляет собой однородный прозрачный или опалесцирующий гель синего цвета с запахом парфюмерной отдушки, хорошо фиксируется на наклонных и вертикальных поверхностях, обеспечивая выдерживание времени экспозиции.\n' +
  '■ Обеспечивает визуальную маркировку обработанной поверхности, что гарантирует качественное смывание препарата по окончании обработки.\n' +
  '■ Подходит для применения на ограниченно кислотостойких поверхностях (таких как акрил, эмаль).\n' +
  '■ Не оказывает повреждающего воздействия на изделия, изготовленные из влагостойких материалов (стекла, фаянса, резины, пластмассы, керамики, линолеума, окрашенной древесины), коррозионно-устойчивых металлов и сплавов (хромированных, никелированных, из нержавеющей стали) не вызывает коррозии металлов;\n' +
  '■ Обладает хорошей моющей способностью;\n' +
  '■ Не горюч, не взрывоопасен ',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  '- Средство «УНИСАН» согласно ГОСТ 12.1.007.76 относится к 3 классу (вещества умеренно опасные). Все работы с применением средства необходимо проводить в резиновых перчатках.\n' +
  '- Не смешивать с другими моющими и чистящими  средствами.\n' +
  '- Хранить при температуре не ниже 0°С, не допускать замораживания.\n',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:
      '- Мытье поверхностей и крупно-габаритного оборудования: 1-10 мл средства растворить в 10 л воды. Поверхности протирают ветошью, смоченной рабочим раствором. На ограниченные участки, имеющие интенсивные застарелые загрязнения, нанести неразбавленное средство на 5-10 минут, а затем очистить механически, поверхности, контактирующие с продуктами питания, необходимо протереть чистой влажной ветошью.\n' +
      '- Мытье внутренних поверхностей унитазов, писсуаров: неразбавленное средство нанести на внутреннюю поверхность изделия , выдержать экспозицию 5 – 20 мин, очистить механически, смыть водой. При наличии сильных загрязнений увеличить экспозицию  до одного часа или повторить процедуру.\n' +
      '- Мытье ванн, раковин, душевых кабин, поддонов, сантехнических приборов  и других санитарно-технических поверхностей: небольшое количество (0,5-1мл) средства нанести на влажную губку, вспенить, равномерно распределить пену по поверхности. При необходимости выдержать экспозицию до 15 мин (для ограниченно кислотостойких поверхностей – не более 1 мин, предварительно попробовав на незаметном участке). При наличии особо-устойчивых загрязнений повторить процедуру. После очистки поверхности промыть водой.',
  [PRODUCT_MENU_NAMES.MODE]:
  `<div style="text-align: center;">
<table class="table table-responsive table-bordered table-hover">
<tbody>
<tr>
	<td rowspan="2">
		<p style="text-align: center; margin: 0cm; text-align: center;">Режим
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">мин.
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">мин.
		</p>
	</td>
</tr>
<tr>
	<td colspan="2">
		<p style="text-align: center; margin: 0cm; text-align: center;">для поверхностей
		</p>
	</td>
	<td colspan="2">
		<p style="text-align: center; margin: 0cm; text-align: center;">для инструментов
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Бактерицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,05
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,1
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,3
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">120
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">90
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
	<td rowspan="5">
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2.5
		</p>
	</td>
	<td rowspan="5">
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Вирулицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">(Кандиды)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">(Дерматофиты)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Туберкулоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Анаэробные инфекции
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">4,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">4,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">(Плесень)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
	<td>
	</td>
	<td>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Овоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">90
		</p>
	</td>
	<td>
	</td>
	<td>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">ДВУ
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">4.0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5.0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">7.0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">8.0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">Стерилизация
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">6,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">8,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">10,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">Медицинские и пищевые отходы
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">Биологические жидкости (кровь, моча, фекалии, мокрота, смывные воды, в т.ч. эндоскопические)и посуда из-под выделений больного
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
</tbody>
</table>
</div>`,
};
