import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";

export default {
  id:"SlavinDelta",
  name: 'Славин-дельта',
  name_addition: 'Концентрированное универсальное дезинфицирующее средство с моющими свойствами для инструментов и поверхностей.',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.THREE,
    sort_destiny: [DESTINY_DEZIN.UNI,DESTINY_DEZIN.DVY,DESTINY_DEZIN.STR],
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.DER, ANTIMIKROB_DEZIN.PLE, ANTIMIKROB_DEZIN.SPR, ANTIMIKROB_DEZIN.OOI],
    sort_adv: [ADV_DEZIN.ALD],
  sostav: '',
  naznach: 'Для очистки, дезинфекции, ДВУ и стерилизации инструментов (включая эндоскопическое оборудование), дезинфекции и мытья поверхностей в помещениях.',
  sf_primen: 'Бактерицидный (включая туберкулез и особо опасные инфекции), вирулицидный, фунгицидный, спороцидный.',
  image: '/images/SlavinDelta.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrSlavinDelta.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- глутаровый альдегид-3%;\n- ПГМГ гидрохлорид-2,5%;\n- алкилдиметилбензиламмоний хлорид-2,5%;\n - функциональные добавки.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая внутрибольничные штаммы и микобактерии туберкулеза, тестировано на М.Теrrае);\n - особо опасные инфекции - чума'+
  ' (тестировано на Yersinia рestis), холера (тестировано на Vibrio сhоlerae 01 нетоксигенный), туляремия (тестировано на Francisella tularensis), бруцеллез '+
  '(тестировано на Brucella abortus), сибирская язва (тестировано на Bacillus anthracis);\n - вирулицидный (включая аденовирусы, герпес, грипп другие возбудители '+
  'респираторных вирусных инфекций, вирусы энтеральных и парентеральных гепатитов А, В, С, ВИЧ, ротавирусы, энтеровирусы, полиомиелит);\n - фунгицидный (включая дрожжевые, '+
  'плесневые грибы, дерматофиты);\n - спороцидный (в том числе, споровые формы возбудителей сибирской язвы и клостридиальных анаэробных инфекций, тестировано на '+
  'Clostridium perfringens тип CN205).',
  [PRODUCT_MENU_NAMES.PRACTICE]: '-	в организациях здравоохранения (в т.ч. многопрофильные и специализированные стационары, ожоговые и асептические отделения),\n'+
  ' -	на объектах коммунальной и социальной сферы, на предприятиях торговли, на транспорте (стационарные объекты и подвижной состав),\n'+
  ' -	на объектах пенитенциарной системы.\n'+
  ' -	для дезинфекции медицинских и пищевых отходов;\n'+
  ' -	дезинфекции, очистки, мойки и дезодорирования мусороуборочного оборудования, мусоровозов, мусорных баков, контейнеров для отходов, мусоропроводов, накопительных баков автономных туалетов, поверхностей в кабинах общественных, автономных туалетов и биотуалетов;\n'+
  ' -	для дезинфекции объектов окружающей среды при чрезвычайных ситуациях техногенного и природного характера.',
  [PRODUCT_MENU_NAMES.PURPOSE]:'для дезинфекции, в т.ч. совмещенной с ПСО (в т.ч. с применением ультразвуковых устройств) изделий медицинского назначения (включая эндоскопическое оборудование), хирургических, стоматологических инструментов, микроинструментария; дезинфекции лабораторной посуды, одноразовых изделий медназначения, перевязочного материала и СИЗ перед утилизацией; для дезинфекции и мойки поверхностей в помещениях, санитарно-технического оборудования, уборочного материала и инвентаря, предметов ухода за больными; белья (нательного, постельного, спецодежды персонала); посуды (столовой, кухонной, лабораторной); для стерилизации и ДВУ термолабильных изделий медназначения; для обеззараживания биологических жидкостей.',
  [PRODUCT_MENU_NAMES.CHARACTER]:'взаимодействие катионактивных ПАВ (полигуанидин и ЧАС) и комплекса «альдегид-органическая кислота» создает условия для формирования устойчивых хеллатных комплексов с ионами Са и Мg в оптимальном диапазоне рН, что позволяет получить стабильную композицию, обладающую выраженным синергизмом и полным спектром антимикробного действия.',
  [PRODUCT_MENU_NAMES.MODE]:`<div style="text-align: center;">
	<div style="text-align: center;">
		<div style="text-align: center;">
			<table class="table table-responsive table-bordered table-hover">
			<tbody>
			<tr>
				<td rowspan="2">
					<p style="text-align: center; text-align: center;">Режим
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">Концентрация,
					</p>
					<p style="text-align: center; text-align: center;">%
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">Экспозиция,
					</p>
					<p style="text-align: center; text-align: center;">мин.
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">Концентрация,
					</p>
					<p style="text-align: center; text-align: center;">%
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">Экспозиция,
					</p>
					<p style="text-align: center; text-align: center;">мин.
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<p style="text-align: center; text-align: center;">для поверхностей
					</p>
				</td>
				<td colspan="2">
					<p style="text-align: center; text-align: center;">для инструментов
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Бактерицидный
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,03
					</p>
					<p style="text-align: center; text-align: center;">0,05
					</p>
					<p style="text-align: center; text-align: center;">0,1
					</p>
					<p style="text-align: center; text-align: center;">0,3
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
					<p style="text-align: center; text-align: center;">5
					</p>
				</td>
				<td rowspan="6">
					<p style="text-align: center; text-align: center;">0,25
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td rowspan="6">
					<p style="text-align: center; text-align: center;">45
					</p>
					<p style="text-align: center; text-align: center;">20
					</p>
					<p style="text-align: center; text-align: center;">10
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Вирулицидный
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,1
					</p>
					<p style="text-align: center; text-align: center;">0,2
					</p>
					<p style="text-align: center; text-align: center;">0,4
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Туберкулоцидный
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,2
					</p>
					<p style="text-align: center; text-align: center;">0,4
					</p>
					<p style="text-align: center; text-align: center;">0,8
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Фунгицидный
					</p>
					<p>(Дерматофиты)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,25
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Фунгицидный
					</p>
					<p>(Кандиды)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,25
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Фунгицидный
					</p>
					<p>(Плесень)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,2
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Чума
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
				</td>
				<td rowspan="3">
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td rowspan="3">
					<p style="text-align: center; text-align: center;">60
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Холера (протирание)
					</p>
					<p>Туляремия (протирание)
					</p>
					<p>Бруцеллез (протирание)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,25
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Холера (орошение)
					</p>
					<p>Туляремия (орошение)
					</p>
					<p>Бруцеллез (орошение)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
					<p style="text-align: center; text-align: center;">2,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td>
					<p>Сибирская язва.
					</p>
					<p>Клостридиалыные
					</p>
					<p>анаэробные инфекции
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">3,0
					</p>
					<p style="text-align: center; text-align: center;">4,0
					</p>
					<p style="text-align: center; text-align: center;">5,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">120
					</p>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">3,0
					</p>
					<p style="text-align: center; text-align: center;">4,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">120
					</p>
					<p style="text-align: center; text-align: center;">60
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>ДВУ
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">3.0
					</p>
					<p style="text-align: center; text-align: center;">4.0
					</p>
					<p style="text-align: center; text-align: center;">5,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">90
					</p>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Стерилизация
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">3,5
					</p>
					<p style="text-align: center; text-align: center;">4.5
					</p>
					<p style="text-align: center; text-align: center;">5.5
					</p>
					<p style="text-align: center; text-align: center;">6,5
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">90
					</p>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Медицинские отходы при вирусных, грибковых (Кандиды, дерматофиты) и бактериальных инфекциях (вкл. Туберкулез)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,25
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">90
					</p>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Медицинские отходы при ООИ (чума, холера, туляремия, бруцеллез)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">1,0
					</p>
					<p style="text-align: center; text-align: center;">2,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Медицинские отходы при клостридиальных анаэробных инфекциях, сибирской язве и других споровых формах
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">3,0
					</p>
					<p style="text-align: center; text-align: center;">4,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">120
					</p>
					<p style="text-align: center; text-align: center;">60
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Биологический материал (кровь, моча, фекалии, промывные воды) при вирусных, грибковых (Кандиды) и бактериальных инфекциях (вкл. Туберкулез, ООИ и анаэробные инфекции)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,7
					</p>
					<p style="text-align: center; text-align: center;">1,2
					</p>
					<p style="text-align: center; text-align: center;">2,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Биологический материал (рвотные массы, мокрота) при вирусных, грибковых (Кандиды) и бактериальных инфекциях (вкл. Туберкулез, ООИ и анаэробные инфекции)
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">1,0
					</p>
					<p style="text-align: center; text-align: center;">1,5
					</p>
					<p style="text-align: center; text-align: center;">2,5
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Пищевые отходы
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">0,25
					</p>
					<p style="text-align: center; text-align: center;">0,5
					</p>
					<p style="text-align: center; text-align: center;">1,0
					</p>
				</td>
				<td>
					<p style="text-align: center; text-align: center;">60
					</p>
					<p style="text-align: center; text-align: center;">30
					</p>
					<p style="text-align: center; text-align: center;">15
					</p>
				</td>
			</tr>															
			</tbody>
			</table>
		</div>
	</div>
</div>`,
};
