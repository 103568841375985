import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {DESTINY_ANTISEP, SFERE_ANTISEP, SOSTAV_ANTISEP} from "../constants/Catalog";

export default {
  id:"IODISKIN",
  name: 'Йодискин',
  name_addition: 'бесспиртовое антисептическое лекарственное средство на основе повидон-йода.',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_ANTISEP.POV,
    sort_destiny: [DESTINY_ANTISEP.GAR, DESTINY_ANTISEP.OPP,DESTINY_ANTISEP.INP,DESTINY_ANTISEP.SLI],
    sort_sfere: [SFERE_ANTISEP.MED],
    sostav: '',
  naznach: 'Раствор для наружного применения, предназначенный для антисептической обработки неповрежденных кожных покровов и слизистых оболочек, инъекционного, манипуляционного, операционного поля.',
  sf_primen: 'бактерицидный (вкл. микобактерии туберкулеза – тестировано на М.terrae), фунгицидный, вирулицидный.',
  image: '/images/IODISKIN.JPG',

  packing: '0,5л, 1л',
  documentLink: '/documents/InstrIODISKIN.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'в 1 мл лекарственного средства: действующего вещества повидон-йода – 20 мг (что эквивалентно 2,0 мг активного йода); вспомогательные компоненты: полиэтиленгликоль-400, эфиры целлюлозы, кислота лимонная моногидрат, вода очищенная.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '■гигиеническая антисептика рук персонала организаций здравоохранения;\n'+
  '■антисептическая обработка слизистых оболочек и прилегающих кожных покровов в профилактических целях до и после проведения медицинских вмешательств;\n■антисептическая обработка кожных покровов пациентов перед проведением инвазивных манипуляций (инъекции, пункции, биопсии, трансфузии, забор крови);\n' +
  '■антисептическая обработка слизистых оболочек и кожных покровов пациентов перед проведением оперативных вмешательств;\n' +
  '■антисептическая обработка кожных покровов пациентов накануне проведения хирургических вмешательств и при оказании ухода за тяжелобольными и лежачими пациентами («дезинфицирующее купание»);',
  [PRODUCT_MENU_NAMES.PRACTICE]: '- в учреждениях здравоохранения;\n' +
  '- в других организациях, имеющих специальное разрешение (лицензию) на медицинскую или фармацевтическую деятельность (косметологические, стоматологические кабинеты и т.п.);\n' +
  '- в медицинских пунктах различных предприятий, учреждений, организаций;\n' +
  '- в санаторно-оздоровительных и лечебно-профилактических организациях.\n',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '- бактерицидный (включая возбудителей внутрибольничных инфекций и микобактерии туберкулеза, а так же в отношении широкого спектра патогенной и условно патогенной микрофлоры, локализованной на слизистой оболочке полости рта человека); \n' +
  '- вирулицидный (включая ротавирус, грипп, герпес, ВИЧ); \n' +
  '- фунгицидный (в отношении дрожжеподобных грибов рода Кандида) \n' +
  'Средство ЙОДИСКИН в виде 30,0 - 50,0% водного раствора обладает выраженной бактериостатической активностью в отношении госпитальных штаммов стафилококков, энтеробактерий и псевдомонад.\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: '- Препарат не содержит спиртов, обладает выраженным пролонгированным антимикробным действием. Обеспечивает обеззараживающий эффект  в течение  не менее 3 часов при работе в перчатках.\n' +
  ' - Препарат малотоксичен, местно-раздражающие, кожно-резорбтивные и сенсибилизирующие свойства в рекомендованных режимах применения не выявлены. \n' +
  ' - Темно-коричневый цвет раствора указывает на его эффективность. Обесцвечивание является признаком снижения эффективности противомикробного действия. Воздействие света и температур, превышающих 40°С, способствует ускоренному распаду йода, что сопровождается обесцвечиванием раствора.\n' +
  '- Средство ЙОДИСКИН легко удаляется с текстильных и других материалов с помощью теплой воды. Для удаления трудно выводимых пятен следует использовать тиосульфат натрия.\n' +
  'Для точного дозирования средства и в целях предупреждения контаминации продукта рекомендуется использовать настенные локтевые дозаторы.\n',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:  '- Гигиеническая антисептика рук: на сухие, чистые руки нанести  3 мл средства ЙОДИСКИН, массирующими движениями втирать в течение 1 мин. по методике, рекомендованной          EN 1500. По окончании экспозиции руки досушивают на воздухе до полного высыхания средства. \n' +
  '- Антисептическая обработка слизистых оболочек и прилегающих кожных покровов 50% водным раствором средства ЙОДИСКИН:  обработка слизистых оболочек в гинекологической, проктологической, урологической, стоматологической практике может проводиться тампонным методом, а так же методом орошения при помощи ирригатора или промывания.\n' +
  '- Антисептическая обработка кожных покровов пациентов перед проведением инвазивных манипуляций: на сухую чистую кожу при помощи стерильного ватно-марлевого тампона нанести 1-2 мл средства ЙОДИСКИН, время экспозиции составляет 1-2 минуты.\n' +
  '- Антисептическая обработка кожных покровов пациентов перед проведением оперативных вмешательств: при помощи стерильных раздельных ватно-марлевых тампонов двукратно нанести средство ЙОДИСКИН на обрабатываемый участок кожи, добиваясь равномерного и тщательного увлажнения. Сначала – широко, с захватом прилегающих зон, затем более узко, в пределах зоны оперативного вмешательства. Общее время экспозиции должно составлять не менее 2 минут.\n' +
  '- Антисептическая обработка кожных покровов пациентов накануне проведения хирургических вмешательств и при оказании ухода за тяжелобольными и лежачими пациентами («дезинфицирующее купание»): непосредственно перед проведением процедуры средство ЙОДИСКИН разбавить водой очищенной (дистиллированной) 1:2 для получения 30% раствора. Салфеткой или тампоном, смоченным в 30% растворе ЙОДИСКИНА кожу очистить от загрязнений и биологичеких субстратов (слизь, гной, кровь, рвотные или каловые массы). Затем при помощи другого ватно-марлевого тампона или салфетки нанести 30% раствор ЙОДИСКИНА на обрабатываемый участок слизистой оболочки или кожи на 2 минуты. \n' +
  '30% раствор ЙОДИСКИНА может также наноситься непосредственно на предварительно очищенные кожные покровы методом орошения при помощи ирригатора (спрей-насадки). \n' +
  'После окончания экспозиционной выдержки кожу промыть теплой водой питьевого качества. Не допускать попадания в глаза! Не применять при повышенной индивидуальной чувствительности к йоду.\n',
};
