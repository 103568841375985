import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {SOSTAV_ANTISEP,SFERE_ANTISEP,DESTINY_ANTISEP,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";//'constants/Catalog';
import {SOSTAV_DEZIN,SOSTAV_MED,DESTINY_MED} from "../constants/Catalog";

export default {
  id:"KVINTASEPT",
  name: 'Квинтасепт',
  name_addition: 'Мыло жидкое с дезинфицирующим эффектом /кожный антисептик/',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: [ SOSTAV_MED.BAK, SOSTAV_MED.VIR, SOSTAV_DEZIN.ONE, SOSTAV_ANTISEP.AMI ],
    sort_destiny: [DESTINY_ANTISEP.GAR,DESTINY_MED.MRP,DESTINY_MED.GAR],
    sort_sfere: [SFERE_ANTISEP.MED, SFERE_ANTISEP.FPR, SFERE_ANTISEP.KOM, SFERE_ANTISEP.OBR, SFERE_ANTISEP.PIS, SFERE_ANTISEP.TRS],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: ADV_DEZIN.AMI,

  sostav: '',
  naznach: '- для гигиенической обработки рук и санитарной обработки кожных покровов, мытья посуды и уборки любых твердых поверхностей.',
  sf_primen: 'бактерицидный, фунгицидный',
  image: '/images/KVINTASEPT.JPG',

  packing: '0,5л, 1л, 5л',
  documentLink: '/documents/InstrKVINTASEPT.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'АДВ - додецилдипропилентриамин 0,5%, а так же ПАВ, функциональные и вспомогательные компоненты.\n' +
  'рН 10% раствора средства 7,0.\n',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  '- гигиеническая обработка рук медицинского персонала (в том числе хирургов, оперирующего медицинского персонала, акушерок и других лиц, участвующих в приеме родов и др.) перед обработкой антисептиком; \n' +
  '- гигиеническая обработка рук перед и после проведения медицинских манипуляций работниками ЛПУ и ЛПО (включая онкологического, гематологического, хирургического и инфекционного профиля), роддомов, детских дошкольных и школьных учреждений, учреждений соцобеспечения (дома престарелых, инвалидов и др.);\n' +
  '- гигиеническая обработка рук и санитарная обработка кожных покровов в санпропускниках и ЛПУ; служащих коммунальных и социальных служб, на стационарных и подвижных объектах железнодорожного транспорта и метрополитена;\n' +
  '- гигиеническая обработка рук работников организаций общественного питания, предприятий пищевой промышленности и др. промышленных предприятий;\n' +
  '- гигиеническая обработка рук сотрудников парфюмерно-косметических предприятий, лабораторий (биохимических, бактериологических и т.п.), учреждений культуры, спорта и отдыха; банков, гостиниц, а также в любых других учреждениях, где предписана антисептическая обработка кожи;\n' +
  '- гигиеническая обработка рук и ступней ног в целях профилактики грибковых заболеваний населением в быту, в местах массового посещения и длительного пребывания людей (гостиницы, общежития, вокзалы, общественные туалеты и пр.);\n' +
  '- гигиеническая обработка рук и санитарная обработка кожных покровов представителей силовых ведомств, в т.ч. спасателей МЧС, личного состава войск и формирований ГО; пациентов лечебно-профилактических учреждений и организаций (в т.ч. перед оперативными вмешательствами и в процессе ежедневного ухода за лежачими больными с целью снижения риска возникновения гнойно-воспалительных заболеваний кожи и устранения неприятного запаха), включая пациентов отделений гериатрического, онкологического профиля; лиц, поступающих в   приемные  отделения   стационаров (после травм, аварий, автомобильных катастроф); в учреждениях социальной защиты (хосписы, дома-интернаты для инвалидов и лиц пожилого возраста, социальные приюты для лиц без определенного места жительства, с подозрением на инфекционные и кожно-венерологические заболевания); в учреждениях пенитенциарной системы;\n' +
  '-для мытья посуды и уборки любых твердых поверхностей в быту и на предприятиях разного профиля.\n',
  [PRODUCT_MENU_NAMES.PRACTICE]: '-	в организациях здравоохранения различного профиля;\n' +
  '- в клинических, серологических и микробиологических лабораториях;\n' +
  '- в патологоанатомических отделениях и бюро судебно-медицинской экспертизы, в моргах, крематориях, траурных залах при оказании ритуальных и похоронных услуг;\n' +
  '- в учреждениях социальной сферы (приюты, дома инвалидов и престарелых);\n' +
  '- в детских дошкольных и образовательных учреждениях;\n' +
  '- на транспорте (санитарном, общественном, грузовом);\n' +
  '- в очагах инфекционных заболеваний, при организации ухода за тяжело больными и лежачими больными;\n' +
  '- на предприятиях пищевой промышленности, общественного питания, продовольственной торговли, потребительских рынках;\n' +
  '- на предприятиях парфюмерно-косметической, биотехнологической, фармацевтической промышленности (при производстве нестерильных лекарственных средств, в помещениях класса чистоты С и О);\n' +
  '- в парикмахерских, соляриях, салонах красоты, маникюрных и педикюрных кабинетах, салонах пирсинга и татуировки;\n' +
  '- в общежитиях, на производственных предприятиях, объектах коммунально-бытового обслуживания (сауны, бани);\n' +
  '- в санаторно-курортных и оздоровительных учреждениях;\n' +
  '- на спортивных объектах (тренажерные залы, бассейны, залы для занятий травматичными видами спорта) и пр. объектах массового пребывания людей;\n' +
  '- в учреждениях пенитенциарной системы;\n' +
  '- в комплексе противоэпидемических мероприятий при ликвидации последствий чрезвычайных ситуаций.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: 'Жидкое мыло «Квинтасепт» обладает бактерицидной (кроме микобактерий туберкулеза) и фунгицидной активностью в отношении дрожжеподобных грибов и трихофитий.\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Средство сохраняет свои свойства после замораживания и последующего оттаивания.\n' +
  'Средство обладает пролонгированным антимикробным действием не менее 3 часов.\n'+
  'Средство обладает смягчающими и увлажняющими кожу свойствами.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:
  '- Гигиеническая обработка рук медицинского персонала (в том числе хирургов, оперирующего медицинского персонала, акушерок и других лиц, участвующих в приеме родов и др.) перед обработкой антисептиком: средство наносят на влажную кожу обеих рук в количестве 3 мл. Намыливают руки (кисти, запястья, предплечья), обрабатывают полученной пеной в течение 1 минуты, тщательно смывают проточной водой. Кожу рук вытирают стерильными салфетками.\n' +
  '- Гигиеническая обработка рук, санитарная обработка кожных покровов, гигиеническая обработка ступней ног: на влажную кожу кистей рук, предплечий, ступней ног наносят 2 мл средства, намыливают, обрабатывают полученной пеной в течение 1 минуты и тщательно смывают проточной водой. Кожные покровы вытирают салфеткой или полотенцем.\n'+
  '- Для мытья твёрдых поверхностей с получением бактерицидного эффекта рекомендуется применение раствора жидкого мыла способом протирания и погружения в зависимости от степени и характера загрязнений. Для слабозагрязненных поверхностей используется раствор мыла в разведении 1:100; для сильно загрязненных поверхностей используется раствор мыла в разведении 1:33. Растворы готовят в ёмкостях из любого материала. Концентрат добавляют в воду и перемешивают. Время экспозиции 3-5 минут в зависимости от степени загрязнения поверхностей. Смывание остатков раствора с обработанных поверхностей не требуется.\n'+
  '- При обеззараживании и мытье посуды небольшое количество мыла наносят на губку или непосредственно на посуду, моют и смывают под проточной водой не менее 1 минуты.',
};
