import { CATALOG, NAVIGATION } from 'redux/actionTypes';

import { COMPANIES } from 'constants/Navigation';
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';

const initialState = {
  activeCompany: COMPANIES.INKRASLAV,
  selectedTab: PRODUCT_MAIN_CATEGORIES.DEZIN,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NAVIGATION.SET_ACTIVE_COMPANY: {
      return {
        ...state,
        activeCompany: action.payload,
      };
    }
    case CATALOG.UPDATE_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
