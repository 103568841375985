import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {SOSTAV_DEZIN} from "constants/Catalog";
import {ADV_DEZIN, ANTIMIKROB_DEZIN, DESTINY_DEZIN, SFERE_DEZIN} from "../constants/Catalog";


export default {
  id:"Peroxin",
  name: 'Пероксин',
  name_addition: ' высококонцентрированное средство для дезинфекции поверхностей, предметов ухода, столовой и  лабораторной посуды, биологических жидкостей.', // новое поле
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.TWO,
    sort_destiny: DESTINY_DEZIN.POV,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.PLE, ANTIMIKROB_DEZIN.DER],
    sort_adv: [ADV_DEZIN.PEVD],
  sostav: '',
  naznach: 'Для дезинфекции и мытья (в том числе способом орошения) поверхностей в помещениях.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный (дрожжи, плесень, дерматофиты)',
  image: '/images/Peroxin.jpg',
  packing: '1л',
  documentLink: '/documents/InstrPeroxin.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '-\tперекись водорода - 10%;\n- полигексаметиленгуанидин гидрохлорид - 2,5%;\n - ингибитор коррозии, комплексообразователь, неионогенные ПАВ.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n - вирулицидный (включая парентеральные гепатиты, ВИЧ, энтеровирусы);\n - фунгицидный (включая дерматофиты, дрожжевые и плесневые грибы рода Аз.Niger).',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'организации здравоохранения, предпочтительно в отделениях интенсивной терапии, хирургического и акушерско-гинекологического профиля,'+
  ' в клинических и серологических лабораториях, операционных и процедурных кабинетах. На объектах массового скопления людей (вокзалы, гостиницы, дома отдыха, места '+
  'проведения спортивно-зрелищных мероприятий). В детских и образовательных учреждениях, на коммунальных объектах (бани, сауны, парикмахерские, массажные, косметические салоны, '+
  'солярии, прачечные и т.д.). В учреждениях социальной сферы. На предприятиях общественного питания, пищевой, перерабатывающей, парфюмерно-косметической, фармацевтической, '+
  'биотехнологической промышленности. На транспорте (автомобильном, железнодорожном, авиационном, пассажирском, общественном, грузовом, предназначенном для перевозки'+
  ' продуктов питания).\n',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'обеззараживание и очистка поверхностей, жесткой мебели, уборочного инвентаря, санитарно-технического оборудования, предметов ухода за больными, '+
  'посуды (столовой, лабораторной), белья (нательного, постельного, санитарной одежды персонала), детских игрушек и прочих объектов, а так же инактивация биологических жидкостей.\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Широчайший спектр действия, высокая очищающая способность. Средство прекрасно удаляет органические загрязнения, в том числе стойкие и застарелые.'+
  ' Совместимо с любыми влагостойкими материалами (за исключением металлов и сплавов из цветных металлов). Безопасно для окружающей среды, содержит только биоразлагаемые компоненты.'+
  ' Не содержит красителей, отдушек, токсичных летучих компонентов (хлора, альдегидов, фенола). Рабочий раствор без резкого запаха. Пары концентрата и рабочих растворов не '+
  'раздражают слизистые оболочки верхних дыхательных путей и глаз. Разрешено применение в присутствии людей, не требуется применения средств индивидуальной защиты кк верхних '+
  'дыхательных путей.',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-right: 0cm; margin-left: 1pt; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; margin-right: 0cm; margin-left: 1pt; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">для поверхностей
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Бактерицидный
			</p>
			<p style="text-align: center; text-align: center;">Фунгицидный (грибы рода Кандида)
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Вирулицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Плесневые грибы
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Дерматофиты
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">1,5
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">90
			</p>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
		<tr>
		<td>
			<p style="text-align: center; text-align: center;">Дезинфекция жидких медицинских отходов(кровь, моча, мокрота, смывные жидкости)
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">2,5
			</p>
			<p style="text-align: center; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">120(смешивание 2:1)
			</p>
			<p style="text-align: center; text-align: center;">120(смешивание 1:1)
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
