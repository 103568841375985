import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";


export default {
  id:"Sten",
  name: 'СТЭН',
  name_addition: 'Высококонцентрированное универсальное средство для химической стерилизации и дезинфекции.', // новое поле
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.TWO,
    sort_destiny: [DESTINY_DEZIN.UNI,DESTINY_DEZIN.DVY,DESTINY_DEZIN.STR],
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.SPR],
    sort_adv: [ADV_DEZIN.PEVD],
  sostav: '',
  naznach: 'Для дезинфекции, в том числе совмещенной с предстерилизационной очисткой, изделий медицинского назначения, ДВУ и стерилизации термолабильных изделий, включая эндоскопическое оборудование, мытья и дезинфекции поверхностей.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный, спороцидный',
  image: '/images/Sten.jpg',
  packing: '1л',
  documentLink: '/documents/InstrSten.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '-\tперекись водорода - 15%;\n- полигексаметиленгуанидин гидрохлорид - 4,5%;\n - ингибитор коррозии, комплексообразователь, неионогенные ПАВ.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n - вирулицидный (включая возбудителей гемоконтактных, воздушнокапельных и кишечных вирусных инфекций);\n - фунгицидный (дрожжевые грибы);\n - спороцидный.',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'организации здравоохранения, предпочтительно в отделениях интенсивной терапии, хирургического и акушерско-гинекологического профиля,'+
  ' в клинических и серологических лабораториях, операционных и процедурных кабинетах.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'Средство предназначено для дезинфекции поверхностей (в организациях здравоохранения различного профиля, в том числе ожоговых и асептических отделениях); ПСО и дезинфекции инструментов, изготовленных из различных материалов, в т.ч. коррозионно-устойчивых металлов и сплавов; предварительной и окончательной очистки, дезинфекции высокого уровня и стерилизации термолабильных изделий медицинского назначения (тубусов, катетеров, жестких и гибких эндоскопов, анестезиологической аппаратуры, наружных контуров наркознодыхательного оборудования).',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Пары рабочих растворов СТЭНа малоопасны при ингаляционном воздействии. В отличие от средств на основе альдегидов или надуксусной кислоты '+
  '(обладающих аналогичным спктром противомикробного действия), СТЭН в рабочих концентрациях не имеет резкого запаха, пары не раздражают слизистую оболочку дыхательных путей и глаз, средство '+
  'не обладает сенсибилизирующим эффектом, разрешено к применению в присутствии пациентов, в том числе детей. Дезинфектант обладает выраженной очищающей способностью, '+
  'прекрасно удаляет биологические загрязнения и потемнение металлических инструментов. Совместим со всеми материалами и узлами эндоскопического оборудования (фиброоптика, '+
  'резина, силикон, клеевые массы, полимерные материалы, нержавеющая сталь), за исключением анодированного алюминия, цветных металлов и сплавов. рН 1% раствора =3. Средство '+
  'прекрасно подходит для дезинфекции различных изделий, поверхностей, лабораторного и технологического инвентаря и оборудования на объектах общественного питания, торговли, '+
  'предприятиях пищевой, перерабатывающей, фармацевтической, биотехнологической, парфюмернокосметической промышленности. Показатели эффективности и безопасности соответствуют '+
  'самым высоким требованиям действующего законодательства, что позволяет уверенно применять СТЭН в детских и образовательных заведениях, на объектах социальной, коммунальной '+
  'сферы, пенитенциарной системы.',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover" style="margin-left: 6pt; margin-right: 6pt;">
	<tbody>
	<tr>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">для поверхностей
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; margin-left: 20pt; text-align: center;">для инструментов
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Бактерицидный
			</p>
			<p style="text-align: center; text-align: center;">Вирулицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">45
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td rowspan="3">
			<p style="text-align: center; margin-left: 20pt; text-align: center;">2,0
			</p>
			<p style="text-align: center; margin-left: 20pt; text-align: center;">5,0
			</p>
		</td>
		<td rowspan="3">
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Фунгицидный (Кандиды)
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">ДВУ жестких.эндоскопов
			</p>
		</td>
		<td>
		</td>
		<td>
		</td>
		<td>
			<p style="text-align: center; margin-left: 20pt; text-align: center;">2,0
			</p>
			<p style="text-align: center; margin-left: 20pt; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">ДВУ гибких эндоскопов
			</p>
		</td>
		<td>
		</td>
		<td>
		</td>
		<td>
			<p style="text-align: center; margin-left: 20pt; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Стерилизация
			</p>
		</td>
		<td>
		</td>
		<td>
		</td>
		<td>
			<p style="text-align: center; margin-left: 20pt; text-align: center;">5,0
			</p>
			<p style="text-align: center; margin-left: 18pt; text-align: center;">10.0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">240
			</p>
			<p style="text-align: center; text-align: center;">60
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
