import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";


export default {
  id:"Septanes",
  name: 'СЕПТАНЕС',
  name_addition: 'Дезинфицирующе-моющее средство.',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.THREE,
    sort_destiny: DESTINY_DEZIN.POV,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ ],
    sort_adv: [ADV_DEZIN.CHAS],

    sostav: '',
  naznach: 'Для дезинфекции и очистки поверхностей в помещениях и на транспорте.\n',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Septanes.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrSeptanes.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- алкилдиметилбензиламмоний хлорид - 5,5%;\n- полигексаметиленгуанидин гидрохлорид - 2,5%;\n - этиловый спирт - 4,5%; \n - функциональные добавки.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n - Вирулицидный (включая парентеральные гепатиты, группа вирусов полиомиелита, энтеровирусы, грипп, вт.ч. вирус птичьего гриппа, штамм Н5N1 и др.);\n - фунгицидный (дрожжевые грибы).',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'организации здравоохранения, предприятия торговли и общественного питания, пищевой, парфюмерно-косметической, химико-фармацевтической и биотехнологической промышленности по производству нестерильных лекарственных средств (помещения класса чистоты С и D), детские, образовательные и оздоровительные учреждения, транспорт (общественный, санитарный, для перевозки продуктов питания), спортивные, общественные, коммунальные и социальные объекты и другие места массового скопления людей (гостиницы, концертные залы, игровые центры, вокзалы, общественные туалеты и т.д.), учреждения пенитенциарной системы.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'Дезинфицирующе-моющее средство для ежедневной влажной уборки и дезинфекции помещений, поверхностей приборов, аппаратов, жесткой мебели, предметов ухода за больными, посуды, белья, детских игрушек, уборочного инвентаря, санитарно-технического оборудования, и прочих объектов.',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Отличается выраженной моющей способностью и дезодорирующими свойствами. Совместим с любыми влагостойкими материалами, не '+
  'оказывает повреждающего действия на изделия, изготовленные из металлов и сплавов, синтетические и искусственные ткани, в том числе ярко окрашенные. '+
  'Не содержит отдушек и летучих токсичных компонентов.',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Бактерицидный
			</p>
			<p style="text-align: center; text-align: center;">Фунгицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Вирулицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">90
			</p>
			<p style="text-align: center; text-align: center;">60
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
