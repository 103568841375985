import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import { SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN } from "../constants/Catalog";


export default {
  id:"Slavin",
  name: 'Славин',
  name_addition: 'универсальное средство для мытья и дезинфекции поверхностей, предстерилизационной очистки и дезинфекции инструментов.', // новое поле
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.TWO,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: SFERE_DEZIN.MED,
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.ALD],
  sostav: '',
  naznach: 'для дезинфекции инструментов, в том числе совмещенной с ПСО, мытья и дезинфекции поверхностей.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Slavin.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrSlavin.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- полигексаметиленгуанидин гидрохлорид - 4,5%;\n- глутаровый альдегид - 2,5%;\n - функциональные добавки.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n- вирулицидный (включая ВИЧ, парентеральные гепатиты, группа вирусов полиомиелита - тестировано на ЕСНО-6);\n- фунгицидный (дрожжевые грибы, дерматофиты).',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'препарат рекомендован для дезинфекции в организациях здравоохранения, на объектах коммунальной, социальной сферы, пенитенциарной системы.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'для дезинфекции и предстерилизационной очистки инструментов и других изделий медназначения, дезинфекции поверхностей помещений, оборудования, предметов ухода, белья, посуды, уборочного инвентаря.\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Синергический эффект при взаимодействии стабилизированного глутарового альдегида с ПГМГ гидрохлоридом и неионогенными ПАВ позволил существенно снизить концентрацию альдегида в рабочих растворах и, тем самым, улучшить токсикологический профиль препарата, рабочие растворы разрешены к применению без защиты органов дыхания и в присутствии пациентов. Предпочтительной областью применения «Славина» является заключительная дезинфекции в очагах инфекционных заболеваний, проведение генеральных уборок в учреждениях здравоохранения, дезинфекционные мероприятия в лечебных помещениях, а так же дезинфекция изделий медназначения.',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<div style="text-align: center;">
		<table class="table table-responsive table-bordered table-hover" style="margin-left: -62pt;">
		<tbody>
		<tr>
			<td>
				<p style="margin: 0cm; text-align: justify;"><a name="OLE_LINK134" id="OLE_LINK134"></a><a name="OLE_LINK133" id="OLE_LINK133"></a><a name="_Hlk24377220" id="_Hlk24377220">Режим</a>
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">%
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">мин.
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">%
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">мин.
				</p>
			</td>
		</tr>
		<tr>
			<td>
			</td>
			<td colspan="2">
				<p style="text-align: center; margin: 0cm; text-align: center;">Для поверхностей
				</p>
			</td>
			<td colspan="2">
				<p style="text-align: center; margin: 0cm; text-align: center;">Для инструментов
				</p>
			</td>
		</tr>
		<tr>
			<td>
				<p style="margin: 0cm; text-align: justify;">Бактерицидный
				</p>
				<p style="margin: 0cm; text-align: justify;">Фунгицидный (Кандиды)
				</p>
				<p style="margin: 0cm; text-align: justify;">Вирулицидный
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">0,5
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">1,0
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">2,0
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">60
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">30
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">10
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">0,5
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">1,0
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">2,0
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">60
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">30
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">10
				</p>
			</td>
		</tr>
		<tr>
			<td>
				<p style="margin: 0cm; text-align: justify;">Фунгицидный (Дерматофиты)
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">0,75
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">60
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">0,75
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">60
				</p>
			</td>
		</tr>
		<tr>
			<td>
				<p style="margin: 0cm; text-align: justify;">Туберкулоцидный
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">1,0
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">1,2
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">90
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">60
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">1,0
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">1,2
				</p>
			</td>
			<td>
				<p style="text-align: center; margin: 0cm; text-align: center;">90
				</p>
				<p style="text-align: center; margin: 0cm; text-align: center;">60
				</p>
			</td>
		</tr>
		</tbody>
		</table>
	</div>
</div>`,

};
