import { LOCATION_CHANGE } from 'connected-react-router';

export default function(state = {}, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        ...state,
        location: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
