import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {
  SOSTAV_DEZIN,
  DESTINY_DEZIN,
  SFERE_DEZIN,
  ANTIMIKROB_DEZIN,
  ADV_DEZIN,
  SFERE_ANTISEP,
} from '../constants/Catalog';

export default {
  id:"ROSA",
  name: 'РОСА-СПРЕЙ',
  name_addition: 'готовое к применению средство дезинфицирующее для экстренной дезинфекции',
  company: COMPANIES.INKRASLAV,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.THREE,
    sort_destiny: DESTINY_DEZIN.EKS,
    sort_sfere: [SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_ANTISEP.PIS, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.SPIR],

    sostav: '',
  naznach: 'Для дезинфекции поверхностей.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/ROSA.jpg',

  packing: '0,75л, 5л',
  documentLink: '/documents/InstrROSA.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- спирт изопропиловый - 40%;\n- алкилдиметилбензиламмоний хлорид - 0,025%;\n- полигексаметиленбигуанидин гидрохлорид - 0,05%;\n- функциональные добавки.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'Ярко выраженный синергический эффект активно-действующих веществ и вспомогательных компонентов обеспечивает мгновенное обеззараживание и отличное очищающее действие. Средство предназначено для дезинфекции небольших по площади или труднодоступных поверхностей, жесткой мебели и оборудования, санитарного транспорта, мелких предметов, обуви, резиновых, пластиковых и полипропиленовых ковриков. Эффективно удаляет органические загрязнения, пото-жировые следы и пятна крови.',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'организации здравоохранения различного профиля, предприятия торговли и общественного питания, пищевой, парфюмерно-косметической, химико-фармацевтической и биотехнологической промышленности по производству нестерильных лекарственных средств, детские, образовательные и оздоровительные учреждения, транспорт, спортивные, общественные, коммунальные и социальные объекты, учреждения пенитенциарной системы.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '- Бактерии (включая микобактерии туберкулеза, тестировано на М. tеrrае); \n- Дрожжевые грибы рода Кандида; \n- Вирусы (включая парентеральные гепатиты, ВИЧ, полиомиелит, респираторные вирусные инфекции)\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'Широкий спектр антимикробной активности, минимальное время экспозиционной выдержки (вирулицидный режим - 30 сек., туберкулез -1 мин.). Великолепно удаляет загрязнения, полностью испаряется, не оставляет мутных разводов на глянцевых поверхностях. Прекрасно подходит для очистки и дезинфекции стеклянных, зеркальных, металлических поверхностей. Флаконы имеют удобную эргономичную форму, оснащены спрей-насадками для орошения.',
  [PRODUCT_MENU_NAMES.MODE]: `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">для поверхностей
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Бактерицидный
			</p>
			<p style="text-align: center; text-align: center;">Вирулицидный
			</p>
			<p style="text-align: center; text-align: center;">Фунгицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">100%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">30 сек.
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">100%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1 мин.
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
