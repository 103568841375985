import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {SOSTAV_DEZIN} from 'constants/Catalog';
import {SOSTAV_ANTISEP,SFERE_ANTISEP,DESTINY_ANTISEP,ANTIMIKROB_DEZIN,ADV_DEZIN} from 'constants/Catalog';
import {SFERE_MED,SOSTAV_MED,DESTINY_MED} from "../constants/Catalog";

export default {
  id:"KVINTASEPT_INV_MeM",
  name: 'Квинтасепт',
  name_addition: 'Мыло жидкое с дезинфицирующим эффектом /кожный антисептик/',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: [ SOSTAV_MED.BAK, SOSTAV_MED.VIR, SOSTAV_DEZIN.ONE, SOSTAV_ANTISEP.AMI ],
    sort_destiny: [DESTINY_ANTISEP.GAR,DESTINY_MED.MRP,DESTINY_MED.GAR],
    sort_sfere: [SFERE_ANTISEP.MED, SFERE_ANTISEP.FPR, SFERE_ANTISEP.KOM, SFERE_ANTISEP.OBR, SFERE_ANTISEP.PIS, SFERE_ANTISEP.TRS,SFERE_MED.FPR,SFERE_MED.KOM,SFERE_MED.MED,SFERE_MED.OBR,SFERE_MED.PIS],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: ADV_DEZIN.AMI,

  sostav: '',
  naznach: '- для мытья и гигиенической антисептики рук, санитарной обработки кожных покровов, мытья и очистки небольших объектов внешней среды, предметов ухода.',
  sf_primen: 'бактерицидный, вирулицидный',
  image: '/images/KVINTASEPT.JPG',

  packing: '0,5л, 1л, 5л',
  documentLink: '/documents/InstrKVINTASEPT_INV.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'АДВ - додецилдипропилентриамин 0,5%, а так же ПАВ, функциональные и вспомогательные компоненты.\n' +
  'рН 10% раствора средства 7,0.\n',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  '- для мытья рук медицинского персонала (в том числе хирургов, оперирующего медицинского персонала, акушерок и других лиц, участвующих в приеме родов и др.) перед нанесением антисептика и надеванием медицинских перчаток при выполнении инвазивных медицинских манипуляций; \n' +
  '- гигиенической антисептики рук работников организаций здравоохранения, лабораторий, работников патологоанатомических отделений и бюро судебно-медицинской экспертизы после контакта с объектами, потенциально загрязненными биологическими субстратами; перед контактом и после контакта с неповрежденной кожей и слизистыми оболочками пациента; после снятия перчаток при проведении инвазивных медицинских вмешательств или выполнении работ, при которых возможен контакт с потенциально загрязненными объектами (сортировка медицинских отходов, грязного белья и пр.); \n' +
  '- гигиенической антисептики рук и санитарной обработки кожных покровов работников детских учреждений и учебных заведений, учреждений соцобеспечения (дома престарелых, инвалидов и др.); служащих коммунальных и социальных служб, на стационарных и подвижных объектах железнодорожного транспорта и метрополитена;\n' +
  '- гигиенической антисептики рук работников объектов торговли, предприятий общественного питания, пищевой и перерабатывающей промышленности, др. промышленных предприятий (парфюмерно-косметических, фармацевтических, биотехнологических);\n' +
  '- гигиенической антисептики рук работников, учреждений культуры, спорта и отдыха; банков, гостиниц, а также в любых других учреждениях, где предписана антисептическая обработка рук;\n' +
  '- гигиенической антисептики рук и санитарной обработки кожных покровов представителей силовых ведомств, в т.ч. спасателей МЧС, личного состава войск и формирований ГО; \n' +
  '- гигиенической антисептики рук и санитарной обработки кожных покровов пациентов лечебно-профилактических учреждений и организаций (в т.ч. перед оперативными вмешательствами и в процессе ежедневного ухода за лежачими больными с целью снижения риска возникновения гнойно-воспалительных заболеваний кожи и устранения неприятного запаха), включая пациентов отделений гериатрического, онкологического профиля; лиц, поступающих в   приемные  отделения   стационаров (после травм, аварий, автомобильных катастроф); в учреждениях социальной защиты (хосписы, дома-интернаты для инвалидов и лиц пожилого возраста, социальные приюты для лиц без определенного места жительства, с подозрением на инфекционные и кожно-венерические заболевания); в учреждениях пенитенциарной системы;\n' +
  '- для мытья и очистки объектов внешней среды -  предметов ухода, спортивного инвентаря, игрушек, прочих мелких изделий, поверхностей.',
  [PRODUCT_MENU_NAMES.PRACTICE]: '-	в организациях здравоохранения различного профиля;\n' +
  '- в клинических, серологических и микробиологических лабораториях;\n' +
  '- в патологоанатомических отделениях и бюро судебно-медицинской экспертизы, в моргах, крематориях, траурных залах при оказании ритуальных и похоронных услуг;\n' +
  '- в учреждениях социальной сферы (приюты, дома инвалидов и престарелых);\n' +
  '- в детских дошкольных и образовательных учреждениях;\n' +
  '- на транспорте (санитарном, общественном, грузовом);\n' +
  '- в очагах инфекционных заболеваний, при организации ухода за тяжело больными и лежачими больными;\n' +
  '- на предприятиях пищевой промышленности, общественного питания, продовольственной торговли, потребительских рынках;\n' +
  '- на предприятиях парфюмерно-косметической, биотехнологической, фармацевтической промышленности (при производстве нестерильных лекарственных средств, в помещениях класса чистоты С и О);\n' +
  '- в парикмахерских, соляриях, салонах красоты, маникюрных и педикюрных кабинетах, салонах пирсинга и татуировки;\n' +
  '- в общежитиях, на производственных предприятиях, объектах коммунально-бытового обслуживания (сауны, бани);\n' +
  '- в санаторно-курортных и оздоровительных учреждениях;\n' +
  '- на спортивных объектах (тренажерные залы, бассейны, залы для занятий травматичными видами спорта) и пр. объектах массового пребывания людей;\n' +
  '- в учреждениях пенитенциарной системы;\n' +
  '- в комплексе противоэпидемических мероприятий при ликвидации последствий чрезвычайных ситуаций.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '- бактерицидный (кроме микобактерий туберкулеза)\n' +
  '- вирулицидный (вкл. Группу вирусов полиомиелита – тестировано на ECHO6).',
  [PRODUCT_MENU_NAMES.CHARACTER]: '- По параметрам острой токсичности, при введении в желудок и нанесении на кожу согласно ГОСТ 12.1.007-76 относится к 4 классу малоопасных соединений. \n' +
  '- Местно-раздражающие, кожно-резорбтивные и сенсибилизирующие свойства не выражены. \n' +
  '- Не опасно при ингаляционном воздействии.\n' +
  '- Обладает смягчающими и  увлажняющими кожу свойствами.\n' +
  '- Не оказывает коррозирующего действия на изделия, изготовленные из коррозионно-стойких сталей, цветных металлов и сплавов на основе алюминия, титана и меди с защитно-декоративными покрытиями и без них; \n' +
  '- Не портит натуральные и синтетические ткани, изделия из резины, а также поверхности из кафеля, дерева с лакокрасочным покрытием, линолеума, полимерных материалов (пластмасса, акрил, оргстекло), стекла, керамики и фаянса.\n' +
  '- Сохраняет свои свойства после замораживания и последующего оттаивания.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:
  '- Мытье рук медицинского персонала перед обработкой антисептиком: средство наносят на влажную кожу рук, намыливают кисти, запястья, предплечья, тщательно смывают проточной водой.\n' +
  '- Гигиеническая антисептика рук, санитарная обработка кожных покровов: на влажную кожу наносят необходимое количество средства (для рук - в количестве не менее 3 мл), намыливают в течение 1 минуты и тщательно смывают проточной водой. Кожные покровы вытирают салфеткой или полотенцем.\n' +
  '- Мойка и очистка объектов внешней среды проводится способом протирания поверхностей  или погружения небольших изделий в раствор в разведении 1:100 (1 часть средства «Квинтасепт» на 100 частей воды питьевого качества). Ополаскивание поверхностей, не контактирующих с продуктами питания, не требуется.',
};
