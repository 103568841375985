import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {SOSTAV_MED} from "constants/Catalog";
import {DESTINY_MED, SFERE_MED} from "../constants/Catalog";

export default {
  id:"GREN",
  name: 'ГРИН серии «Софт-лайн»',
  name_addition: 'мыло жидкое',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_MED.BAS,
    sort_destiny: [DESTINY_MED.D3L,DESTINY_MED.MRP],
    sort_sfere: [SFERE_MED.FPR,SFERE_MED.KOM,SFERE_MED.MED,SFERE_MED.OBR,SFERE_MED.PIS],

  sostav: '',
  naznach: 'для мытья рук и тела',
  sf_primen: 'бактериостатический и фунгистатический',
  image: '/images/GREN.JPG',
  packing: '0.5л,1л,5л',
  documentLink: '',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'натрий лауретсульфат, глицерин,  диэтаноламиды жирных кислот кокосового масла, кокамидопропилбетаин,  соль поваренная, отдушка, трилон Б, кислота лимонная, 2-бром-2-нитропропандиол-1,3, краситель CI 42090.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'Рекомендуется к применению у детей с 3-х лет и взрослых.\n' +
  'Мягко очищает кожу. Ухаживающие компоненты способствуют увлажнению и смягчению кожных покровов, улучшают физиологическое состояние кожи, не нарушая ее естественного баланса.  Бодрящий аромат свежесрезанных побегов молодой крапивы создает длительное ощущение свежести, наполняет энергией и дарит позитивное настроение.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '• для мытья рук персонала в медицинских, социальных, детских учреждениях, на предприятиях пищевой промышленности и общественного питания, а так же объектах коммунального обслуживания населения - в парикмахерских, салонах красоты, в косметических и массажных кабинетах; \n' +
  '• для мытья рук и тела пациентов в медицинских, социальных, детских учреждениях;\n' +
  '• для мытья волос вместо шампуня при организации ухода за пациентами стационаров и социальных учреждений; \n' +
  '• в качестве геля для душа и пены для ванны при купании пациентов в организациях здравоохранения, домах инвалидов, пансионатах для лиц пожилого возраста, психо-неврологических диспансерах, приютах для лиц без определенного места жительства и т.д.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: 'Обладает бактериостатической и фунгистатической активностью.',
  [PRODUCT_MENU_NAMES.CHARACTER]:'Содержит компоненты, которые способствуют увлажнению и смягчению кожи, сохранению естественного кислотно-щелочного баланса. Не вызывает чувства стянутости и дискомфорта. Придает ноже приятный запах, оставляя ощущение свежести и чистоты на длительное время.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'Рекомендуется для ежедневного применения. \n' +
  '•для принятия ванны:   подставьте колпачок с мылом под струю воды;\n' +
  '•для душа:  нанесите немного средства на губку или марлевую салфетку;\n' +
  '•для мытья волос:  нанесите мыло на влажные волосы, слегка массируя кожу головы, смойте и при необходимости повторите процедуру;\n' +
  '•для мытья рук:  нанесите мыло на влажные руки, легкими массирующими движениями равномерно распределите пену по поверхности рук в течение не менее 10-15 сек, тщательно очищая все поверхности кистей рук, включая запястья, уделяя особое внимание межпальцевым промежуткам, а так же пространству под ногтями. Для достижения максимального эффекта обработку следует повторить дважды.',
};
