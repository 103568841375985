import React, { PureComponent } from 'react';
import Search from '@material-ui/icons/Search';
import clsx from 'clsx';
import flatten from 'lodash/flatten';
import toArray from 'lodash/toArray';
import { COMPANIES } from 'constants/Navigation';
import PRODUCTS from 'data';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import './SearchField.styles.pcss';

export default class SearchBar extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);

    this.inputRef = undefined;
    this.state = {
      focus: false,
      searchString: '',
    };
  }

  setFocus = () => {
    this.setState(() => ({
      focus: true,
    }));

    if (this.inputRef) this.inputRef.focus();
  };

  closeField = () => {
    setTimeout(() => {
      this.setState({
        focus: false,
        searchString: '',
      });
    },1000) // TODO replace with history redirect!
  };

  inputFocus = () => this.setState({ focus: true });

  inputBlur = event => {
    if (event?.relatedTarget?.className === 'search_link') return;
    this.closeField();
  };

  searchTextChangedHandler = ({ target }) => {
    this.setState({ searchString: target.value });
  };

  clickProduct = id => {
    this.closeField();
    window.location.href = `/catalog/${id}`;
  };

  searchOptions = () => {
    const { searchString } = this.state;
    if (!searchString)
      return (
        <div className="no-options-wrapper">
          <h5>{'Не найдено'}</h5>
        </div>
      );

    const options = flatten(toArray(PRODUCTS)).reduce((all, product) => {
      console.log({ all });
      if (all.length > 3) return null;
      if (!product) return null;

      let colorClass = 'inc-back-color';
      if (product.company === COMPANIES.INKRASLAV) {
        colorClass = 'inc-back-color';
      } else {
        colorClass = 'inv-back-color';
      }

      if (product.name.toLowerCase().startsWith(searchString.toLowerCase())) {
        const option = (
          <li
            className="search_li"
            key={`${product.id}${product.company}`}
            // data-id={product.id}
            onClick={() => this.clickProduct(product.id)}
          >
            <div className="search_link">
              <div className="row">
                <div className="column">
                  <h5 className={clsx('search_company', colorClass)}>{product.company}</h5>
                  <h6 className="search-name">{product.packing}</h6>
                </div>
                <h5 className="search_label">
                  {[`${product.name} -`, <span key={'name'} className="search_addition">{product.name_addition}</span>]}
                </h5>
              </div>
            </div>
          </li>
        );
        all.push(option);
      }
      return all;
    }, []);

    if (!options.length)
      return (
        <div className="no-options-wrapper">
          <h5>{'Не найдено'}</h5>
        </div>
      );
    return options;
  };

  render() {
    const { className } = this.props;
    const { focus, searchString } = this.state;
    const { setFocus } = this;

    return (
      <div className={clsx(className, 'search-fld')}>
        <div className={clsx('search-fld_content')}>
          <input
            ref={ref => {
              this.inputRef = ref;
            }}
            value={searchString}
            type="text"
            onFocus={this.inputFocus}
            onBlur={this.inputBlur}
            onChange={this.searchTextChangedHandler}
            className={focus ? 'focused' : ''}
            placeholder="Поиск"
          />
          <button type="button" onClick={setFocus} className="search-fld_icon">
            <Search />
          </button>
        </div>
        <ul className={clsx('search-fld_options', { disabled: !focus || !searchString })}>
          {this.searchOptions(searchString)}
        </ul>
      </div>
    );
  }
}
