import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"MOIKON_Pos_INV",
  name: 'МОЙКОН для посуды',
  name_addition: 'концентрированное пенное нейтральное моющее средство универсального назначения ',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.NET,
    sort_destiny: [DESTINY_WASH.POV,DESTINY_WASH.LAP,DESTINY_WASH.POS],
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],
  sostav: 'содержит комплекс неионогенных поверхностно-активных веществ (15% и более, но менее 30%), спирт этиловый марки «Экстра» 5%; полигексаметиленбигуанидин гидрохлорид 0,01%; полиэтиленгликоль, аллантоин, комплексообразователь, регулятор рН.',
  naznach: 'для мытья ручным способом различных поверхностей (полов, стен, поручней, окон), столовой и кухонной посуды, столовых приборов, инвентаря и оборудования, разделочных досок,  рабочих поверхностей и столов, внутренних поверхностей холодильных установок,   а так же  лабораторной и аптечной посуды, принадлежностей, уборочного инвентаря, ветоши, губок, щеток, игрушек, предметов ухода.',
  sf_primen: '',
  image: '/images/MOIKON_Pos.jpg',
  packing: '5л,1л',
  documentLink: '/documents/InstrMOIKON_Pos.PDF',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  '- содержит комплекс неионогенных поверхностно-активных веществ (15% и более, но менее 30%);\n' +
  '- спирт этиловый марки «Экстра» 5%; \n' +
  '- полигексаметиленбигуанидин гидрохлорид 0,01%;\n' +
  '- полиэтиленгликоль, аллантоин, комплексообразователь, регулятор рН.',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'для мытья ручным  способом различных поверхностей (полов, стен, поручней, окон),  столовой и кухонной посуды, столовых приборов, инвентаря и оборудования, разделочных досок,  рабочих поверхностей и столов, внутренних поверхностей холодильных установок,   а так же  лабораторной и аптечной посуды, принадлежностей, уборочного инвентаря, ветоши, губок, щеток, игрушек, предметов ухода.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '- в организациях здравоохранения, \n' +
  '- на предприятиях парфюмерно-косметической, фармацевтической, биотехнологической, пищевой промышленности и общественного питания, \n' +
  '- в учреждениях социальной сферы, \n' +
  '- в детских дошкольных и школьных учреждениях, учебных заведениях, \n' +
  '- в оздоровительных лагерях, учреждениях санаторно-курортного профиля и пр.\n',
  [PRODUCT_MENU_NAMES.SPECTRUM]:
  '■ обладает хорошей моющей способностью;\n' +
  '■ не горюч, не взрывоопасен.',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  '- содержит только биоразлагаемые компоненты;\n' +
  '- средство в концентрированном виде и рабочие растворы согласно ГОСТ 12.1.007-76 относятся к 4 классу (вещества малоопасные) при введении в желудок (DL50) и ингаляционном воздействии (С20 насыщающие концентрации), не обладают кожно-резорбтивным, кумулятивным и сенсибилизирующим действием; \n' +
  '- растворы средства не раздражают кожные покровы, при работе со средством защита рук резиновыми перчатками не требуется; \n' +
  '- содержит косметические компоненты, защищающие кожу рук от сухости и раздражения;\n' +
  '- не содержит красителей, летучих аллергоопасных или токсичных компонентов, не создает вредных испарений в воздухе;\n',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'■ обладает выраженной моющей способностью в отношении органических и смешанных загрязнений; \n' +
  '■ температура воды не влияет на моющую способность (хорошо отмывает посуду в холодной воде);\n' +
  '■ стабильность рабочих растворов 14 суток, визуально прозрачный (без инородных включений, хлопьев, осадка) раствор может использоваться многократно.\n' +
  '■ рН концентрата 5,5±1. \n' +
  '■ не вызывает коррозии металлов;  \n' +
  '■ не оказывают повреждающего воздействия на изделия, изготовленные из любых влагостойких материалов (стекла, фаянса, резины, пластмассы, керамики, линолеума, окрашенной древесины), искусственного и натурального камня, в т.ч.  мрамора, деликатных полимерных и композитных материалов, цветных металлов и сплавов, в т. ч. чувствительных к воздействию кислот и щелочей; \n' +
  '■ эффективно устраняет загрязнения различного происхождения, включая пятна от жира, сока, вина, чая, кофе с натуральных, искусственных и синтетических тканей, в т. ч. ярко-окрашенных, при этом не происходит обесцвечивание рисунка;\n' +
  '■ обладает хорошей моющей способностью по отношению к специфическим загрязнениям, характерным для лабораторной и аптечной посуды: удаляет смешанные и органические загрязнения (белковые, жировые в т.ч. иммерсионное масло), надписи маркером или восковым карандашом на стекле и пластмассе;\n' +
  '■ не содержит загустителей, обладает умеренным пенообразованием, быстро и легко смывается с посуды, полное отсутствие остатков средства на лабораторной и химической посуде повышает качество и точность измерений;\n' +
  '■ не оставляет разводов и пятен на глянцевых поверхностях, отлично подходит для мытья окон, стеклянных перегородок, застекленных дверей;\n' +
  '■ не требует смывания с поверхностей, не контактирующих с продуктами питания.\n' +
  '■ не горюч, не взрывоопасен;',
    /*  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="3">
			<p style="text-align: center; text-align: center;">Концентрация рабочего раствора (по препарату),%
			</p>
		</td>
		<td colspan="4">
			<p style="text-align: center; text-align: center;">Количество концентрата и воды, необходимое для приготовления:
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">1 литра раб. раствора
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">10 литров раб. раствора
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Концентрат, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Вода, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрат, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Вода, л
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,01
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,9
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,999
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0, 025
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,75
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,975
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,05
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,995
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,990
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">997,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,975
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">995
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">50
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,95
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">990
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">100
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,9
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">50
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">950
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">500
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,5
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">100
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">900
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">1 000
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,0
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,*/
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'- Может применяться для предварительного мытья поверхностей перед дезинфекцией при генеральной уборке, при этом используется 0,05% - 1% раствора «МОЙКОНА для посуды». Ополаскивание поверхностей перед дезинфекцией не требуется. Растворы средства «Мойкон для посуды» не снижают эффективности дезинфицирующих средств на основе ЧАС, полигуанидинов, аминов.\n' +
  '- Текущая уборка без применения средств дезинфекции: все поверхности протирают ветошью, смоченной 0,05% – 1% раствором. Смывание не требуется (кроме поверхностей, непосредственно контактирующих с продуктами питания).\n' +
  '- Мытье кухонной и столовой посуды (в т.ч. предназначенной для детского питания, вкл. бутылочки для новорожденных), небольших изделий и мелкого инвентаря: изделия погружают в 0,05-0,1% раствор средства на 5-15 минут, после чего очищают и ополаскивают последовательно в 2-х емкостях или под струей проточной воды питьевого качества;\n' +
  '- Уборочный материал, текстильные изделия замачивают в 1% рабочем растворе средства простирывают в том же растворе, выполаскивают и высушивают. На участки ткани, имеющие устойчивые загрязнения, наносится небольшое количество неразбавленного средства, выдерживается 5-10 мин.,\n' +
  '- Мытье лабораторной посуды: в емкость с 5% рабочим раствором средства помещают обрабатываемые изделия (предметные стекла, капилляры Паченкова, пипетки, кюветы, пробирки, наконечники дозаторов, планшеты, часовые стекла) на 5 - 30 мин. в зависимости от интенсивности и характера загрязнений, очищают, затем все изделия тщательно промывают под струей проточной воды или ополаскивают последовательно в 2-х емкостях: водой питьевого качества и водой дистиллированной.\n' +
  '- Для эффективного удаления чрезвычайно устойчивых загрязнений (иммерсионного масла, смол, красителей, надписей маркером, следов от воскового карандаша и т.д.) рекомендуется использовать 10% раствор средства.',
};
