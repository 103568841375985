import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";


export default {
  id:"Dyasept",
  name: 'ДУАСЕПТ',
  name_addition: 'Концентрированное универсальное дезинфицирующее средство с моющими свойствами.', // новое поле
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.THREE,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.MED, SFERE_DEZIN.OBR, SFERE_DEZIN.FPR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.PLE, ANTIMIKROB_DEZIN.DER, ANTIMIKROB_DEZIN.JAI, ANTIMIKROB_DEZIN.KAI],
    sort_adv: [ADV_DEZIN.AMI, ADV_DEZIN.ORK],

    sostav: '',
  naznach: 'Для дезинфекции и ПСО изделий медицинского назначения, мытья и дезинфекции поверхностей, предметов ухода, посуды, белья, уборочного инвентаря и текстиля, дезинфекции одноразовых изделий, обеззараживания биологических жидкостей, медицинских и пищевых отходов.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный, овоцидный',
  image: '/images/Dyasept.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrDyasept.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- додецилдипропилентриамин -10%;\n- алкилдиметилбензиламмоний хлорид - 6,5%;\n- органические кислоты - до 5%;\n - функциональные компоненты.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая внутрибольничные штаммы и микобактерии туберкулеза, тестировано на М.Теrrае, возбудителей анаэробных инфекций);\n - вирулицидный (включая ВИЧ, парентеральные гепатиты, группа вирусов полиомиелита);\n - фунгицидный (дрожжевые и плесневые грибы рода Аsр.Niger, дерматофиты);\n - овоцидный (возбудители кишечных гельминтозов, в том числе яйца глист).',
  [PRODUCT_MENU_NAMES.PRACTICE]: '-\tв учреждениях здравоохранения;\n' +
  '-\tв учреждениях социальной сферы и пенитенциарной системы;\n' +
  '-\tв детских дошкольных учреждениях и учебных заведениях;\n' +
  '-\tна коммунальных объектах (бани, сауны, парикмахерские, массажные и косметические салоны);\n' +
  '-\tв магазинах, на складах и базах;\n' +
  '-\tна предприятиях общественного питания, продовольственной торговли, потребительских рынках, коммунальных объектах, гостиницах, общежитиях, бассейнах, аквапарках, банях, саунах, местах массового скопления людей;\n' +
  '-\tна всех видах транспорта (санитарном, общественном, предназначенном для перевозки продуктов питания), мусоровозов;\n' +
  '-\tв кабинах общественных, автономных туалетов и биотуалетов;\n' +
  '-\tпри чрезвычайных ситуациях техногенного и природного характера.\n',
  [PRODUCT_MENU_NAMES.PURPOSE]:'-\tдля дезинфекции и предстерилизационной очистки изделий медназначения (включая эндоскопическое оборудование и инструменты), в том числе с использованием ультразвуковых устройств;\n'+
  '-\tмойки и дезинфекции поверхностей (в том числе кувезов и приспособлений к ним, комплектующих деталей наркозно-дыхательной аппаратуры, анестезиологического оборудования), предметов ухода, белья, посуды, уборочного инвентаря и других изделий;\n'+
  '-\tдезинфекции обуви из резин, пластика и других полимерных материалов с целью профилактики инфекций грибковой этиологии (дерматофитии);\n'+
  '-\tдля борьбы с плесенью\n'+
  '-\tдля дезинфекции медицинских и пищевых отходов\n'+
  '-\tдезинфекции, очистки, мойки и дезодорирования мусороуборочного оборудования, мусоровозов, мусорных баков, контейнеров для отходов\n'+
  '-\tдезинфекции мусоропроводов, накопительных баков автономных туалетов, поверхностей в кабинах общественных, автономных туалетов и биотуалетов; \n'+
  '-\tдля дезинфекции объектов окружающей среды при чрезвычайных ситуациях.\n',
  [PRODUCT_MENU_NAMES.CHARACTER]:'компонентный состав подобран с учетом современных технологий и требований к организации противоэпидемических и санитарно-гигиенических мероприятий на различных объектах, направленных на обеспечение инфекционной безопасности, профилактику формирования резистентности к дезинфицирующим средствам циркулирующей микрофлоры и снижение концентрации вредных испарений в воздухе закрытых помещений. Средство не содержит опасных, едких, ядовитых компонентов, не обладает кумулирующим и сенсибилизирующим эффектами. Высокая эффективность достигается за счет синергического взаимодействия активно-действующих веществ и вспомогательных компонентов.',
  [PRODUCT_MENU_NAMES.MODE]:  `<table class="table table-responsive table-bordered table-hover" style="margin-left: 6pt;">
<tbody>
<tr>
	<td rowspan="2">
		<p style="text-align: center; margin: 3pt; text-align: center;">Режим
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">мин.
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">мин.
		</p>
	</td>
</tr>
<tr>
	<td colspan="2">
		<p style="text-align: center; margin: 3pt; text-align: center;">для поверхностей
		</p>
	</td>
	<td colspan="2">
		<p style="text-align: center; margin: 3pt; text-align: center;">для инструментов
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Бактерицидный (кроме туберкулеза)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,01
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,05
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,1
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">5
		</p>
	</td>
	<td rowspan="5">
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
	</td>
	<td rowspan="5">
		<p style="text-align: center; margin: 3pt; text-align: center;">45
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">20
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">10
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Вирулицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,1
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Туберкулоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">(Кандиды)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">(Дерматофиты)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">90
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Анаэробные
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">инфекции
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">3,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;"><a name="OLE_LINK60" id="OLE_LINK60"></a>60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">2,0
		</p>
		<p style="text-align: center; text-align: center;">3,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">(Плесень)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,1
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
	<td>
	</td>
	<td>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">Овоцидный
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">(яйца глист)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">90
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
	</td>
	<td>
	</td>
	<td>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p>Предстерилизационная очистка ИМН, предварительная и окончательная очистка эндоскопов
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,01
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,01
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">20-30 (руч.)
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">5 (УЗ, металлы, стекло)
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p>Дезинфекция эндоскопов при инфекциях бактериальной (вкл. Туберкулез), вирусной и грибковой этиологии
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p>Дезинфекция отработанных медицинских изделий(одноразовых ИМН, медицинских перчаток, перевязочного материала, ватно-марлевых шариков, тампонов, одноразовой санитарной одежды, СИЗ)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">90
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="margin: 3pt;">Дезинфекция жидких медицинских отходов (кровь, моча, фекалии, мокрота, смывные воды, в т.ч. эндоскопические) и посуды из-под выделений больного
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">90
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p>Дезинфекция пищевых отходов
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,25
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">1,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 3pt; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 3pt; text-align: center;">15
		</p>
	</td>
</tr>
</tbody>
</table>`,

};
