import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MENU_NAMES, PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { DESTINY_MED, SOSTAV_MED, SFERE_MED } from "constants/Catalog";

export default {
  id:"AKVA",
  name: '«АКВА» серии «Софт-лайн»',
  name_addition: 'мыло жидкое',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_MED.BAS,
    sort_destiny: DESTINY_MED.MRP,
    sort_sfere: [SFERE_MED.FPR,SFERE_MED.KOM,SFERE_MED.MED,SFERE_MED.OBR,SFERE_MED.PIS],

  sostav: '',
  naznach: 'для мытья рук и тела',
  sf_primen: 'бактериостатический и фунгистатический',
  image: '/images/AKVA.JPG',
  packing: '0.5л,1л,5л',
  documentLink: '/documents/test.PDF',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'натрий лауретсульфат, глицерин,  диэтаноламиды жирных кислот кокосового масла, кокамидопропилбетаин, гликоль стеарат, соль поваренная, отдушка, трилон Б, кислота лимонная, 2-бром-2-нитропропандиол-1,3..',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'Рекомендуется для ежедневного применения.\n' +
  'Предназначено для мытья рук, тела, волосистой части головы у лиц с нормальным функциональным состоянием  кожи или обладающих склонностью к сухости кожных покровов.\n',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '• для мытья рук персонала в медицинских, социальных, детских учреждениях, на предприятиях пищевой промышленности и общественного питания, а так же объектах коммунального обслуживания населения - в парикмахерских, салонах красоты, в косметических и массажных кабинетах; \n' +
  '• для мытья рук и тела пациентов в медицинских, социальных, детских учреждениях;\n' +
  '• для мытья волос вместо шампуня при организации ухода за пациентами стационаров и социальных учреждений; \n' +
  '• в качестве геля для душа и пены для ванны при купании пациентов в организациях здравоохранения, домах инвалидов, пансионатах для лиц пожилого возраста, психо-неврологических диспансерах, приютах для лиц без определенного места жительства и т.д.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: 'Обладает бактериостатической и фунгистатической активностью',
  [PRODUCT_MENU_NAMES.CHARACTER]:'Мягкое моющее средство для эффективного очищения и удаления загрязнений с поверхности кожи. Содержит специальные компоненты, которые защищают кожу от чрезмерной сухости, сохраняя при этом оптимальный рН-баланс.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'для принятия ванны:   подставьте колпачок с мылом под струю воды;\n' +
  'для душа:  нанесите немного средства на губку или марлевую салфетку;\n' +
  'для мытья волос:  нанесите мыло на влажные волосы, слегка массируя кожу головы, смойте и при необходимости повторите процедуру;\n' +
  'для мытья рук:  нанесите мыло на влажные руки, легкими массирующими движениями равномерно распределите пену по поверхности рук в течение не менее 10-15 сек, тщательно очищая все поверхности кистей рук, включая запястья, уделяя особое внимание межпальцевым промежуткам, а так же пространству под ногтями. Для достижения максимального эффекта обработку следует повторить дважды.',
};
