import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"Moi_Konz_INV",
  name: 'МОЙ-КОНЦЕНТРАТ. Для предстерилизационной очистки',
  name_addition: 'концентрированное ферментное жидкое чистящее средство для ПСО.',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: [SOSTAV_WASH.NET,SOSTAV_WASH.FRM],
    sort_destiny: DESTINY_WASH.IPSO,
    sort_sfere: [SFERE_WASH.MED,SFERE_WASH.VET],
  sostav: '',
  naznach: 'Для предстерилизационной очистки инструментов, очистки эндоскопического оборудования.',
  sf_primen: '',
  image: '/images/Moi_Konz.jpg',

  packing: '1л',
  documentLink: '/documents/InstrMoi_Konz.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- неионогенные поверхностно-активные вещества от 5% до 15%;\n- полигексаметиленгуанидин гидрохлорид - 5%;\n- энзимы до 5%;\n- хеллатный комплекс.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '■ для предстерилизационной очистки (ПСО) ручным способом изделий медицинского назначения (включая эндоскопическое оборудование): хирургических, стоматологических инструментов, микроинструментария, эндоскопических инструментов, лабораторной посуды;\n■ для предстерилизационной очистки инструментов с применением ультразвуковых устройств.',
  [PRODUCT_MENU_NAMES.PRACTICE]: '- в организациях здравоохранения различного профиля: в стационарах, амбулаторно-поликлинических, оздоровительных учреждениях, диагностических центрах и пр.\n' +
  '- клинических, серологических и микробиологических лабораториях;\n' +
  '- в патологоанатомических отделениях и организациях судебно-медицинской экспертизы;',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'',
  [PRODUCT_MENU_NAMES.ACT_MECHANICS]: 'Профилактика инфекционных осложнений при проведении медицинских манипуляций базируется на адекватной, деликатной и тщательной очистке медицинских инструментов и лечебно-диагностического оборудования. \n' +
  'Предстерилизационная очистка - наиболее ответственный этап подготовки медицинских инструментов к стерилизации. А от качества предварительной и окончательной очистки сложного современного оборудования (например, эндоскопического), в значительной степени зависит результативность последующей дезинфекции высокого уровня или стерилизации. \n' +
  '- Средство содержит комплекс неионогенных ПАВ, обеспечивающих высокую моющую способность, эффективно удаляют биологические загрязнения с поверхностей обрабатываемых изделий, препятствуют повторной адгезии загрязнений;\n' +
  '- Средство содержит энзимный комплекс «Эверлазу», обладающий протеолитическим и амилолитическим действием, специально предназначенный для расщепления полимерных органических молекул биологических субстратов (крови, слизи, слюны);\n' +
  '- Средство содержит антибактериальный компонент ПГМГ гидрохлорид, обеспечивающий противомикробную активность с целью обеззараживания рабочего раствора в процессе использования.',
  [PRODUCT_MENU_NAMES.CHARACTER]: '- Концентрат и рабочие растворы средства «Мой концентрат» при введении в желудок относятся, согласно ГОСТ 12.1.007.76 к 4 классу малоопасных веществ, не обладают раздражающим действием при нанесении на кожные покровы. Средство мало-опасно при ингаляционном воздействии. По параметрам острой внутрибрюшинной токсичности средство относится к практически нетоксичным веществам (V класс токсичности по ТКП 125-2008).\n' +
  '- Смывы с ИМН после ПСО с применением средства «Мой-концентрат» не обладают гемолитической активностью, что свидетельствует о полной смываемости средства в рекомендованных режимах применения.\n' +
  '- Короткое время экспозиции и низкие концентрации рабочих растворов, позволяющие получить до 400л раствора из 1 л концентрата, делают средство экономически привлекательным для потенциальных потребителей и позволяют легко адаптировать его к современным технологиям и процессам очистки не только медицинских инструментов, но и сложного лечебно-диагностического оборудования. \n' +
  '- Средство «Мой-концентрат для ПСО» не содержит кислот, щелочей, спиртов. Растворы средства рН-нейтральны, не оказывают повреждающего воздействия на изделия, изготовленные из любых влагостойких материалов (стекла, резины, полимерных и композиционных материалов), практически всех металлов и сплавов, применяемых в производстве изделий медицинского назначения, а так же клеевых соединений, узлов и деталей современного дорогостоящего медицинского оборудования.\n' +
  '- Средство может применяться как для очистки медицинских изделий ручным способом, так и с применением ультразвуковых установок любого типа, при этом время экспозиции сокращается до 5 минут. \n' +
  '- Растворы средства обладают умеренным пенообразованием, что не позволяет применять их в автоматических моечных машинах.\n' +
  '- Рабочие растворы стабильны в течение 5 суток. Допускается их многократное применение в течение 1 рабочей смены при условии сохранения прозрачности.',
  [PRODUCT_MENU_NAMES.MODE]: `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация, %
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Стандартное загрязнение
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">15
			</p>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Интенсивное
			</p>
			<p style="text-align: center; text-align: center;">загрязнение
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">С применением ультразвуковых устройств
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
