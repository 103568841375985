import * as ROUTES from './routes';

export const INKRASLAV_SMALL_DESCRIPTION = 'общество с ограниченной ответственностью';

export const INVADES_SMALL_DESCRIPTION = 'общество с ограниченной ответственностью';

export const COMPANIES = {
  INKRASLAV: 'Инкраслав',
  INVADES: 'Инвадез',
  OTHERS: 'Партнеры',
};

export const NAVIGATION_LINKS = [
  {
    name: 'Компания',
    link: ROUTES.HOME,
  },
  {
    name: 'Продукты',
    link: ROUTES.CATALOG,
  },
  {
    name: 'Услуги',
    link: ROUTES.SERVICES,
  },
  {
    name: 'Сертификаты',
    link: ROUTES.CERTIFICATES,
  },
  {
    name: 'Филиалы',
    link: ROUTES.FILIALS,
  },
  {
    name: 'Контакты',
    link: ROUTES.CONTACTS,
  },
];
