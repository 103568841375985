import { COMPANIES } from 'constants/Navigation';
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"GALO_Effekt",
  name: 'Гало-эффект',
  name_addition: 'жидкое концентрированное пенное щелочное моющее средство с дезинфицирующими свойствами',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.SCH,
    sort_destiny:  [DESTINY_WASH.POV,DESTINY_WASH.LAP,DESTINY_WASH.POS,DESTINY_WASH.TPP],
    sort_sfere: [SFERE_WASH.PIS,SFERE_WASH.KOM,SFERE_WASH.TRS,SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.OBR,SFERE_WASH.FPR],

  sostav: 'неионогенные ПАВ, натрия гидроксид, натрия гипохлорит',
  naznach: 'Средство используется для мытья , в том числе совмещенного с дезинфекцией, поверхностей помещений, оборудования, столовой, кухонной и лабораторной посуды, предметов домашнего обихода, санитарно-технического оборудования при проведении текущих и генеральных уборок.',
  sf_primen: '',
  image: '/images/GALO_Effekt.webp',
  packing: '5л,1л',
  documentLink: '/documents/instruktsiya_galo_effekt.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  'неионогенные ПАВ (менее 5%), натрия гидроксид (менее 5%), фосфонаты (менее 5%), натрия гипохлорит (20-60 г/л в пересчете на активный хлор).',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'средство используется для мытья, в том числе совмещенного с дезинфекцией, поверхностей основного и вспомогательного оборудования, столовой, кухонной и лабораторной посуды, предметов домашнего обихода, поверхностей в помещениях и санитарно-технического оборудования при проведении текущих и генеральных уборок, в комплексе санитарно-гигиенических и противоэпидемических мероприятий. Благодаря содержанию щелочи и сильного окислителя гипохлорита натрия, средство прекрасно подходит для удаления широкого спектра различных органических и сопутствующих их загрязнений: пигментных пятен, следов маркеров и карандашей, белковых, жировых загрязнений, маслянистых (в том числе от горюче-смазочных материалов и нефтепродуктов), биоорганических, грязе-пылевых, смешанных.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '-  на предприятиях пищевой, перерабатывающей, фармацевтической, биотехнологической, парфюмерно - косметической промышленности;\n' +
  '- в учреждениях здравоохранения;\n' +
  '- на транспорте (общественном, пассажирском, санитарном, предназначенном для перевозки продуктов питания);\n' +
  '- на предприятиях коммунальной сферы, бытового обслуживания, торговли;\n' +
  '- в местах массового скопления людей и проведения спортивных и культурно - зрелищных мероприятий, на стационарных объектах транспортной инфраструктуры (вокзалы, аэропорты, станции метрополитена, склады, сортировочные пункты);\n' +
  '- в учреждениях социальной сферы и пенитенциарной системы;\n' +
  '- других объектах хозяйственной деятельности и в быту.',
  [PRODUCT_MENU_NAMES.SPECTRUM]:'',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'■ обладает хорошей моющей способностью; \n' +
  '■ отлично солюбилизирует широкий спектр загрязнений, не давая им прилипать к обработанным поверхностям;\n' +
  '■ хорошо смывается с обрабатываемой поверхности; \n' +
  '■ обладает дезинфицирующими свойствами и достаточным уровнем дезинфицирующей активности (RF>5) в отношении грамположительных, грамотри нательных бактерий и дрожжевых грибов (тестировано на S. aureus, Е. coli, Р. aeruginosa, С. albicans): 0,1 % при экспозиции 15 минут, 1 % - 5 минут;\n' +
  '■ средство не горюче, не взрывоопасно;\n' +
  '■ средство не содержит красителей и отдушек.',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  'Категорически запрещается смешивать средство с другими моющими, чистящими, дезинфицирующими средствами, особенно на основе кислот, во избежание отравления хлором!!!\n' +
  'Концентрация и температура рабочего раствора зависит от характера и интенсивности загрязнений. \n' +
  'Для мытья, совмещенного с дезинфекцией, визуально прозрачный раствор (без инородных включений, хлопьев, осадка) может использоваться многократно в течение срока стабильности рабочего раствора или до изменения внешнего вида. Стабильность 1 % рабочего раствора -14 суток, 0,1 % раствора - 1 день.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'- При мытье различного оборудования и поверхностей рабочие растворы средства могут быть нанесены вручную с помощью губки, щетки, изделий из текстильного материала (салфетки, мопы или др.), либо с помощью аппаратов высокого давления (пеногенераторов, пенопушек, пенокомплектов и др.), а также с помощью автоматических или полуавтоматических устройств.\n' +
  '- Температура и время мойки подбираются экспериментально, с учетом возможностей применяемого оборудования и предприятия. \n' +
  '- Расход рабочего раствора средства составляет 50-500 мл/м2.',
  /*[PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="3">
			<p style="text-align: center; text-align: center;">Концентрация рабочего раствора (по препарату),%
			</p>
		</td>
		<td colspan="4">
			<p style="text-align: center; text-align: center;">Количество концентрата и воды, необходимое для приготовления:
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">1 литра раб. раствора
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">10 литров раб. раствора
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Концентрат, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Вода, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрат, мл
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Вода, л
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,01
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,9
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,999
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0, 025
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,75
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,975
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,05
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,995
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">999,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,990
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">997,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">25
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,975
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">995
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">50
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,95
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">1
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">990
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">100
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,9
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">50
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">950
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">500
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,5
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">10
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">100
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">900
			</p>
		</td>
		<td>
			<p style="text-align: center; margin-left: pt; text-align: center;">1 000
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">9,0
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,*/
};
