import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import { ADV_DEZIN, ANTIMIKROB_DEZIN, SOSTAV_DEZIN, DESTINY_DEZIN, SFERE_DEZIN} from "../constants/Catalog";


export default {
  id:"Dezaral_INV",
  name: 'Дезараль',
  name_addition: 'Высококонцентрированное универсальное средство для дезинфекции поверхностей и инструментов.', // новое поле
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.TWO,
    sort_destiny: DESTINY_DEZIN.UNI,
    sort_sfere: SFERE_DEZIN.MED,
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ],
    sort_adv: [ADV_DEZIN.GYAN],

    sostav: '',
  naznach: 'Для дезинфекции, в том числе совмещенной с предстерилизационной очисткой, изделий медицинского назначения, мытья и дезинфекции поверхностей, предметов ухода, посуды (столовой, кухонной, лабораторной, аптечной), дезинфекции белья (постельного, нательного), санитарной одежды, уборочного инвентаря и текстиля, дезинфекции перед утилизацией одноразовых изделий медицинского назначения, СИЗ, перевязочного материала, одноразовых изделий из нетканых материалов, обеззараживания биологических жидкостей, жидких медицинских и пищевых отходов.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный',
  image: '/images/Dezaral.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrDezarl.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- полигексаметиленгуанидин гидрохлорид - 9%;\n- глутаровый альдегид - 5%;\n - хелатный комплекс, неионогенные ПАВ, функциональные добавки.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая микобактерии туберкулеза, тестировано на штамме М.terrae);\n - вирулицидный (включая ВИЧ, ВГВ, ротавирусы, ЕСНО 6 -группа вирусов полиомиелита);\n - фунгицидный (включая дрожжевые грибы).',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'в организациях здравоохранения; в учреждениях пенитенциарной системы.',
  [PRODUCT_MENU_NAMES.PURPOSE]: 'для дезинфекции, в т.ч. совмещенной с ПСО, изделий медицинского назначения (кроме эндоскопического оборудования), хирургических, стоматологических инструментов, микроинструментария, лабораторной посуды, одноразовых изделий медназначения перед утилизацией; для дезинфекции и мойки поверхностей в помещениях, санитарно-технического оборудования, уборочного материала и инвентаря, предметов ухода за больными; белья (нательного, постельного, спецодежды персонала); посуды (столовой, кухонной, лабораторной).\n',
  [PRODUCT_MENU_NAMES.CHARACTER]: 'высокая концентрация АДВ в препарате (суммарное содержание АДВ -стабилизированного глутарового альдегида и ПГМГ гидрохлорида - 14%),'+
  ' обеспечивает надежный обеззараживающий эффект при разбавлении концентрата в 1000 раз, что существенно снижает стоимость рабочих растворов, транспортные расходы,'+
  ' обеспечивает рациональное использование складских помещений при хранении.',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover" style="margin-left: 6pt; margin-right: 6pt;">
	<tbody>
	<tr>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">Режим
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">для поверхностей
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; text-align: center;">для инструментов
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; margin-right: 0cm; margin-left: 10pt; text-align: center;">Бактерицидный
			</p>
			<p style="text-align: center; margin-right: 0cm; margin-left: 10pt; text-align: center;">Вирулицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,1
			</p>
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
			<p style="text-align: center; text-align: center;">2
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">90
			</p>
			<p style="text-align: center; text-align: center;">45
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
			<p style="text-align: center; text-align: center;">5
			</p>
		</td>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">0,25
			</p>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td rowspan="2">
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
			<p style="text-align: center; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Фунгицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="text-align: center; text-align: center;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">0,5
			</p>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
			<p style="text-align: center; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="text-align: center; text-align: center;">Обеззараживание медицинских и пищевых отходов, биологических жидкостей
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; text-align: center;">60
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,

};
