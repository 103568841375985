export const PRODUCT_MAIN_CATEGORIES = {
  DEZIN: 'Дезинфицирующие средства',
  ANTISEP: 'Антисептические средства',
  WASH: 'Моющие средства',
  MED: 'Медицинское мыло',
};

export const PRODUCT_MENU_NAMES = {
  COMPOSITION: 'Состав',
  PURPOSE: 'Назначение',
  PRACTICE: 'Область применения',
  SPECTRUM: 'Спектр действия',
  CHARACTER: 'Характеристика',
  MODE: 'Таблица применения ',
  MODE_TEXT: 'Режим применения',
  CONSUMER_PROP: 'Потребительские свойства',
  ACT_MECHANICS: 'Механизм действия',
};

export const MENU_VALUES = {
  MODE_TEXT: 'mode_text',
};

export const PRODUCT_MENU_TIPS = [
  {
    name: PRODUCT_MENU_NAMES.COMPOSITION,
    value: 'composition',
  },
  {
    name: PRODUCT_MENU_NAMES.PURPOSE,
    value: 'purpose',
  },
  {
    name: PRODUCT_MENU_NAMES.PRACTICE,
    value: 'practice',
  },
  {
    name: PRODUCT_MENU_NAMES.SPECTRUM,
    value: 'spectrum',
  },
  {
    name: PRODUCT_MENU_NAMES.CHARACTER,
    value: 'character',
  },
  {
    name: PRODUCT_MENU_NAMES.CONSUMER_PROP,
    value: 'consumer_prop',
  },
  {
    name: PRODUCT_MENU_NAMES.ACT_MECHANICS,
    value: 'act_mechanics',
  },
  {
    name: PRODUCT_MENU_NAMES.MODE,
    value: 'mode',
  },
  {
    name: PRODUCT_MENU_NAMES.MODE_TEXT,
    value: MENU_VALUES.MODE_TEXT,
  },
];
