import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_WASH, SFERE_WASH, SOSTAV_WASH} from "../constants/Catalog";

export default {
  id:"GrosKlin",
  name: 'ГРОСС КЛИН',
  name_addition: 'готовое к применению пенное щелочное моющее средство',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_WASH.SCH,
    sort_destiny: DESTINY_WASH.LAP,
    sort_sfere: [SFERE_WASH.VET,SFERE_WASH.MED,SFERE_WASH.FPR],

  sostav: 'комплекс ПАВ, КОН и вспомогательных компонентов.Содержание щелочи (КОН) 4±1%.',
  naznach:'для мытья небольших изделий и лабораторной посуды',
  sf_primen: '',
  image: '/images/GrosKlin.jpg',
  packing: '0,5л, 1л, 5л',
  documentLink: '/documents/InstrGrosKlin.PDF',

  [PRODUCT_MENU_NAMES.COMPOSITION]:
  'анионные ПАВ – менее 5 %, неионогенные ПАВ – менее 5 %, гидроксид калия, натриевые соли этилендиаминтетрауксусной кислоты – менее 5 %,  эфиры гликолей. Не содержит отдушек и красителей.',
  [PRODUCT_MENU_NAMES.PURPOSE]:
  'для мытья и очистки лабораторной посуды: предметных  стекол, кювет, счетных камер (Горяева), колб, часовых стекол, пипеток, капилляров Паченкова,  воронок, мерных цилиндров, бюреток, наконечников дозаторов, планшетов  и пр. от всех видов органических загрязнений: маслянистых загрязнений, таких как иммерсионное масло, белковых веществ (фиксированные следы биологических жидкостей – крови, фекалий, мочи, ликвора, мокроты и др.), смол, красителей, следов от нанесения маркировки (воскового карандаша, маркера по стеклу, этикеток и пр.); остатков реактивов, лекарственных средств и др.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '- в клинических, микробиологических, серологических  и других лабораториях учреждений здравоохранения; \n' +
  '- в исследовательских лабораториях научных и научно-практических центров, учебных заведений;\n' +
  '- в контрольно-аналитических лабораториях различных предприятий.',
  [PRODUCT_MENU_NAMES.SPECTRUM]:'',
  [PRODUCT_MENU_NAMES.CONSUMER_PROP]:'■ Средство применяется в нативном виде.\n' +
  '■ В неразбавленном виде средство обладает высоким уровнем антимикробной активности (RF≥5lg) в отношении санитарно-показательной микрофлоры (Е. соly, S. Aureus, P. aeruginosa) при экспозиции 15 мин.\n' +
  '■  Отлично солюбилизирует широкий спектр загрязнений, не давая им прилипать к обработанным поверхностям;\n' +
  '■  Хорошо смывается с обрабатываемой поверхности;\n' +
  '■  Не содержит спиртов, отдушек, красителей;\n' +
  '■  Не горюче, не взрывоопасно;\n' +
  '■  рН12,5±1 (массовая доля средства 10,0%);',
  [PRODUCT_MENU_NAMES.CHARACTER]:
  '- По степени токсичности при внутрижелудочном введении средство относится 3 классу умеренно-опасных веществ. \n' +
  '- Содержит только биоразлагаемые компоненты, при естественном разложении не выделяет вредных веществ в окружающую среду.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:
      '- Мытье методом  погружения: Наполнить  неразбавленным средством емкость, обрабатываемые изделия  погрузить в емкость, выдержать экспозицию в зависимости от характера, интенсивности и устойчивости загрязнений (обычно не более 30 мин), затем провести  механическую очистку. В случае необходимости, при наличии чрезвычайно устойчивых загрязнений, следует увеличить время  экспозиции (до 60 мин.) или повторить процедуру до полного удаления загрязнений. Ополаскивание лабораторной посуды проводят вначале проточной водопроводной водой питьевого качества в течение 1-3 мин, а затем дистиллированной водой в течение 0,5 - 1 минуты. Допускается многократное использование средства (до 5-6 раз). В случае появления нерастворимого осадка, хлопьев раствор следует заменить.\n' +
      '- Мытье методом протирания: Средство нанести непосредственно  на отмываемую поверхность или губку, протереть до полного удаления загрязнений. При необходимости выдержать экспозицию (5-10 мин) или повторить процедуру. Ополаскивание лабораторной посуды проводят вначале проточной водопроводной водой питьевого качества в течение 1-3 мин, а затем дистиллированной водой в течение 0,5 - 1 минуты.',
  /*[PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;">

</div>`,*/
};
