import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_MED, SFERE_MED, SOSTAV_DEZIN, SOSTAV_MED} from "../constants/Catalog";

export default {
  id:"Delikat",
  name: '«ДЕЛИКАТ» серии «Софт-лайн»',
  name_addition: 'мыло жидкое',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_MED.BAS,
    sort_destiny: [DESTINY_MED.NVR,DESTINY_MED.MRP],
    sort_sfere: [SFERE_MED.FPR,SFERE_MED.KOM,SFERE_MED.MED,SFERE_MED.OBR,SFERE_MED.PIS],
  sostav: '',
  naznach: 'для мытья рук и тела',
  sf_primen: 'бактериостатический и фунгистатический',
  image: '/images/Delikat.JPG',
  packing: '0.5л,1л,5л',
  documentLink: '/documents/test.PDF',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'кокамидопропилбетаин, натрий лауретсульфат, натрий лаурет-11 карбоксилат, глицерин,  диэтаноламиды жирных кислот кокосового масла, гликоль стеарат,  аллантоин, эфиры целлюлозы, соль поваренная, отдушка, трилон Б, кислота лимонная, 2-бром-2-нитропропандиол-1,3, краситель CI 14720.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '• Рекомендуется к применению с первых дней жизни.\n' +
  '• Рекомендуется для ежедневного применения.\n' +
  '• Предназначено для мягкого очищения и деликатного ухода за нежной, склонной к раздражению и сухости кожей взрослых и детей, в том числе младенцев с первых дней жизни, а так же для комфортной и эффективной интимной гигиены. \n' +
  '• Благодаря специально подобранному составу, жидкое мыло обеспечивает мягкое и нежное очищающее действие. Содержит компоненты, которые способствуют увлажнению и смягчению кожи, сохранению естественного кислотно-щелочного баланса.',
  [PRODUCT_MENU_NAMES.PRACTICE]:
  '• для первого купания новорожденных в роддомах;\n' +
  '• для деликатного гигиенического ухода за кожей младенцев и грудных детей в перинатальных центрах, детских медицинских учреждениях, домах малютки, детских социальных учреждениях;\n' +
  '• для мытья рук персонала в медицинских, социальных, детских учреждениях, на предприятиях пищевой промышленности и общественного питания, а так же объектах коммунального обслуживания населения - в парикмахерских, салонах красоты, в косметических и массажных кабинетах; \n' +
  '• для мытья рук и тела пациентов в медицинских, социальных, детских учреждениях;\n' +
  '• для мытья волос вместо шампуня при организации ухода за пациентами стационаров и социальных учреждений; \n' +
  '• в качестве геля для душа и пены для ванны при купании пациентов в организациях здравоохранения, домах инвалидов, пансионатах для лиц пожилого возраста, психо-неврологических диспансерах, приютах для лиц без определенного места жительства и т.д.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: 'Обладает бактериостатической и фунгистатической активностью',
  [PRODUCT_MENU_NAMES.CHARACTER]:'Обладает пониженным пенообразованием.\n' +
  'Содержит компоненты, которые способствуют увлажнению и смягчению кожи, сохранению естественного кислотно-щелочного баланса. Не вызывает чувства стянутости и дискомфорта. Придает коже приятный запах, оставляя ощущение свежести и чистоты на длительное время.',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:'- для купания младенцев:   нанесите несколько капель мыла на ладонь, аккуратно, стараясь избегать попадания мыла в глаза ребенка, перенесите средство на влажные волосы и тело малыша. Нежно помассируйте несколько секунд. Тщательно ополосните чистой водой. Для создания нежной пены добавьте немного мыла в ванночку для купания и взбейте рукой;\n' +
  '- для тела и интимной гигиены:  нанесите небольшое количество мыла на увлажненную кожу. Распределите массажными движениями до образования пены. Смойте водой.',
};
