import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from 'constants/product';
import { PRODUCT_MENU_NAMES } from 'constants/product';
import {DESTINY_ANTISEP, SOSTAV_ANTISEP, SFERE_ANTISEP} from "../constants/Catalog";

export default {
  id:"IODEL",
  name: 'Йодель',
  name_addition: 'гелеобразное готовое к применению дезинфицирующее средство (кожный антисептик) на основе повидон-йода.',
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_ANTISEP.POV,
    sort_destiny: [DESTINY_ANTISEP.GAR, DESTINY_ANTISEP.OPP, DESTINY_ANTISEP.HAR, DESTINY_ANTISEP.INP],
    sort_sfere: [SFERE_ANTISEP.MED, SFERE_ANTISEP.FPR, SFERE_ANTISEP.KOM, SFERE_ANTISEP.VET, SFERE_ANTISEP.OBR, SFERE_ANTISEP.PIS, SFERE_ANTISEP.TRS],
  sostav: '',
  naznach: 'Хирургическая и гигиеническая антисептика рук, обработка инъекционного и операционного поля.',
  sf_primen: 'бактерицидный, вирулицидный, туберкулоцидный, фунгицидный.',
  image: '/images/IODEL.jpg',

  packing: '1л',
  documentLink: '/documents/InstrIODEL.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: 'В 100мл содержится повидон-йода - 2% (в пересчете на активный йод 0,2%), функциональные вспомогательные компоненты.',
  [PRODUCT_MENU_NAMES.PURPOSE]: '■ Хирургическая и гигиеническая антисептика рук персонала;\n'+
  '■ Обеззараживание и обезжиривание кожи операционного и инъекционного поля, в т.ч. локтевых сгибов доноров, обработка кожи перед выполнением инвазивных манипуляций и процедур (катетеризации периферических и центральных сосудов, спинномозговых пункций, катетеризаций эпидурального пространства, пункций суставов и др.);\n' +
  '■ Обработка ступней ног с целью профилактики грибковых заболеваний.',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'Учреждения здравоохранения, объекты социальной сферы (дома престарелых, инвалидов, хосписы и пр.), санаторно-курортного, спортивного и оздоровительного обеспечения, предприятия торговли и общественного питания, химико-фармацевтической, биотехнологической и парфюмерно-косметической промышленности, детские дошкольные и образовательные учреждения, , объекты коммунальных служб (в том числе парикмахерские, косметические салоны и др.), гостиничного хозяйства, пенитенциарной системы и пр.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '- бактерицидный (в том числе в отношении возбудителей внутрибольничных инфекций и микобактерий туберкулеза); \n' +
  '- вирулицидный (включая герпес, полиомиелит, энтеровирусы, ротавирусы, вирусы гриппа, в т.ч. вирус «птичьего» гриппа, «свиного» гриппа, парагриппа, др. возбудителей острых респираторных вирусных инфекций, гепатиты всех видов, включая гепатиты А, В и С, ВИЧ-инфекция, аденовирус и пр.); \n' +
  '- фунгицидный (в отношении грибов родов Кандида и Трихофитон). \n',
  [PRODUCT_MENU_NAMES.CHARACTER]: '- Средство «Йодель» по параметрам острой токсичности, согласно классификации по ГОСТ 12.1.007-76, при нанесении на кожу и введении в желудок относится к 4 классу малоопасных соединений. Местно-раздражающие, кожно-резорбтивные и сенсибилизирующие свойства в рекомендованных режимах применения у средства не выражены;\n' +
  '- Средство может быть использовано для обработки кожи детей от 1 года;\n' +
  '- Окрашивает обработанный участок кожи в жёлтый цвет, окрашивание исчезает через 10-15 минут в результате трансдермального проникновения ионов йода;\n' +
  '- При необходимости окраска легко удаляется  путем промывания обработанного участка водой с мылом или протирания ватным тампоном, смоченным мыльной водой; \n' +
  '- Не содержит красителей;\n' +
  '- При обработке рук не окрашивает ногтевую пластину; \n' +
  '- Не раздражает нежные участки кожи (на внутренней поверхности локтевого сгиба и предплечья, в области шеи, лица, живота); \n' +
  '- Полное высыхание антисептика на поверхности кожи происходит медленнее, чем у антисептиков на основе спиртов;\n' +
  '- Средство обладает пролонгированным антимикробным действием не менее 5 часов;\n' +
  '- Средство сохраняет свои свойства после замораживания и оттаивания.\n',
  [PRODUCT_MENU_NAMES.MODE_TEXT]:  '- Гигиеническая антисептика  рук: на кожу рук наносят 3 мл средства и втирают до полного впитывания. Время выдержки после окончания обработки – не менее 30 сек.\n' +
  '- Хирургическая антисептика рук: кисти рук и предплечий предварительно тщательно моют теплой проточной водой и туалетным мылом в течение 2 мин, после чего их высушивают стерильной марлевой салфеткой. Далее на кисти рук наносят 2 мл средства, равномерно распределяя и при этом, тщательно втирая его в кожу кистей рук и предплечий в течение 2 мин; после этого на руки наносят новую порцию (2 мл) средства и в течение 2 мин повторяют обработку рук средством до полного впитывания средства. Общее время обработки составляет не менее 4 мин.\n' +
  '- Обработка инъекционного поля: кожу протирают стерильным ватным тампоном, обильно смоченным средством; время выдержки после окончания обработки – 30 сек.\n' +
  '- Обработка операционного поля, локтевых сгибов доноров, кожи перед введением катетеров и пункцией суставов с обозначением границ обработки: кожу двукратно протирают раздельными стерильными марлевыми тампонами, обильно смоченными средством; время выдержки после окончания обработки – 2 минуты; \n' +
  '- Профилактическая обработка ступней ног: обильно смочить ватный тампон средством и тщательно обработать каждую ступню ног разными ватными тампонами, смоченными средством; время выдержки после обработки каждой ступни – не менее 30 сек.\n',
};
