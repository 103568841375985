import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";

export default {
  id:"SlavinDelta_INV",
  name: 'Славин-дельта',
  name_addition: 'Концентрированное универсальное дезинфицирующее средство с моющими свойствами для инструментов и поверхностей.',
  company: COMPANIES.INVADES,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.THREE,
    sort_destiny: [DESTINY_DEZIN.UNI,DESTINY_DEZIN.DVY,DESTINY_DEZIN.STR],
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.DER, ANTIMIKROB_DEZIN.PLE, ANTIMIKROB_DEZIN.SPR, ANTIMIKROB_DEZIN.OOI],
    sort_adv: [ADV_DEZIN.ALD],
  sostav: '',
  naznach: 'Для очистки, дезинфекции, ДВУ и стерилизации инструментов (включая эндоскопическое оборудование), дезинфекции и мытья поверхностей в помещениях.',
  sf_primen: 'Бактерицидный (включая туберкулез и особо опасные инфекции), вирулицидный, фунгицидный, спороцидный.',
  image: '/images/SlavinDelta.jpg',
  packing: '1л,5л',
  documentLink: '/documents/InstrSlavinDelta_Inv.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- полигексаметиленгуанидин (ПГМГ) гидрохлорид - 2,5±0,5%;\n - алкилдиметилбензиламмоний хлорид - 2,5±0,5%;\n - глутаровый альдегид - 3,0±0,5%;\n' +
  'Вспомогательные компоненты: ингибитор коррозии, моющий комплекс (неионогенное ПАВ), стабилизатор и другие функциональные добавки.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '- обладает антимикробной активностью в отношении грамотрицательных и грамположительных микроорганизмов (включая микобактерии туберкулеза - тестировано на штамме M. Terrae);\n - вирусов (включая аденовирусы, вирусы гриппа, парагриппа и др. возбудителей острых респираторных инфекций, энтеровирусы, ротавирусы, вирус полиомиелита, вирусы энтеральных, парентеральных гепатитов А,В,С, герпеса, атипичной пневмонии, птичьего и свиного гриппа, ВИЧ и др.);\n -  грибов (рода Кандида, Трихофитон и плесневых);\n - возбудителей внутрибольничных инфекций (включая синегнойною палочку и золотистый стафилококк);\n - особо опасных инфекций (чума (тестировано на Yersinia рestis), холера (тестировано на Vibrio сhоlerae 01 нетоксигенный), туляремия (тестировано на Francisella tularensis), бруцеллез (тестировано на Brucella abortus));\n - средство обладает спороцидной активностью (в том числе в отношении спор возбудителей сибирской язвы (тестировано на Bacillus anthracis) и анаэробных инфекций (тестировано на Clostridium perfringens тип CN205).',
  [PRODUCT_MENU_NAMES.PRACTICE]: '- в организациях здравоохранения различного профиля;\n' +
  '- клинических, серологических и микробиологических лабораториях;\n' +
  '- в патологоанатомических отделениях и бюро судебно-медицинской экспертизы, в моргах, крематориях, траурных залах при оказании ритуальных и похоронных услуг, для дезобработки автокатафалков;\n' +
  '- в учреждениях социальной сферы (приюты, дома инвалидов и престарелых);\n' +
  '- в детских дошкольных и образовательных учреждениях; \n' +
  '- на транспорте (санитарном, общественном, грузовом);\n' +
  '- в очагах инфекционных заболеваний, при организации ухода за тяжело больными и лежачими пациентами;\n' +
  '-  на предприятиях парфюмерно-косметической,  биотехнологической, фармацевтической  промышленности (при производстве нестерильных лекарственных средств, в помещениях класса чистоты C и D); \n' +
  '- в парикмахерских, соляриях, салонах красоты, маникюрных и педикюрных кабинетах, салонах пирсинга и татуировки; \n' +
  '- в общежитиях, на производственных предприятиях, объектах социальной и коммунальной сферы (сауны, бани);\n' +
  '- в санаторно-курортных и оздоровительных учреждениях;\n' +
  '- на спортивных объектах (тренажерные залы, бассейны, залы для занятий травматичными видами спорта)  и пр. объектах массового пребывания людей;\n' +
  '- в учреждениях пенитенциарной системы;\n' +
  '- для обеззараживания внутренних поверхностей накопительных баков автономных туалетов, санитарных поверхностей в общественных, автономных туалетах и биотуалетах, мусороуборочного оборудования и транспорта, мусорных баков, мусоропроводов, технологических помещений, площадок, емкостей и коммуникаций в коммунальном хозяйстве;\n' +
  '- для заполнения дезковриков, дезбарьеров, обработки колес автотраспорта;  \n' +
  '- в комплексе дезинфекционных мероприятий при ликвидации последствий чрезвычайных ситуаций.',
  [PRODUCT_MENU_NAMES.PURPOSE]:'■ для дезинфекции, в том числе совмещенной с предстерилизационной очисткой, изделий медицинского назначения, в том числе инструментов к эндоскопическому оборудованию;\n' +
  '■ для предстерилизационной очистки ручным и механизированным (в ультразвуковых установках любого типа) способами, изделий медицинского назначения, в том числе инструментов к эндоскопическому оборудованию;\n' +
  '■ для мытья и дезинфекции поверхностей в помещениях и на транспорте; крупногабаритного оборудования, поверхностей аппаратов, приборов; жесткой мебели; санитарно-технического оборудования; уборочного материала и инвентаря; предметов ухода за больными; белья (нательного, постельного, спецодежды персонала); посуды (столовой и лабораторной в микробиологических, серологических и клинических лабораториях); игрушек; кувезов для недоношенных детей, пеленальных и инструментальных столиков;\n' +
  '■ дезинфекции высокого уровня эндоскопов и других термолабильных ИМН;\n' +
  '■ предстерилизационной очистки, не совмещенной с дезинфекцией, жестких и гибких эндоскопов ручным и механизированным (в специализированных установках) способами;\n' +
  '■ предварительной и окончательной очистки эндоскопов перед ДВУ ручным и механизированным (в специализированных установках, например, «КРОНТ-УДЭ») способами;\n' +
  '■  для дезинфекции пищевых и медицинских отходов (одноразовых ИМН, перевязочного материала и белья, жидкие отходы, включая промывные воды после выполнения эндоскопических манипуляций), биологических жидкостей (моча, кровь, фекалии, рвотные массы, мокрота, амниотические воды и пр.);\n' +
  '■ дезинфекции крови в сгустках, донорской крови и препаратов, в том числе с истекшими сроками годности.',
  [PRODUCT_MENU_NAMES.CHARACTER]:'- Показатель концентрации водородных ионов рН нативного препарата 4±1,0 ед.\n' +
  '- Взаимодействие катионактивных ПАВ (полигуанидин и ЧАС) и комплекса «альдегид-органическая кислота» создает условия для формирования устойчивых хеллатных комплексов с ионами Са и Мg в оптимальном диапазоне рН, что обеспечивает стабильность, выраженный синергизм и полный спектр антимикробного действия, включая споровые формы.\n' +
  '- Для определения концентрации рабочих растворов имеются тест-полоски. Поставляются отдельно по запросу потребителя.\n' +
  '- Рабочие растворы стабильны в течение 28 суток.\n' +
  '- Допускается многократное применение рабочих растворов для дезинфекции и предстерилизационной очистки изделий, если их внешний вид не изменился. \n' +
  '- Растворы для предстерилизационной очистки изделий механизированным способом в специализированных установках, могут быть использованы многократно в течение одной рабочей смены или рабочего дня, если их внешний вид не изменился. \n' +
  '- Не оказывает коррозирующего действия на хирургические и стоматологические инструменты из коррозионно-стойких сталей, цветных металлов и сплавов на основе алюминия, титана и меди с защитно-декоративными покрытиями и без них; стоматологические оттиски и  зубопротезные заготовки, изготовленные из металла, полимерных материалов и альгинатной смолы; средство не портит хлопковую ткань, изделия из резины, а также поверхности из кафеля, дерева с лакокрасочным покрытием, линолеума, стекла, керамики и фаянса.\n' +
  '- По параметрам острой токсичности по ГОСТ 12.1.007-76 относится к 3 классу умеренно опасных веществ при введении в желудок, к 4 классу мало опасных веществ при нанесении на кожу. Средство относится к 4 классу малотоксичных веществ при введении в брюшину согласно классификации К.К.Сидорова. \n' +
  '- Не обладает кожно-резорбтивной и сенсибилизирующей активностью. \n' +
  '- Растворы средства относятся к 4 классу мало опасных веществ при ингаляционном воздействии В концентрации до 6,5% не оказывают раздражающего действия на кожу.',
  [PRODUCT_MENU_NAMES.MODE]:`<div style="text-align: center;">
	<table class="table table-responsive table-bordered table-hover">
	<tbody>
	<tr>
		<td rowspan="2">
			<p style="text-align: center; margin: 0cm; text-align: center;"><a name="OLE_LINK272" id="OLE_LINK272">Режим</a>
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">мин.
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">%
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">мин.
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="2">
			<p style="text-align: center; margin: 0cm; text-align: center;">для поверхностей
			</p>
		</td>
		<td colspan="2">
			<p style="text-align: center; margin: 0cm; text-align: center;">для инструментов
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="margin: 0cm;">Бактерицидный
			</p>
			<p style="margin: 0cm;">Вирулицидный
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,1
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,25
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">5
			</p>
		</td>
		<td rowspan="4">
			<p style="text-align: center; margin: 0cm; text-align: center;">0,25
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
		</td>
		<td rowspan="4">
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="margin: 0cm;">Туберкулоцидный
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,2
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,4
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,8
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="margin: 0cm;">Фунгицидный
			</p>
			<p style="margin: 0cm;">(Дерматофиты)
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,25
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="margin: 0cm;">Фунгицидный
			</p>
			<p style="margin: 0cm;">(Кандиды, плесень)
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,25
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="margin: 0cm;">Чума, холера, туляремия, бруцеллез
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td>
			<p style="margin: 0cm;">Сибирская язва.
			</p>
			<p style="margin: 0cm;">Клостридиалыные
			</p>
			<p style="margin: 0cm;">анаэробные инфекции
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">3,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">4,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">120
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">3,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">4,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">120
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Предстерилизационная очистка ИМН
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,05
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">20 (вручную)
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">5 (УЗ-очистка)
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">ДВУ
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">10
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Медицинские отходы
  при вирусных, грибковых (Кандиды, дерматофиты) и бактериальных инфекциях (вкл. Туберкулез)
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,25
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Медицинские отходы при ООИ (чума, холера, туляремия, бруцеллез)
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Медицинские отходы при клостридиальных анаэробных инфекциях, сибирской язве и других споровых формах
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">3,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">4,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">5,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">120
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Биологический материал (кровь, моча, фекалии, промывные воды) при вирусных, грибковых (Кандиды) и бактериальных инфекциях
			</p>
			<p style="margin: 0cm;">(вкл. Туберкулез, ООИ и анаэробные инфекции)
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,7
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,2
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">2,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Биологический материал (рвотные массы, мокрота) при вирусных, грибковых (Кандиды) и бактериальных инфекциях
			</p>
			<p style="margin: 0cm;">(вкл. Туберкулез, ООИ и анаэробные инфекции)
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">2,5
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
		</td>
	</tr>
	<tr>
		<td colspan="3">
			<p style="margin: 0cm;">Пищевые отходы
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,1
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,25
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">0,5
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">1,0
			</p>
		</td>
		<td>
			<p style="text-align: center; margin: 0cm; text-align: center;">60
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">30
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">15
			</p>
			<p style="text-align: center; margin: 0cm; text-align: center;">5
			</p>
		</td>
	</tr>
	</tbody>
	</table>
</div>`,
};
