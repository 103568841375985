import { COMPANIES } from "../constants/Navigation";
import { PRODUCT_MAIN_CATEGORIES } from "../constants/product";
import { PRODUCT_MENU_NAMES } from "../constants/product";
import {SOSTAV_DEZIN,DESTINY_DEZIN,SFERE_DEZIN,ANTIMIKROB_DEZIN,ADV_DEZIN} from "../constants/Catalog";


export default {
  id:"PeroxinPlus",
  name: 'ПЕРОКСИН ПЛЮС',
  name_addition: ' Универсальное концентрированное дезинфицирующее средство с моющими свойствами.', // новое поле
  company: COMPANIES.INKRASLAV,
  category: PRODUCT_MAIN_CATEGORIES.DEZIN,
    /* ----- SORT ------*/
    sort_sostav: SOSTAV_DEZIN.THREE,
    sort_destiny: [DESTINY_DEZIN.UNI,DESTINY_DEZIN.DVY,DESTINY_DEZIN.STR],
    sort_sfere: [SFERE_DEZIN.PIS, SFERE_DEZIN.KOM, SFERE_DEZIN.TRS, SFERE_DEZIN.FPR, SFERE_DEZIN.MED, SFERE_DEZIN.OBR],
    sort_antimicrob: [ANTIMIKROB_DEZIN.BAK, ANTIMIKROB_DEZIN.VIR, ANTIMIKROB_DEZIN.DRJ, ANTIMIKROB_DEZIN.DER, ANTIMIKROB_DEZIN.PLE, ANTIMIKROB_DEZIN.SPR, ANTIMIKROB_DEZIN.JAI, ANTIMIKROB_DEZIN.KAI],
    sort_adv: [ADV_DEZIN.PEVD, ADV_DEZIN.ORK],

  sostav: '',
  naznach: 'Для дезинфекции и мытья поверхностей в помещениях; для ПСО, дезинфекции, ДВУ и стерилизации ИМН.',
  sf_primen: 'бактерицидный, вирулицидный, фунгицидный, спороцидный, овоцидный',
  image: '/images/PeroxinPlus.jpg',
  packing: '1л',
  documentLink: '/documents/InstrPeroxinPlus.pdf',

  [PRODUCT_MENU_NAMES.COMPOSITION]: '- перекись водорода 10%;\n- полигексаметиленгуанидин гидрохлорид-2,5%;\n- оксикислота (гликолевая кислота)-до 15%;\n - функциональные компоненты.',
  [PRODUCT_MENU_NAMES.SPECTRUM]: '-	бактерицидный (включая внутрибольничные штаммы MRSA, VRE, микобактерии туберкулеза, тестировано на на М.Теrrае);\n'+
  '- фунгицидный (включая дерматофиты, дрожжевые и плесневые грибы рода As. Niger);\n'+
  '- вирулицидный (включая ВИЧ, парентеральные гепатиты, респираторные, кишечные вирусные инфекции, полиомиелит);\n'+
  '- спороцидный;\n'+
  '- овоцидный в отношении возбудителей паразитарных болезней (цистов и ооцистов простейших, яиц и личинок гельминтов).\n',
  [PRODUCT_MENU_NAMES.PRACTICE]: 'организации здравоохранения различного профиля (в т.ч. многопрофильные, специализированные и детские стационары, ожоговые и асептические отделения)'+
  ', предприятия торговли и общественного питания, пищевой, парфюмерно-косметической, химико-фармацевтической и биотехнологической промышленности по производству нестерильных'+
  ' лекарственных средств (помещения класса чистоты С и D), детские, образовательные и оздоровительные учреждения, транспорт (общественный, санитарный, для перевозки продуктов '+
  'питания), спортивные, общественные, коммунальные и социальные объекты, учреждения пенитенциарной системы.',
  [PRODUCT_MENU_NAMES.PURPOSE]:'- дезинфекция, предстерилизационная очистка и стерилизация изделий медназначения (втом числе эндоскопов и инструментов к ним),\n' +
  '- обеззараживание и очистка систем кондиционирования и вентиляции, \n' +
  '- дезинфекция поверхностей, жесткой мебели, уборочного инвентаря, санитарно-технического оборудования, предметов ухода за больными, посуды (столовой, лабораторной), белья (нательного, постельного, санитарной одежды персонала), детских игрушек и прочих объектов,\n' +
  '- инактивация биологических жидкостей, \n' +
  '- дезинфекция медицинских и пищевых отходов;\n' +
  '- дезинфекция, очистка, мойка и дезодорирование мусороуборочного оборудования, мусоровозов, мусорных баков, контейнеров для отходов;\n' +
  '- дезинфекция мусоропроводов, накопительных баков автономных туалетов, поверхностей в кабинах общественных, автономных туалетов и биотуалетов;\n'+
  '- дезинфекция и дезинвазия объектов внешней среды при чрезвычайных ситуациях техногенного и природного характера.',
  [PRODUCT_MENU_NAMES.CHARACTER]:'Полный спектр антимикробной активности, выраженные моющие свойства, высокая проницающая способность, умеренная коррозионная активность, '+
  '100% биоразлагаемость и экологическая безопасность. Не содержит токсичных компонентов (хлора, альдегидов, фенола), не создает опасных испарений в воздухе закрытых'+
  ' помещений, рабочий раствор без запаха. Пары концентрата и рабочих растворов не опасны для персонала и пациентов, не обладают раздражающим и сенсибилизирующим действием.',
  [PRODUCT_MENU_NAMES.MODE]:  `<div style="text-align: center;"><table class="table table-responsive table-bordered table-hover">
<tbody>
<tr>
	<td rowspan="2">
		<p style="text-align: center; margin: 0cm; text-align: center;">Режим
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">мин.
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Концентрация,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">%
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Экспозиция,
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">мин.
		</p>
	</td>
</tr>
<tr>
	<td colspan="2">
		<p style="text-align: center; margin: 0cm; text-align: center;">для поверхностей
		</p>
	</td>
	<td colspan="2">
		<p style="text-align: center; margin: 0cm; text-align: center;">для инструментов
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Бактерицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,05
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,1
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,3
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">0,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">120
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">90
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
	<td rowspan="5">
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2.5
		</p>
	</td>
	<td rowspan="5">
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Вирулицидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">(Кандиды)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">(Дерматофиты)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Туберкулоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,5
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,5
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;"><a name="OLE_LINK98" id="OLE_LINK98">Анаэробные
  инфекции
			</a>
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">4,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">4,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Фунгицидный
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">(Плесень)
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">1,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">2,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
	<td>
	</td>
	<td>
	</td>
</tr>
<tr>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">Овоцидный
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">90
		</p>
	</td>
	<td>
	</td>
	<td>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">ДВУ
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">4.0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5.0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">7.0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">8.0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">60
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">Стерилизация
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">6,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">8,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">10,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">Медицинские и пищевые отходы
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
<tr>
	<td colspan="3">
		<p style="text-align: center; margin: 0cm; text-align: center;">Биологические жидкости (кровь, моча, фекалии, мокрота, смывные воды, в т.ч. эндоскопические) <a name="OLE_LINK99" id="OLE_LINK99">и посуда из-под выделений больного</a>
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">3,0
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">5,0
		</p>
	</td>
	<td>
		<p style="text-align: center; margin: 0cm; text-align: center;">30
		</p>
		<p style="text-align: center; margin: 0cm; text-align: center;">15
		</p>
	</td>
</tr>
</tbody>
</table></div>`,

};
