import namespace from 'helpers/namespace';

export const NAVIGATION = namespace(
  {
    SET_ACTIVE_COMPANY: null,
  },
  'NAVIGATION'
);

export const ROUTING = namespace({}, 'ROUTING');

export const CATALOG = namespace(
  {
    UPDATE_SELECTED_TAB: null,
  },
  'CATALOG'
);
